import React,{useState } from 'react';
import Axios from "../../axios";
import axios2 from "../../axios2";
import { Link , useNavigate } from 'react-router-dom';
import Parser from 'html-react-parser';
import label from "../../config/DoctorLabel";
import Loginfooter from './Loginfooter'

function Login(){   
    //console.log('login');

	const navigate = useNavigate();
    const [loadingRButton, setloadingRButton] = useState(0);
    const [responseError, setresposeError] = useState(0);
    const [emailResponse, setemailResponse] = useState(0);
  	const [emailResponseStatus, setemailResponseStatus] = useState(0);
    let [account,setAccount] = useState({
        email: '',
        password: '',
        rememberme: '', 
        emailError:'',
        passwordError:'',
        
});
    let handleChange = (e) => {
		let name = e.target.name; 
        
		if(name === 'rememberme'){
			const checked = e.target.checked;
            //alert(checked)
			if(checked === true){
				let name = e.target.name;
				let value = checked;
				account[name] = value;
				setAccount(account);
			}else{
				let name = e.target.name;
				let value = '';
				account[name] = value;
				setAccount(account);
			}
            //validate();
		}else{
			let name = e.target.name;
			let value = e.target.value;
			account[name] = value;
			setAccount(account);
		}
		validate();
	}
    const validate = (e) => {
		let emailError = "";
		let passwordError = "";
       
	
        const reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
		if (!account.email || reg.test(account.email) === false) {
		    emailError = "Email Field is Invalid ";
		}
		if (!account.password) {
		    passwordError = "Password field is required";
		}
        
		if (emailError || passwordError ) {
			let email = account.email;
			let password = account.password;
            
		
			setAccount({emailError, passwordError, email, password });
			
		  return false;
		}

		let email = account.email;
		let password = account.password;
        
		setAccount({ emailError, passwordError, email, password });	
		return true;
	  }
    const hideEmailverifyMessage = ()=>{
    setemailResponseStatus('null');
    setemailResponse('');
	} 
    let SubmitPressed = (e) => {
        e.preventDefault();
        // setemailResponseStatus(0);
        if (validate()) {
            setloadingRButton(1)
            const config = {
				headers: {"Access-Control-Allow-Origin": "*"}
			};
			const bodyParameters = {
				email: account.email,password: account.password
			};
			Axios.post( 
			'/dLogin',
			bodyParameters,
			config
			).then(res =>{
               // alert('sd');
                setloadingRButton(0)
                let ApiResponseMessage = res.data.message;
				let ApiResponseStatus = res.data.status;
                if(res.data.status === 'Logged' && res.data.StatusCode === '200'){
                    window.sessionStorage.setItem('token', res.data.token);
                    setemailResponse(ApiResponseMessage);
                    setemailResponseStatus(ApiResponseStatus);
                    axios2.defaults.headers.common = { 'Authorization': `Bearer ${res.data.token}` }
                    setTimeout(() => {
                       window.location.href='/doctor';
                    }, 1000)
                }else if(res.data.status === 'Failed'){
                    setemailResponse(ApiResponseMessage);
					setemailResponseStatus(ApiResponseStatus);
                }else{
                    setemailResponse(ApiResponseMessage);
					setemailResponseStatus(ApiResponseStatus);
                }
			}).catch(response =>{
				setemailResponse(response.data.message);
			});
        }
    }
    return(
        <>
        <main id="dashboard"> 
        <div className="page-container">
            <div className="login d-lg-flex align-items-lg-center">
                <div className="login-content">
                    <div className="login-box">
					    <div className="login-logo">
                            <div className="content"><Link to="/">{Parser(label.logo)}</Link></div>
						</div>
                        <div className="login-header">
                            <div className="content">{Parser(label.login_header)}</div>
                        </div>
                        <div className='responseError'>
                            {responseError === 0 ? '':<span className="text-danger">{responseError}</span>}
                        </div>
                        <div className={`emailResponse ${emailResponseStatus} == 'Success' || 'Logged' ? forgotPasswordAlert : ''}`} >
                            {emailResponseStatus === 'Success' ?
                            <div className="alert success">
                                <span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
                                <i className="fa fa-check fa-1x"></i> 
                                <b>{emailResponse}</b>
                            </div>
                            :emailResponseStatus === 'Failed'?
                            <div className="alert danger">
                                <span className="closebtn danger" onClick={hideEmailverifyMessage}>×</span>
                                <i className="fa fa-check fa-1x"></i> 
                                <b>{emailResponse}</b>
                            </div>
                            :emailResponseStatus === 'Logged'?
                            <div className="alert success">
                                <span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
                                <i className="fa fa-check fa-1x"></i> 
                                <b>{emailResponse}</b>
                            </div>
                            :''
                            }
                        </div>
                        <div className="login-body">
                            <div className="row">
							  <div className="col-xl-12">
							    <form onSubmit={SubmitPressed}>
									<div className="form-group">
                                        <div className="content">{Parser(label.login_mail_image)}</div>
										<input type="email" className="form-control" name='email' id="exampleInputEmail1" onChange={handleChange} aria-describedby="emailHelp" placeholder="Enter email" autoComplete=""/>
                                    </div>
                                    <span className="text-danger">{account.emailError}</span>
									<div className="form-group">
                                        <div className="content">{Parser(label.login_password_image)}</div>
										<input type="password" className="form-control" name='password' id="exampleInputPassword1" onChange={handleChange} placeholder="Password" autoComplete=""/>
                                    </div>
                                    <span className="text-danger">{account.passwordError}</span>
									<div className="custom-control custom-checkbox">
                                        <input type="checkbox" name='rememberme' onChange={(e) => {
                                                handleChange(e);
                                            }} className="custom-control-input" id="exampleCheck1"/>
                                        <label className="custom-control-label" htmlFor="exampleCheck1">{label.login_remember_password}</label>
                                    </div>
                                    {loadingRButton === 1 ?     
                                    <button className="btn btn-primary" disabled>
                                        <i className="fa fa-circle-o-notch fa-spin"></i> Loading
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-primary">{label.login_button}</button>}
								</form>
								<p className="m-t-sm forgot">
                                    <Link to="/forgotPassword" >{label.login_forgot}</Link>
                                </p>
                              
							  </div>
                              <div className="acnt_txt text-md-right">
                                
                              </div>
							</div>
                        </div>
                        <Loginfooter></Loginfooter>
                    </div>
                </div>
            </div>
        </div>
        
        </main>
        </>
    )
}
export default Login;
import { Button, Group, List, Modal, Text } from "@mantine/core";
import React, { useState } from "react";
import BillingForm from "./BillingForm";
function AutoReChargeModal({
  openAutoRechargeModal,
  autoRechargeModal,
  setAutoRenew,
  context,
  updatePayment,
  setUpdatePayment
}) {
  let hasPaymentProfile = context?.user?.has_payment_profile;
  const [renewalTermsAccepted, setRenewalTermsAccepted] = useState(false);

  function handleAutoRenewal() {
    if (hasPaymentProfile) {
      setAutoRenew();
      autoRechargeModal.close();
    } else {
      setRenewalTermsAccepted(true);
    }
  }
  return (
    <Modal
      opened={openAutoRechargeModal}
      size="lg"
      onClose={autoRechargeModal.close}
      title={
        renewalTermsAccepted || updatePayment ? "Billing form" : "Agreement for Auto Recharge"
      }
    >
      {renewalTermsAccepted || updatePayment ? (
          <BillingForm
          setUpdatePayment={setUpdatePayment}
            setAutoRenew={setAutoRenew}
            close={() => autoRechargeModal.close()}
          />
        ) : null}
      {!renewalTermsAccepted && !updatePayment &&  (
        <Text>
          <p>
            By setting up Auto Recharge, You agree to be bound by the terms of
            this Agreement and authorize Us to charge the Auto Recharge Amount
            at the agreed renewal price of the respective state of your
            selection at Your Nominated Card on Your Credit Expiry Date. The
            Nominated Card details will also be stored on your account.
          </p>
          <p>
            Auto Recharge will be triggered on the Recommendation Expiry Date
            and your recommendation will be renewed and forwarded to you unless
            you choose to cancel it.
          </p>

          <p>
            You warrant that You have the capacity and authority to authorize Us
            to charge the Nominated Card.
          </p>

          <p>
            We are not liable for any loss arising from and any costs incurred
            in connection with:
          </p>
          <List m={20}>
            <List.Item>
              a. Us being unable to charge the Auto Recharge Amount to Your
              Nominated Card;
            </List.Item>
            <List.Item>
              b. any unsuccessful payment by You of the Auto Recharge Amount
              using Your Nominated Card (including a chargeback resulting from a
              disputed or fraudulent transaction or a failure by You to update
              Your details);
            </List.Item>
            <List.Item>
              c. Your credit expiring due to payment of the Auto Recharge Amount
              using Your Nominated Card being unsuccessful for any reason;
            </List.Item>
            <List.Item>
              d. any delay in applying the Auto Recharge; or
            </List.Item>
            <List.Item>
              e. Our variation or termination of this Agreement Changes by Us
            </List.Item>
          </List>
          <Group justify="center">
            <Button color="green" mr={10} onClick={handleAutoRenewal}>
              Accept
            </Button>
            <Button color="red" onClick={() => autoRechargeModal.close()}>
              Close
            </Button>
          </Group>
        </Text>
      )}
    </Modal>
  );
}

export default AutoReChargeModal;

import { Link } from "react-router-dom";
import {Alert, Button, Group} from "@mantine/core";
import Helpers from "../../components/doctor/Helper";
import { useContext } from "react";
import AppContext from "../../AppContext";
import { FaInfoCircle, FaTimesCircle } from "react-icons/fa";

const FirstOrderLayout = ({ children }) => {
  const {context} = useContext(AppContext);
  const userData = context?.user;
  return <>
    <main id="steps">
      <section id="pricing" className="container h-100">
        <div className="row ma_in">
          <header style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <div className="logo-img">
              <div className="content"><a href="/"><img src={process.env.REACT_APP_SITE_LOGO1} alt="logo" /></a></div>
            </div>
            <Group position="center" className="fl-top-menu">
              {userData?.id && <span>{userData.name} ({userData.state_name})</span>}
              {userData?.is_medical_info_completed && <Link to="/dashboard">Dashboard</Link>}
              <Link onClick={() => Helpers.Logout()}>Logout</Link>
            </Group>
          </header>
          { context?.partner?.id && context?.partner.state_id == context?.user?.state_id && <Alert style={{maxWidth: '400px', margin: '1em auto'}} variant="light" color="green" title="Dispensary Offer" icon={<FaInfoCircle/>}>
            You are ordering for {context.partner.title} 
            <a style={{display: 'block', fontSize: '10px', margin: '5px'}} href="#" onClick={() => {
              localStorage.removeItem('partner_source');
              window.location.reload();
            }}><FaTimesCircle/> Decline Dispensary Offer</a>
          </Alert> }
          {children}
        </div>
      </section>
    </main>
  </>
}
export default FirstOrderLayout;
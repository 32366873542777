import React, {useContext, useEffect, useState} from "react";
import axios2 from "../../axios2";
import {Badge, Button, Group, LoadingOverlay, Modal, ScrollArea, Table} from "@mantine/core";
import AppContext from "../../AppContext";
import OrderList from "../doctor/OrderList";
import OrderStatus from "../../components/OrderStatus";
import Moment from "react-moment";
import { useDisclosure } from "@mantine/hooks";
import { confirmAlert } from "react-confirm-alert";
import { showNotification } from "@mantine/notifications";
import { FaCheckCircle } from "react-icons/fa";
import moment from "moment";
import OrderNow from "../../components/OrderNow";
import { useLocation, useNavigate } from "react-router-dom";
import OrderSteps from "../../components/OrderSteps";

function Orders({site}) {
    const userData = useContext(AppContext).context?.user;
    const [selectedRcom, setSelectedRecom] = useState();
    const navigate = useNavigate();

    const { state } = useLocation();

    useEffect(() => {

    }, [state]);

    const site_id = process.env.REACT_APP_SITE_ID;

    const [viewRecomOpened, viewRecomModal] = useDisclosure(false);
    
    const viewRecom = (recom) => {
        setSelectedRecom(recom);
        viewRecomModal.open();
    }

    const download = (file) => {
        window.open(file+'?download=1'); 
    }

    const sendRecommendation = (recom) => {
        confirmAlert({
            title: 'Are you sure',
            message: 'You want to send recommendation to your email address?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios2.post('recommendations/' + recom.id + '/send')
                            .then(resp => {
                                showNotification({
                                    title: 'Sent',
                                    message: "The recommendation has been sent to your email address.",
                                    color: 'green',
                                    icon: <FaCheckCircle />
                                });
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => ''
                }
            ]
        });
    }

    useEffect(() => {
        if (userData?.role === "ROLE_DOCTOR") {
            setIsLoading(false);
            return;
        }
        axios2.get('orders')
            .then(res => {
                setIsLoading(false);
                setOrders(res.data.data);
            });
    }, []);

    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    return (
        <>
            {isLoading
                ? <LoadingOverlay visible={isLoading} overlayProps={{blur:0}}/>
                :
                <>
                    {
                        userData?.role === 'ROLE_DOCTOR' ?
                            <OrderList site={site} state={state}/>
                            :
                            <ScrollArea>
                                <Table miw={700} className='table'>
                                    <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th scope="col">No.</Table.Th>
                                        <Table.Th scope="col">Plan</Table.Th>
                                        <Table.Th scope="col">Order Date</Table.Th>
                                        <Table.Th scope="col">State</Table.Th>
                                        <Table.Th scope="col">Paid Amount</Table.Th>
                                        <Table.Th scope="col">ID Card?</Table.Th>
                                        <Table.Th scope="col">Expire Date</Table.Th>
                                        <Table.Th scope="col">Status</Table.Th>
                                        <Table.Th scope="col">Actions</Table.Th>
                                    </Table.Tr>
                                    </Table.Thead>

                                    <Table.Tbody>
                                    {orders.length !== 0 &&
                                    orders.map((order, index) => <>
                                        <Table.Tr>
                                            <Table.Td style={{ padding: '1.8rem' }}>{order.id}</Table.Td>
                                            <Table.Td style={{ padding: '1.8rem' }}>{order.plan_name}</Table.Td>
                                            <Table.Td><Moment format="MM/DD/YYYY">{order.created_at}</Moment></Table.Td>
                                            <Table.Td>{order.state_name}</Table.Td>
                                            <Table.Td>{order.paid_amount}$</Table.Td>
                                            <Table.Td>{order.has_id_card ? 'Yes' : 'No'}</Table.Td>
                                            <Table.Td>{order.main_recommendation ? <><Moment format="MM/DD/YYYY">{order.main_recommendation.expire_date}</Moment> ({moment(order.main_recommendation.expire_date).diff(moment(), 'days', false)} days)</> : '---'}</Table.Td>
                                            <Table.Td><OrderStatus status={order.status}></OrderStatus></Table.Td>
                                            <Table.Td>
                                                {!order.main_recommendation && <Badge>No {site_id == 2 ? 'ESA letter' : 'recommendation'} yet</Badge>}
                                                {order.main_recommendation && !order.main_recommendation.is_valid && <Badge>Revoked/Expired</Badge>}
                                                {order.main_recommendation && order.main_recommendation.is_valid && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    {order.main_recommendation.file && <Button variant="light" color="blue" size="xs" onClick={() => viewRecom(order.main_recommendation)}>View</Button>}
                                                    {order.main_recommendation.caregiver?.file && <Button variant="light" color="blue" size="xs" onClick={() => viewRecom(order.main_recommendation.caregiver)}>View Caregiver</Button>}
                                                    {order.main_recommendation.file && <Button variant="light" color="grape" size="xs" onClick={() => download(order.main_recommendation.file)}>Download</Button>}
                                                    {order.main_recommendation.file2 && <Button variant="light" color="blue" size="xs" onClick={() => download(order.main_recommendation.file2)}>Download Attachemnt</Button>}
                                                    {moment(order.main_recommendation.expire_date).diff(moment(), 'days', false) < 90 && <Button variant="light" color="orange" size="xs" onClick={() => navigate('/order/plan')}>Renew</Button> }
                                                </div>
                                                }
                                            </Table.Td>
                                        </Table.Tr>
                                        <Table.Tr className="order-list-status">
                                            <Table.Td colSpan={9}><OrderSteps size="small" orientation="horizontal" order={order} updateOrder={(order) => {
                                                orders[index] = order;
                                                setOrders(orders);
                                            }}></OrderSteps></Table.Td>
                                     </Table.Tr>
                                        </>)
                                        }
                                    </Table.Tbody>
                                </Table>
                                {orders.length == 0 && <OrderNow></OrderNow>}
                            </ScrollArea>

                    }
                </>
            }
            <Modal opened={viewRecomOpened} size="xl" onClose={viewRecomModal.close} title="View Recommendation">
                <embed src={selectedRcom && (site_id == 2 && selectedRcom.file ? selectedRcom.file.replace('admin.onlinemedicalcard', 'api2.myesadoctor') : selectedRcom.file)} frameborder="0" width="100%" height="400px"></embed>
            </Modal>
        </>
    )
}

export default Orders;
 const config={
    // -----------------------------Pages Title ------------------------------
    'emailVerifyTitle':'Onlinemedicalcard - Email Verify',
    'errorTitle':'Onlinemedicalcard - Error',
    'forgotPasswordTitle':'Onlinemedicalcard - Forgot Password',
    'homeTitle':'Onlinemedicalcard - Home',
    'loginTitle':'Onlinemedicalcard - Login',
    'otpTitle':'Onlinemedicalcard - OTP',
    'registerTitle':'Onlinemedicalcard - Register',
    'registerSuccessfulTitle':'Onlinemedicalcard - Register Successful',
    'resetPasswordTitle':'Onlinemedicalcard - Reset Password',
    'uploadTitle':'Onlinemedicalcard - Upload Documents',
    'plansTitle':'Onlinemedicalcard - Select Plans',
    // ------------------------------------------------ Global  ------------------------------------------------------------
    'logo':'<img src="../../assets/website/img/omc-logo-new 1.png" alt="logo"/>',
    'footer_copyright':'<p>2022 © OnlineMedicalCard</p>',
    // ------------------------------------------------Register Page----------------------------------------------------------
    'register_login_button':'Login',
    'register_banner_heading':'<h1>Get Approved for a <span>Medical Card</span> by Certified <span>Health Physicians</span></h1>',
    'register_banner_image':'<img src="/assets/website/img/register.png" alt="" />',
    'register_heading_2':'<h2 class="form-title">Register Now for a Virtual Medical Evaluation</h2>',
    'register_terms_conditions':'Accept Term & Conditions',
    'register_button':'Register',
    'registerfNameErrorNotification':'First Name field is required.',
    'registerlNameErrorNotification':'Last Name field is required.',
    'registerEmailErrorNotification':'Email Field is Invalid.',
    'registerMobileErrorNotification':'Phone field is required.',
    'registerMobileLengthErrorNotification':'Phone must be 10 digit.',
    'registerPasswordErrorNotification':'Password field is required.',
    'registerCPasswordErrorNotification':'Confirm Password field is required.',
    'registerPasswordMatchErrorNotification':'Password not matched.',
    'registerTermAndConditionErrorNotification':'required',

    // ------------------------------------------------Login Page----------------------------------------------------------
    'login_header':'<h3>Login</h3><p>Welcome back! Please login to continue.</p>',
    'login_mail_image':'<img src="/assets/website/img/mail.png" alt=""/>',
    'login_password_image':'<img src="/assets/website/img/password.png" alt=""/>',
    'login_remember_password':'Remember password',
    'login_button':'Login',
    'login_forgot':'Forgot password?',
    'login_create':'Don’t have an account?',
    'login_footer':'<p>2022 © OnlineMedicalCard</p>',
    // ------------------------------------------------OTP Page----------------------------------------------------------
    'otp_image':'<img src="/assets/website/img/authentication.png" alt="" />',
    'otp_heading_description':"<h1>Authentication</h1><h4>Check 6-digit code.</h4>",
    'otp_button_verify':'Verify',
    'otp_subdescription':"If you don't receive a text message ",
    'otp_modal_label':'<label className="w-100">Change Phone No</label><small className="w-100">OTP is valid for 2 minutes please make sure that you have entered a right phone no</small>',
    // ------------------------------------------------Email verify Page----------------------------------------------------------
    'emailVerify_heading_description':'<h3>Verification</h3><p>Your account is now verified. </p>',
    // ------------------------------------------------Forgot password Page----------------------------------------------------------
    'forgotpassword_heading_description':'<h3>Password assistance</h3><p>Enter the email address associated with your Online Medical Card account.</p>',
    'forgotpassword_button':'Continue',
    // ------------------------------------------------Forgot password Page----------------------------------------------------------
    'resetpassword_heading_description':'<h3>Reset</h3><p>Reset your password</p>',
    'resetpassword_button':'Reset',
    // ------------------------------------------------Register Successfully Page----------------------------------------------------------
    'registersuccessfully_heading_description':'<h3>Account Created.</h3><p>Please check your email, to verify account.</p>',
    'a':'nama',
    // ------------------------------------------------Personal profile Page----------------------------------------------------------
    'personalProfileLicensenoError':'License No. field is required.',
    'personalProfileGenderError':'Gender field is required.',
    'personalProfileDobError':'DOB field is required.',
    'personalProfileAddressError':'Address field is required.',
    'personalProfileCityError':'City field is required.',
    'personalProfileStateError':'State field is required.',
    'personalProfileZipcodeError':'Zipcode field is required.',
    'PersonalProfile_button':'Next',
    'PersonalProfileheading':'You Are Getting a Recommendation for California.',
    'PersonalProfileNameLabel':'<label>Full Name</label>',
    'PersonalProfileEmailLabel':'<label>Email</label>',
    'PersonalProfilePhoneLabel':'<label>Phone No.</label>',
    'PersonalProfileLicenseLabel':"<label>Your Driver's License NUMBER. or State ID NUMBER</label>",
    'PersonalProfileGenderLabel':'<label>Sex Assigned at Birth</label>',
    'PersonalProfileDOBLabel':'<label>Date of Birth</label>',
    'PersonalProfilereferedByLabel':'<label>Referred By</label>',
    'PersonalProfileallergiesLabel':'<label>What are your allergies? (If any)</label>',
    'PersonalProfileAddressLabel':'<label>Address (Please include Apartment / Suite number if any)</label>',
    'PersonalProfileCityLabel':'<label>City</label>',
    'PersonalProfileStateLabel':'<label>State</label>',
    'PersonalProfileZipLabel':'<label>Zipcode</label>',
    // ------------------------------------------------Plans Page----------------------------------------------------------
    'plansIdCardRemove':'ID Card Removed.',
    'plansIdCardAdded':'ID Card Added.',
    // ------------------------------------------------Payment Page----------------------------------------------------------
    'paymentCardNumber':'Card Number Field requied.',
    'paymentNameonCard':'Name on Card Field requied.',
    'paymentCardExpYear':'Card Expire Field requied.',
    'paymentCardExpMonth':'Card Expire Month Field requied.',
    'paymentCardCvc':'Card Cvc Field requied.',
    // ------------------------------------------------verify Email Page----------------------------------------------------------
    'verifyEmail_heading':'<h3>Verify Email.</h3><p>Please check your email, to verify account.</p>',
    // ------------------------------------------------verify Email Page----------------------------------------------------------
    'ThankYou':'<h3>Thank You.</h3><p>We will call you Soon.</p>',
    'MY_PROFILE_TITLE':'Online Medical Card ( MMJ )| My Profile',
    'MY_PROFILE':'My Profile',
    'MANUAL_REGISTER':'Add Patient',
    'EDIT_REGISTER':'Edit Patient',
    'MANUAL_REGISTER_TITLE':'Online Medical Card ( MMJ ) | Add Patient',
    'OLD_PASSWORD':'Old Password',
    'NEW_PASSWORD':'New Password',
    'CONFIRM_PASSWORD':'Confirm Password',
    'BTN_UPDATE_PASSWORD':'Update Password',
    'BTN_CLEAR':'Clear',
    'CHANGE_PASSWORD_TITLE':'Online Medical Card ( MMJ ) | Change Password',
    'CHANGE_PASSWORD_HEADING':'Change Password',
    'CONTACT_US_TITLE':'Online Medical Card ( MMJ ) | Contact Us',
    'CONTACT_US_HEADING':'Contact Us ',
    'MANUAL_REGISTER_PATIENT_LIST':'Manual Patient',
    'MANUAL_PATIENT_LIST_TITLE':'Online Medical Card ( MMJ ) | Manual Patient',
    'AUTO_REGISTER_PATIENT_LIST':'Auto Patient',
    'AUTO_PATIENT_LIST_TITLE':'Online Medical Card ( MMJ ) | Manual Patient',
    'VIDEO_CALL_TITLE':'Online Medical Card ( MMJ ) | Video Call',
    'VIDEO_CALL_HEADING':'Video Call',
    'WEBSITE':'onlinemedicalcard',
    'DISCONNECT_CALL':'',
    'DISCONNECT_CALL_MSG':'Are you sure,Do you want disconnect call?',
    'BTN_BACK':'Back',
    'PATIENT_INFO_TITLE':'Online Medical Card ( MMJ )',
    'PATIENT_INFO':'Patient Information',
    'ORDER_LIST_TITLE':'Online Medical Card ( MMJ )|Order List',
    'ORDER_LIST':'Order List'




} 
export default config;
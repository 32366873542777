import { Divider, Grid, Select, TextInput } from "@mantine/core";
import { useContext } from "react";
import AppContext from "../AppContext";

function AddressForm({ type, form }) {
  const title = type.charAt(0).toUpperCase() + type.slice(1) + " Address";
  const isShippingAddress = type == "shipping";

  const context = useContext(AppContext).context;
  const states = context?.states?.map((item) => ({
    value: item.id.toString(),
    label: item.name,
  }));
  console.log(form.values);
  return (
    <div>
      <h3 className="header">{title}</h3>

      <TextInput
        label="Name"
        placeholder=""
        mt="sm"
        size="sm"
        disabled={isShippingAddress}
        withAsterisk
        {...form.getInputProps(type + "_name")}
      />

      <TextInput
        label="Address"
        mt="sm"
        size="sm"
        withAsterisk
        {...form.getInputProps(type + "_address")}
      />

      <Grid>
        <Grid.Col span={{ md: 6, lg: 4 }}>
          <TextInput
            label="City"
            mt="sm"
            size="sm"
            withAsterisk
            {...form.getInputProps(type + "_city")}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 6, lg: 4 }}>
          <Select
            label="State"
            size="sm"
            mt="sm"
            withAsterisk
            data={states}
  {...form.getInputProps(type + '_state_id')}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 6, lg: 4 }}>
          <TextInput
            label="Zip"
            mt="sm"
            size="sm"
            withAsterisk
            {...form.getInputProps(type + "_zipcode")}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default AddressForm;

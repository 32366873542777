import {
    Button,
    PasswordInput,
    TextInput,
    Checkbox,
    LoadingOverlay,
    Modal,
    Group,
    PinInput,
    Loader,
    Box,
    Alert
} from "@mantine/core";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "@mantine/form";
import {Link, redirect, useNavigate, useSearchParams} from "react-router-dom";
import Parser from "html-react-parser";
import label from "../../config/DoctorLabel";
import Loginfooter from "../doctor/Loginfooter";
import {notifications} from '@mantine/notifications';
import axios from "../../axios2";
import AppContext from "../../AppContext";

function LoginByToken(props) {

    const { context, refreshContext } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    let [searchParams] = useSearchParams();
    const [token] = useState(searchParams.get('token'));
    const navigate = useNavigate();
    const [done, setDone] = useState(false)

    const form = useForm({
        initialValues: {
            email: '',
        },

        validate: {
            email: (value) => !value ? 'You must enter your email address' : null,
        },

        validateInputOnChange: true
    });

    useEffect(() => {
        const token = searchParams.get('token');
        const redirect = searchParams.get('redirect');
        if(token){
            setLoading(true);
            setFailed(false);
            setDone(false);
            axios.post('/user/login-by-token', {token})
            .then((resp) => {
                if(resp.data.data?.access_token){
                    axios.defaults.headers.common = { 'Authorization': `Bearer ${resp.data.data?.access_token}` }
                    localStorage.setItem('access_token', resp.data.data?.access_token);
                    refreshContext(redirect ? redirect : 'dashboard');
                }else{
                    setFailed(true);
                }
            })
            .catch(() => {
                if(context?.user?.id){
                    navigate(redirect ? redirect : '/dashboard');
                }else{
                    notifications.show({
                        title: 'Erro',
                        message: 'token is invalid, Please login again',
                        color: 'red'
                    });
                    setFailed(true)
                }
            }).finally(() => setLoading(false))
        }else{
            setFailed(true);
            setDone(false);
        }
    }, [context])

    const sendLoginLink = (values) => {
        values['redirect'] = searchParams.get('redirect');
        setLoading(true);
        axios.post('/user/send-login-token', values)
        .then((resp) => {
            setDone(true);
        })
        .finally(() => setLoading(false))
    }

    return (<div
        className="page-container login d-lg-flex align-items-lg-center"
        style={{ height: "100vh" }}
      >
        <div
          className="login-content login-box"
          style={{ padding: "6px", paddingBottom: "1.0em", maxWidth: "300px" }}
        >
          <div className="login-logo content" style={{ marginTop: "1.0em" }}>
            <img src={process.env.REACT_APP_SITE_LOGO1} alt="logo" />
          </div>
  
          <div style={{ textAlign: "center", marginTop: "2em" }}>
            <h3 style={{ fontWeight: "bold", marginBottom: "0.4em" }}>Login</h3>
            <p>Welcome back! Please enter you email address.</p>
          </div>
        <div style={{maxWidth: '600px', margin: '2em auto', textAlign: 'center'}}>
        {loading && <Loader color="blue" type="bars"></Loader>}
        {failed && !done && <Box>
            {token && failed && <Alert mb={20} color="red" variant="outline">Login token is expired, Please fill below form to get a new one.</Alert>}
            <form onSubmit={form.onSubmit(sendLoginLink)}>
            <TextInput {...form.getInputProps('email')} label="Email" ></TextInput>
            <Button loading={loading} type="submit" >Get a new login link</Button>
            </form>
            </Box>}

        {done && <Alert color="green" variant="outline">We have sent you an email with login link, please click on the link to get log in to your account automatically</Alert>}

            <br/>
        <Link to="/login">Log in</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/register">Sign up</Link>

    </div>
    </div>
    </div>
    );
}

export default LoginByToken;

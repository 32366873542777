import { useContext } from "react";
import styles from "../../styles/Plan.module.css"
import AppContext from "../../AppContext";

function Plan({ plan, selected, changeCallBack, style }) {
  return <div style={style} className={styles.plan + (selected ? ' ' + styles.selected : '')} >
      <label>
        <h4>
          <input
            type='radio'
            checked={selected}
            onChange={() => changeCallBack(plan)}
            style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
          />
          { plan.has_partner_discount && plan.partner_price != plan.price ? <span style={{textDecoration: 'line-through', paddingRight: '5px', fontWeight: 'normal', fontSize: '12px'}}>${plan.price}</span> : ''}
          <strong>${plan.partner_price}</strong>
        </h4>
        <div className="text">
          <h6
            style={{
              fontSize: '0.9rem',
              color: (selected ? 'rgba(60,183,180)' : 'rgba(0,0,0,0.7)')
            }}
          >
            {plan.name}
            <p style={{fontSize: '0.9em'}}>
            {plan.description}
            </p>
          </h6>
          {/* <div style={{ color: 'rgba(0,0,0,0.8)', marginTop: '2vmin' }} className="content">
            {plan.description}
          </div> */}
        </div>
      </label>
    </div>
}

export default Plan;
import { Badge } from "@mantine/core";

function OrderStatus({ status }) {
return <Badge variant="gradient" gradient={{
  from: status == 'processing' || status == 'unapproved' || status == 'waiting' ? 'orange' : 'teal',
  to: status == 'processing' || status == 'unapproved' || status == 'waiting' ? 'red' : 'lime',
    deg: 105
  }}>{status}</Badge>
}

export default OrderStatus;
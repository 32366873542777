import React,{useState, useEffect } from 'react';
import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.min.css'

const Helpers = {
    Logout: function(){
        var time = Date.now();
        var do_logout = window.confirm('Are you sure you want to log out?');
        if(do_logout || Date.now() - time < 10){
            delete window.localStorage['access_token'];
            window.location.href = '/';
            delete window.localStorage['formData']
        }
    },
    callAxiosConfig:function(){
        const token=window.sessionStorage.getItem('token');
        alert(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return config;
        
    },
    errorAlert:function(msg,error){

            if(error=='error'){
                new Notify({
                        status: 'error',
                        title: 'Error',
                        text: msg,
                        effect: 'fade',
                        speed: 300,
                        customClass: null,
                        customIcon: null,
                        showIcon: true,
                        showCloseButton: true,
                        autoclose: true,
                        autotimeout: 5000,
                        gap: 20,
                        distance: 20,
                        type: 1,
                        position: 'right top'
                });
            }else{
                new Notify({
                    status: 'success',
                    title: 'Success',
                    text: msg,
                    effect: 'fade',
                    speed: 300,
                    customClass: null,
                    customIcon: null,
                    showIcon: true,
                    showCloseButton: true,
                    autoclose: true,
                    autotimeout: 5000,
                    gap: 20,
                    distance: 20,
                    type: 1,
                    position: 'right top'
            });
        }

    }
   
}

export default Helpers;

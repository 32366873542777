import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
import "./assets/website/assets/css/concept.min.css";
import "./assets/website/assets/css/admin2.css";
import "./assets/website/css/main.css";
import "./assets/website/css/upload.css";
import "./assets/website/css/profile.css";
import "./assets/website/css/additional.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Loader, MantineProvider, createTheme } from "@mantine/core";
import { AppContextProvider } from "./AppContext";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import "@mantine/core/styles.css";
import { Notifications } from "@mantine/notifications";
import '@mantine/notifications/styles.css';
const root = ReactDOM.createRoot(document.getElementById("root"));

const site_id = process.env.REACT_APP_SITE_ID;
const theme = createTheme({
  fontFamily: "poppins",
  components: {
    Loader: Loader.extend({
      defaultProps: {
        type: "dots",
      },
    }),
  },
});
root.render(
  <MantineProvider theme={theme}>
    <BrowserRouter>
      <AppContextProvider>
        <Helmet>
          <title>
            {site_id == 2 ? "My ESA Doctor" : "OMC - Online Medical Card"}
          </title>
          <meta
            name="description"
            content={
              site_id == 2
                ? "My ESA Doctor online application to send get you ESA letter!"
                : "Online Medical Card to get your recommendation in  a few minutes!"
            }
          />
          <link
            rel="icon"
            href={site_id == 2 ? "/favicon-esa.png" : "/favicon.png"}
          />
          <link
            rel="apple-touch-icon"
            href={site_id == 2 ? "/logo-esa.png" : "/logo.png"}
          />
        </Helmet>
        <Notifications position="top-right" />
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

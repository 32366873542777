import { Paper } from "@mantine/core";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export function getWeekDays(date) 
{

  const start = moment(date).startOf("week");
  const end = moment(date).endOf("week");

  console.log('start', start);

  const days = Array.from({ length: 7 }, (_, i) => moment(start).add(i, "day"));


  return {
    start,
    end,
    days,
    startFormatted: start.format("YYYY-MM-DD"),
    endFormatted: end.format("YYYY-MM-DD"),
  };
}

export function isInWeekRange(date, weekStart) {
  if (!weekStart) return false;
  const start = dayjs(weekStart)
    .tz(process.env.REACT_APP_TIMEZONE)
    .startOf("week");
  const end = dayjs(weekStart).tz(process.env.REACT_APP_TIMEZONE).endOf("week");
  return dayjs(date)
    .tz(process.env.REACT_APP_TIMEZONE)
    .isBetween(start, end, null, "[]");
}
function numberToColor(num) {
  // Ensure the input number is within the allowed range
  if (num < 1 || num > 1000000) {
      throw new Error("Number must be between 1 and 1,000,000.");
  }

  // Simple hash function to map the number to a unique value
  function hash(n) {
      n = ((n >> 16) ^ n) * 0x45d9f3b;
      n = ((n >> 16) ^ n) * 0x45d9f3b;
      n = (n >> 16) ^ n;
      return n;
  }

  // Use hash value to generate unique Hue (0-360)
  const hue = hash(num) % 360;

  // Use hash to generate Saturation (60-100) and Lightness (50-80)
  const saturation = 60 + (hash(num + 1) % 40); // 60-100%
  const lightness = 50 + (hash(num + 2) % 30); // 50-80%

  // Convert HSL to RGB
  function hslToRgb(h, s, l) {
      s /= 100;
      l /= 100;

      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs((h / 60) % 2 - 1));
      const m = l - c / 2;
      let r = 0, g = 0, b = 0;

      if (0 <= h && h < 60) {
          r = c; g = x; b = 0;
      } else if (60 <= h && h < 120) {
          r = x; g = c; b = 0;
      } else if (120 <= h && h < 180) {
          r = 0; g = c; b = x;
      } else if (180 <= h && h < 240) {
          r = 0; g = x; b = c;
      } else if (240 <= h && h < 300) {
          r = x; g = 0; b = c;
      } else if (300 <= h && h < 360) {
          r = c; g = 0; b = x;
      }

      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      return { r, g, b };
  }

  // Convert the HSL to RGB
  const { r, g, b } = hslToRgb(hue, saturation, lightness);

  // Convert RGB to Hex format
  function rgbToHex(r, g, b) {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  }

  // Return the hex color code
  return rgbToHex(r, g, b);
}

export const statusColors = {
  planned: "orange",
  done: "green",
  canceled: "black",
  unverified: "grey",
  no_show: "red",
  rescheduled: 'white',
};

export const timeSlots = Array.from({ length: 16 }, (_, i) =>
  dayjs()
    .tz(process.env.REACT_APP_TIMEZONE)
    .hour(9).minute(30).second(0)
    .add(i * 30, "minute")
);

export const formatPhone = (phone) => {
  return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3 - $4");
};

export const renderAppointment = (appointments, openModal) => {

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
      {appointments.map((appointment, index) => ( <Draggable
                            key={appointment.id}
                            draggableId={appointment.id.toString()}
                            index={appointment.index}
                          >

                          {(provided) => (
                              <div
                                className="draggable-item"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{
                                  margin: '4px 0',
                                  borderRadius: 4,
                                  ...provided.draggableProps.style,
                                }}
                              >

                                <Paper
                                  p="xs"
                                  bg={`${statusColors[appointment.status]}.2`}
                                  className="timeslot-appointment"
                                  onClick={() => openModal(appointment)}
                                  style={{
                                    border: `1px solid ${statusColors[appointment.status]}`,
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{appointment.name}</p>
                                  <span style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: numberToColor(appointment.dr_id),
                                  }}></span>
                                </Paper>
                                
                              </div>
                            )}
        </Draggable>
      ))}
    </div>
  );
};

import { LoadingOverlay } from "@mantine/core";
import { createContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "./axios2";
import { notifications } from "@mantine/notifications";

const AppContext = createContext({
  context: null,
  refreshContext: () => {},
});

export function AppContextProvider({ children }) {
  
  const [context, setContext] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState("");
  const [countryValue, setCountryValue] = useState("+1");

  const [chat, setChat] = useState({
    mobile: '',
    name: '',
    email: '',
  });
  
  function activeKeypad() {
    setKeypad((prev) => !prev);
  }
  const [keypad, setKeypad] = useState(false);

  const logout = useRef(false);

  useEffect(() => {
    logout.current = searchParams.get("logout");
  }, []);

  const refreshContext = (url, selectedPlan, noloading) => {
    const source = localStorage.getItem("partner_source");
    if (logout.current) {
      logout.current = false;
      axios.defaults.headers.common = {};
      localStorage.removeItem("access_token");
      searchParams.set("logout", "0");
    }
    if (!noloading) setLoading(true);
    axios.get("context?source=" + source).then((resp) => {
      const c = resp.data.data;
      setContext({
        ...c,
        selectedPlan,
        doctor_id: localStorage.getItem("selected_doctor"),
      });
      setLoading(false);
      redirect(url, c);
    });
  };

  const redirect = (url, context) => {
    console.log("Context:", context);
    console.log("URL:", url);
    if (context.user?.id) {
      const is_medical_info_completed = context.user?.is_medical_info_completed;
      if (!is_medical_info_completed && context.user.role !== "ROLE_DOCTOR") {
        if (!["/order/plan"].includes(location.pathname))
          navigate("/order/plan");
      } else {
        if (url) navigate(url);
      }
      window.scrollTo(0, 0);
    } else {
      console.log("location.pathname", location.pathname);
      if (
        !["/register", "/login", "/verify", "/login-by-token"].includes(
          location.pathname
        )
      ) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    const source = searchParams.get("source");
    const plan = searchParams.get("plan");
    if (source) localStorage.setItem("partner_source", source);
    if (plan) localStorage.setItem("plan", plan);
    refreshContext();
  }, []);

  return (
    <AppContext.Provider value={{ context, refreshContext, setContext, keypad, activeKeypad, inputValue, setInputValue, countryValue, setCountryValue, chat, setChat}}>
      {loading ? <LoadingOverlay visible={true} overlayProps={{blur:2}} /> : children}
    </AppContext.Provider>
  );
}

export default AppContext;

import { Grid } from "@mantine/core";
import React from "react";

function BigCalendarHeader({ days }) {
  return (
    <>
      {days.map((day, index) => (
        <Grid.Col span={1.5} key={index}>
          <p style={{ textAlign: "center" ,fontWeight:700}}>{day.format("ddd")}</p>
          <p style={{ textAlign: "center" ,fontSize:'12px'}}>{day.format("MM/DD")}</p>
        </Grid.Col>
      ))}
    </>
  );
}

export default BigCalendarHeader;

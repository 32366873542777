import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  params: {}
});

instance.interceptors.response.use(function (config) {
  return config;
}, function (error) {
  if (error.response.status == 401) {
    window.sessionStorage.clear();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

export default instance; 
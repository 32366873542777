import React from "react";
import { Button} from "@mantine/core";
import { Link } from "react-router-dom";

function Unsubscribe() {

    const site_id = process.env.REACT_APP_SITE_ID;

    return <div style={{margin: '2em 1em', textAlign: 'center'}}>
    <h3>Thank you for updating your preferences!</h3>
    <h4>You've been successfully unsubscribed from our communications. We'll miss you but respect your decision.</h4>
    <p>Remember, you can always resubscribe or reach out if you change your mind. Wishing you all the best!</p>
    <p>If you have any questions, contact us at [contact@{site_id == 2 ? 'myesadoctor.com' : 'onlinemedicalcard.com'}].</p>
    <p>&nbsp;</p>
    <Link to={'/'}>Back</Link>
    </div>


}

export default Unsubscribe;
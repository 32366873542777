import React, {} from "react";
import label from "../../config/PatientLabel";
import {Link} from "react-router-dom";
import ProfileForm from "./ProfileForm";
function ViewProfile() {
    
    return (
        <>
            <div className="content-header" style={{marginTop: '2rem'}}>
                <h1 className="page-title">{label.viewProfileTitle}</h1>
            </div>

            <div id='profile' className="view-profile update-profile" style={{paddingInline: '1rem'}}>

                <div className="profile-edit-btn">
                    <Link to="/profileUpdate"> <img src="../../assets/website/img/edit.png" alt="Edit"/>Edit</Link>
                </div>

                <ProfileForm isEditable={false}></ProfileForm>

            </div>
        </>
    );
}

export default ViewProfile;
import React,{useState ,useEffect} from 'react';
import $ from "jquery";
import "jquery-validation";
import Axios from "../../axios";
import Parser from 'html-react-parser';
import label from "../../config/PatientLabel";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import '../../assets/website/css/verifyRecommendation.css';
function Verifyrecommendation(){
    const [requestResponse, setRequestResponse] = useState(0);
  	const [requestResponseStatus, setRequestResponseStatus] = useState(0);
    const [recDetails, setRecdetails] = useState([]);
    const [loadingRButton, setloadingRButton] = useState(0);

    let [account,setAccount] = useState([]);

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        account[name] = value;
        setAccount(account);
    }
    const validate=(e)=>{
		$("#verifyrec").validate({
			rules: {
				checkbox: {
					required: true,
				},
                recid : {
					required: true,
				},
				
			},
		  });
	   }
    useEffect(() => {
		validate();
	})
    let verifyRecommendation = (e)=>{
        e.preventDefault();
        setloadingRButton(1)
        const config = {
            headers: {"Access-Control-Allow-Origin": "*"}
        };
        const bodyParameters = {
            recom_number: account.recid
        };
        Axios.post( 
        '/mSearchRecom',
        bodyParameters,
        config
        ).then(res =>{
            setloadingRButton(0)
            let ApiResponseMessage = res.data.message;
			let ApiResponseStatus = res.data.status;
            if(ApiResponseStatus == 'Success'){
                setRecdetails(res.data.recDetails);
                setRequestResponse(ApiResponseMessage);
				setRequestResponseStatus(ApiResponseStatus);
            }else{
                setRecdetails([]);
                setRequestResponse(ApiResponseMessage);
				setRequestResponseStatus(ApiResponseStatus);
            }
            //console.log(res.data)
        }).catch(response =>{
            //setemailResponse(response.data.message);
        });
    }
    const hideEmailverifyMessage = ()=>{
        setRequestResponse('');
		setRequestResponseStatus('null');
    }
    return(
        <div className="mainwrapper">
            <div className={`emailResponse ${requestResponseStatus} == 'Success'? forgotPasswordAlert : ''}`} >
            {requestResponseStatus === 'Success' ?
            <div className="alert success">
                <span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
                <i className="fa fa-check fa-1x"></i> 
                <b>{requestResponse}</b>
            </div>
            :requestResponseStatus === 'Failed'?
            <div className="alert danger">
                <span className="closebtn danger" onClick={hideEmailverifyMessage}>×</span>
                <i className="fa fa-check fa-1x"></i> 
                <b>{requestResponse}</b>
            </div>
            :
            ''
            }
        </div>
        <section className="find">
            <div className="container">
                <div className="row find_modal">
                <form onSubmit={verifyRecommendation} id="verifyrec">
                    <div className="col-md-12 text-center">
                    
                        <a href="/"><img src="../../assets/website/img/omc-logo-new 1.png" alt="logo"/></a>
                        <h1>{Parser(label.verifyrecHeading)}</h1>
                        <div className="content">{Parser(label.verifyrecparagraph)}</div>
                        <input type="text" id="search" className="form-control one" placeholder={Parser(label.verifyrecPlaceholder)} name="recid" onChange={handleChange}/>
                    </div>
                    <div className="col-md-12">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" name='checkbox' className="custom-control-input" id="exampleCheck1"/>
                            <label className="custom-control-label" htmlFor="exampleCheck1">{Parser(label.verifyrecCheckboxContent)}</label>
                        </div>
                    </div>
                    <div className="col-md-12 my-3">
                        {loadingRButton == 1 ? 
                            <button className="btn submit-btn verifyRecBTn" disabled>
                                <i className="fa fa-circle-o-notch fa-spin"></i> Loading
                            </button>
                        :
                            <button type='submit' className="btn submit-btn verifyRecBTn">{Parser(label.verifyrecBtnName)}</button>
                        }	
                        {/* <button type="submit" id="submit" value="submit"></button> */}
                    
                    </div>
                    </form>
                    {recDetails.rec_end_date == undefined?'':
                    <div className="col-md-12 ">
                        <hr />
                        <table id="fees_table" border="1" className="table table-striped mt-2">
                            <tbody>
                            <tr>
                                <th scope="col" width="200">Full Name</th>
                                <th scope="col" width="200">Physician</th>
                                <th scope="col" width="200">Expiration Date</th>
                            </tr>
                            <tr>
                                <td>{recDetails.patient_name}</td>
                                <td>{recDetails.doctor_name}</td>
                                <td>{recDetails.rec_end_date == undefined?'':<Moment  format="MM/DD/YYYY">{recDetails.rec_end_date}</Moment>}</td>
                            </tr>
                            </tbody>
                        </table> 
                    </div>
                    }
                    <div className="col-md-12 login-footer mt-3 ">
                        <div className="content text-center">{Parser(label.footer_copyright)}</div>
                    </div>
                </div>
            </div>
            
        </section>
        
    </div>
    
    )
}
export default Verifyrecommendation;
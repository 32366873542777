import {
  Badge,
  Button,
  Checkbox,
  FileButton,
  Grid,
  Group,
  Image,
  Input,
  LoadingOverlay,
  ScrollArea,
  Select,
  SimpleGrid,
  Space,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import AppContext from "../../AppContext";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import DatePicker from "react-datepicker";
import { format, subYears } from "date-fns";
import MaskedTextInput from "react-input-mask";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";
import DOB from "../../components/DOB";
import moment from "moment/moment";
import MedicalConditionsCheckList from "./MedicalConditionsCheckList";
import axios2 from "../../axios2";
import { useNavigate } from "react-router-dom";
import AutoReChargeModal from "../../components/AutoRechargeModal";

function ProfileForm(props) {
  const isMobile = useMediaQuery("screen and (max-width: 1024px)");
  const site_id = process.env.REACT_APP_SITE_ID;

  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState(null);

  const userData = useContext(AppContext).context?.user;

  const [picture, setPicture] = useState(null);

  const [idProof, setIdProof] = useState();
  const [medicalRecord, setMedicalRecord] = useState();
  const [openAutoRechargeModal, autoRechargeModal] = useDisclosure();

  console.log("idProof", idProof);
  console.log("medicalRecord", medicalRecord);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [dob, setDob] = useState("");
  const { context } = useContext(AppContext);
  const form = useForm();
const [updatePayment,setUpdatePayment] = useState(false)
  useEffect(() => {
    axios2.get("/user/profile").then((response) => {
      setIsLoading(false);
      setFetchedData(response.data.data);
      setSelectedDiseases(
        response.data.data.medical_conditions.map((m) => m.id)
      );
    });
  }, []);

  function handleSubmit(values) {
    if (picture !== null && typeof picture !== "string")
      values["profile_pic"] = picture;

    if (idProof !== null && typeof idProof == "object")
      values["id_proof"] = idProof;

    if (medicalRecord !== null && typeof medicalRecord == "object")
      values["medical_record"] = medicalRecord;

    if (!dob)
      notifications.show({
        title: "Error",
        message: "Please enter your date of birth",
        color: "red",
      });

    const formData = new FormData();
    Object.keys(values).map((key) => formData.append(key, values[key]));

    selectedDiseases.map((key) =>
      formData.append("medical_conditions[]", parseInt(key))
    );

    formData.set("dob", dayjs(values["dob"]).format("MM-DD-YYYY"));

    setIsSubmitLoading(true);
    axios2
      .post("/user/profile", formData)
      .then((res) => {
        setIsSubmitLoading(false);
        notifications.show({
          title: "Success",
          message: "Profile Successfully updated",
          color: "green",
        });
      })
      .catch((err) => setIsSubmitLoading(false));
  }

  useEffect(() => {
    form.setValues({ dob: dob });
  }, [dob]);

  useEffect(() => {
    if (fetchedData === null) return;

    setPicture(fetchedData.profile_pic);
    setMedicalRecord(fetchedData.medical_record);
    setIdProof(fetchedData.id_proof);

    setDob(fetchedData.dob ? fetchedData.dob : "");

    form.setValues({
      first_name: fetchedData.first_name ?? "",
      middle_name: fetchedData.middle_name ?? "",
      last_name: fetchedData.last_name ?? "",
      email: fetchedData.email ?? "",
      mobile: fetchedData.mobile ?? "",
      gender: fetchedData.gender ?? "",
      driver_license_number: fetchedData.driver_license_number ?? "",
      address: fetchedData.address ?? "",
      city: fetchedData.city ?? "",
      state_name: fetchedData.state_name ?? "",
      zipcode: fetchedData.zipcode ?? "",
      pid: fetchedData.pid ?? "",
      allergies: fetchedData.allergies ?? "",
      id_proof_type: fetchedData.id_proof_type ?? "",
      auto_renew: fetchedData.auto_renew ?? "",
      unsubscribed: fetchedData.unsubscribed ?? "",
    });
  }, [fetchedData]);
console.log(form.values.auto_renew)
  return isLoading ? (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col span={{ md: 4, lg: 4 }} style={{ paddingTop: "5rem" }}>
            <FileButton
              disabled={!props.isEditable}
              onChange={setPicture}
              accept="image/png,image/jpeg"
            >
              {(props) => (
                <div {...props} className="up_pro">
                  <div className="profile_picture">
                    <Image
                      fit="contain"
                      src={
                        picture === null
                          ? "../../assets/website/img/user.jpg"
                          : typeof picture === "string"
                          ? picture
                          : URL.createObjectURL(picture)
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
            </FileButton>
          </Grid.Col>
          <Grid.Col span={{ md: 7 }} style={{ paddingBlock: "5rem" }}>
            <Grid>
              <Grid.Col span={{ md: 4, sm: 12 }}>
                <TextInput
                  label="First Name"
                  radius="0.5rem"
                  size="lg"
                  disabled={!props.isEditable}
                  {...form.getInputProps("first_name")}
                />
              </Grid.Col>

              <Grid.Col span={{ md: 4, sm: 12 }}>
                <TextInput
                  label="Middle Name"
                  radius="0.5rem"
                  size="lg"
                  disabled={!props.isEditable}
                  {...form.getInputProps("middle_name")}
                />
              </Grid.Col>

              <Grid.Col span={{ md: 4, sm: 12 }}>
                <TextInput
                  label="Last Name"
                  radius="0.5rem"
                  size="lg"
                  disabled={!props.isEditable}
                  {...form.getInputProps("last_name")}
                />
              </Grid.Col>
            </Grid>

            <SimpleGrid spacing="xl">
              <DOB setDob={setDob} dob={dob} disabled={!props.isEditable}></DOB>
            </SimpleGrid>

            <SimpleGrid spacing="xl" cols={2}>
              {!props.isEditable && (
                <TextInput
                  label="Email"
                  radius="0.5rem"
                  mt="lg"
                  size="lg"
                  disabled={true}
                  {...form.getInputProps("email")}
                />
              )}

              {!props.isEditable && (
                <TextInput
                  label="Mobile No."
                  radius="0.5rem"
                  mt="lg"
                  size="lg"
                  disabled={true}
                  {...form.getInputProps("mobile")}
                />
              )}

              <Select
                label="Gender"
                radius="0.5rem"
                mt="lg"
                size="lg"
                disabled={!props.isEditable}
                data={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                  { label: "Other", value: "other" },
                ]}
                {...form.getInputProps("gender")}
              />

              {/* <DatePickerInput
                            label="Date of Birth"
                            placeholder="10-23-1990"
                            radius='0.5rem'
                            mt='lg'
                            size='lg'
                            disabled={!props.isEditable}
                            {...form.getInputProps('dob')}
                            valueFormat="MM-DD-YYYY"
                        /> */}

              {/* <Input.Wrapper label="Date of Birth" withAsterisk mt='lg' size="lg">
                            <Input
                                component={DatePicker}
                                selected={dob}
                                size="lg"
                                onChange={(v) => setDob(v)}
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange={true}
                                dropdownMode="select"
                                maxDate={subYears(new Date(), 18)}
                                customInput={<MaskedTextInput type="text" mask='99/99/9999' />}
                                dateFormat="MM/dd/yyyy"
                                disabled={!props.isEditable}
                            />
                        </Input.Wrapper> */}

              {userData.role === "ROLE_PATIENT" && (
                <>
                  {site_id != 2 && (
                    <>
                      <TextInput
                        label="License No"
                        radius="0.5rem"
                        mt="lg"
                        size="lg"
                        disabled={!props.isEditable}
                        {...form.getInputProps("driver_license_number")}
                      />

                      <TextInput
                        label=" Allergies? (If any) "
                        radius="0.5rem"
                        mt="lg"
                        size="lg"
                        disabled={!props.isEditable}
                        {...form.getInputProps("allergies")}
                      />
                    </>
                  )}

                  {userData.state_id == 45 && site_id != 2 && (
                    <TextInput
                      label="Pennsylvania Patient ID"
                      radius="0.5rem"
                      mt="lg"
                      size="lg"
                      disabled={!props.isEditable}
                      {...form.getInputProps("pid")}
                    />
                  )}

                  <TextInput
                    label="Address"
                    radius="0.5rem"
                    mt="lg"
                    size="lg"
                    disabled={!props.isEditable}
                    {...form.getInputProps("address")}
                  />

                  <TextInput
                    label="City"
                    radius="0.5rem"
                    mt="lg"
                    size="lg"
                    disabled={!props.isEditable}
                    {...form.getInputProps("city")}
                  />

                  {!props.isEditable && (
                    <TextInput
                      label="State"
                      radius="0.5rem"
                      mt="lg"
                      size="lg"
                      disabled={!props.isEditable}
                      {...form.getInputProps("state_name")}
                    />
                  )}

                <TextInput
                  label="Zipcode"
                  radius="0.5rem"
                  mt="lg"
                  size="lg"
                  disabled={!props.isEditable}
                  {...form.getInputProps("zipcode")}
                />
              </>
            )}
          </SimpleGrid>
          <Checkbox
              disabled={!props.isEditable}
              mt={20}
              {...form.getInputProps("auto_renew", { type: "checkbox" })}
              label="Activate Auto Renewal"
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  autoRechargeModal.open();
                } else {
                  form.setValues({ auto_renew: false });
                }
              }}
            />
            <Text size="sm">
              Would you like to streamline your experience and ensure
              uninterrupted service? Activate auto-renewal for the next year
              with just a simple click!
            </Text>
            {props.isEditable && form.values.auto_renew != 0 &&  <Button size="xs" variant="transparent" onClick={()=>{setUpdatePayment(true)
               autoRechargeModal.open()
            }}>Update payment information</Button>}
            <Checkbox
              mt={20}
              disabled={!props.isEditable}
              {...form.getInputProps("unsubscribed", { type: "checkbox" })}
              label="Unsubscribe me from all future emails"
            />
            {site_id != 2 && (
              <SimpleGrid>
                <div>
                  <div style={{ marginTop: "2rem" }}>Signature</div>
                  <img
                    src={fetchedData.signature}
                    style={{ maxWidth: "200px" }}
                  />
                </div>

                {userData.role === "ROLE_PATIENT" && (
                  <div>
                    <div style={{ marginTop: "2rem" }}>ID Proof</div>
                    {!props.isEditable && (
                      <img
                        src={fetchedData.id_proof}
                        style={{ maxWidth: "200px" }}
                      />
                    )}
                    {props.isEditable && (
                      <div>
                        <FileButton
                          onChange={setIdProof}
                          accept="image/png,image/jpeg"
                          required
                        >
                          {(props) =>
                            idProof ? (
                              <>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Clikc on the image to change it
                                </div>
                                <Image
                                  fit="contain"
                                  {...props}
                                  src={
                                    typeof idProof == "object"
                                      ? URL.createObjectURL(idProof)
                                      : idProof
                                  }
                                  style={{ maxWidth: "200px", margin: "auto" }}
                                />
                              </>
                            ) : (
                              <Button {...props} color="cyan">
                                Choose or Take a Picture{" "}
                                <span style={{ color: "#fa5252" }}>
                                  &nbsp;*
                                </span>
                              </Button>
                            )
                          }
                        </FileButton>
                      </div>
                    )}

                    <Select
                      label="ID Type"
                      mt="lg"
                      withAsterisk
                      size="sm"
                      placeholder="Pick one"
                      data={[
                        { label: "Driver's License", value: "driver_license" },
                        { label: "State ID Card", value: "idcard" },
                        { label: "Tribal ID Card", value: "tribal" },
                        {
                          label: "U.S. Passport/U.S. ID Card",
                          value: "passport",
                        },
                      ]}
                      disabled={!props.isEditable}
                      {...form.getInputProps("id_proof_type")}
                    />

                    {fetchedData.medical_record ? (
                      <>
                        <Space h="lg" />
                        <div style={{ marginTop: "2rem" }}>Medical Record</div>
                        {!props.isEditable && (
                          <img
                            src={fetchedData.medical_record}
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        {props.isEditable && (
                          <div>
                            <FileButton
                              onChange={setMedicalRecord}
                              accept="image/png,image/jpeg"
                              required
                            >
                              {(props) =>
                                medicalRecord ? (
                                  <>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Clikc on the image to change it
                                    </div>
                                    <Image
                                      fit="contain"
                                      {...props}
                                      src={
                                        typeof medicalRecord == "object"
                                          ? URL.createObjectURL(medicalRecord)
                                          : medicalRecord
                                      }
                                      style={{
                                        maxWidth: "200px",
                                        margin: "auto",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Button {...props} color="cyan">
                                    Choose or Take a Picture{" "}
                                    <span style={{ color: "#fa5252" }}>
                                      &nbsp;*
                                    </span>
                                  </Button>
                                )
                              }
                            </FileButton>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </SimpleGrid>
            )}

            {userData?.role === "ROLE_PATIENT" && site_id != 2 && (
              <div style={{ marginTop: "1em" }}>
                <MedicalConditionsCheckList
                  setSelectedDisease={setSelectedDiseases}
                  selectedDisease={selectedDiseases}
                />
              </div>
            )}

            {userData.role === "ROLE_DOCTOR" && (
              <ScrollArea>
                <div>
                  <h4>Licenses:</h4>
                  <Table
                    striped
                    highlightOnHover
                    withTableBorder
                    withColumnBorders
                    horizontalSpacing="md"
                    verticalSpacing="md"
                  >
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Number</Table.Th>
                        <Table.Th>Expire Date</Table.Th>
                        <Table.Th>State</Table.Th>
                        <Table.Th>Active?</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {fetchedData.licenses.map((l) => (
                        <Table.Tr>
                          <Table.Td>{l.number}</Table.Td>
                          <Table.Td>
                            {moment(l.expire_date).format("MM-DD-YYYY")} (
                            {moment(l.expire_date).diff(
                              moment(),
                              "days",
                              false
                            )}{" "}
                            days)
                          </Table.Td>
                          <Table.Td>{l.state_name}</Table.Td>
                          <Table.Td>
                            {l.is_active ? (
                              <Badge color="green">Yes</Badge>
                            ) : (
                              <Badge color="gray">No</Badge>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                </div>
              </ScrollArea>
            )}
          </Grid.Col>
        </Grid>

        {props.isEditable && (
          <div
            className="form-group button-group d-flex"
            style={{ textAlign: "center", marginBottom: "2em" }}
          >
            <Button type="submit" className="btn submit-btn">
              Update
              <LoadingOverlay
                visible={isSubmitLoading}
                overlayProps={{ blur: 2 }}
              />
            </Button>
          </div>
        )}
      </form>
      <AutoReChargeModal
      setUpdatePayment={setUpdatePayment}
      updatePayment={updatePayment}
        context={context}
        autoRechargeModal={autoRechargeModal}
        openAutoRechargeModal={openAutoRechargeModal}
        setAutoRenew={() => form.setValues({ auto_renew: true })}
      />
    </>
  );
}

export default ProfileForm;

import {
    ActionIcon,
    Badge,
    Box,
    Drawer,
    Loader,
    Paper,
    Stack
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { memo, useEffect, useState } from "react";
import { IoIosMore } from "react-icons/io";
import { Link } from "react-router-dom";
import "../../App.css";
import axios from "../../axios2";
import { statusColors } from "./utils";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
const SlotDataDrawer = memo(function SlotDataDrawer({ date }) {
  
  const [leadData, setLeadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);
  const fetchAllAppointments = async () => {
    const res = await axios.get(
      `/steadycare-leads/appointments?day=${date.day}&time=${date.time}&timezone=${process.env.REACT_APP_TIMEZONE}`
    );
    const transformedData = Object.values(res.data).map((value) => ({
      ...value,
    }));
    const schedules = transformedData?.flatMap((item) =>
      item.appointments?.map((person) => {
        const personDateTime = dayjs(person.due_date).tz(
          process.env.REACT_APP_TIMEZONE
        );
        return {
          name: person.name,
          dueTime: person.due_date,
          status: person.status,
          leadId: person?.lead_id,
          time: personDateTime.format("HH:mm"),
          day: personDateTime.format("YYYY-MM-DD"),
        };
      })
    );
    return schedules;
  };

  useEffect(() => {
    if (!date) return;
    open();
    async function loadAllAppointments() {
      setIsLoading(true);
      try {
        const newLeadData = await fetchAllAppointments(date.day, date.time);
        setLeadData(newLeadData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    loadAllAppointments();
  }, [date]);

  return (
    <>
      <Drawer
        onClose={close}
        opened={opened}
        title={
          <p style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            Scheduled leads for{" "}
            <Badge>
              {dayjs(date.day).format("MMM D, YYYY")} at {date.time}
            </Badge>
          </p>
        }
        padding="md"
        size="md"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Stack spacing="sm">
            {!leadData.length && (
              <p>No scheduled leads for the selected date were found.</p>
            )}
            {leadData.map((lead) => (
              <Link
                to={`/leads/${lead.leadId}`}
                target="_blank"
                key={lead.leadId}
                style={{ textDecoration: "none" }}
              >
                <Paper p="sm" withBorder>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>{lead.name}</Box>
                    <Box>
                      <Badge color={statusColors[lead.status]} variant="light">
                        {lead.status}
                      </Badge>
                    </Box>
                    <Box>
                      <Badge variant="outline">
                        {lead.day} at {lead.time}
                      </Badge>
                    </Box>
                  </Box>
                </Paper>
              </Link>
            ))}
          </Stack>
        )}
      </Drawer>
    </>
  );
});

export default SlotDataDrawer;

import React,{useState,useEffect} from 'react';
function Footer() {
  return (
    <>
    <div className="page-footer">
			<p>{ (new Date()).getFullYear() } © { process.env.REACT_APP_TITLE }</p>
		</div>
    </>
 );
}
export default Footer;
import { Button, Card, Text } from "@mantine/core";
import { AiOutlineShopping } from "react-icons/ai";
import { Link } from "react-router-dom";
function OrderNow() {
  return <Card shadow="sm" p={30} radius="md" withBorder style={{textAlign: 'center', margin: 'auto'}}>
    <Card.Section p={10}>
      <div>
    <AiOutlineShopping size="6em"></AiOutlineShopping>
    <h5>You don't have any in-progress orders.</h5>
        <br />
        <Link to='/order/plan'><Button m={5} color="green">Order Now</Button></Link>
    
    <Text>You can also contact us to place your order</Text>
      </div>
    </Card.Section>
    </Card>
}
export default OrderNow;
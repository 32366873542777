import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { FaExclamationCircle } from "react-icons/fa";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
  params: {},
});

const token =
  typeof sessionStorage !== "undefined"
    ? localStorage.getItem("access_token")
    : "";
instance.defaults.headers.common = { Authorization: `Bearer ${token}` };

instance.show_notif = true;
instance.withCredentials = true;

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      if (instance.show_notif) {
        showNotification({
          title: "Error",
          message: error.response.data.message,
          color: "red",
          icon: <FaExclamationCircle />,
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;

import React, {useState, useEffect, useContext} from "react";
import Moment from "react-moment";
import "moment-timezone";
import AppContext from "../../AppContext";
import axios2 from "../../axios2";
import { Card, Group, LoadingOverlay, Select } from "@mantine/core";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

const IncomeReport = () => {
    
    const context = useContext(AppContext).context;
    const [data, setData] = useState([]);
    console.log('data', data)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
    }, []);

    const update = function (state_id) {
        setLoading(true);
        axios2.get('/report/dr/income?state_id='+state_id).then((resp) => {
            const p = resp.data.data;
            setData(p);
            setLoading(false);
        })
    }

    return (
        <>
            <Card>
                <Select placeholder="Please select a state" onChange={update} data={context.user.licenses.map(l => ({ value: l.state_id.toString(), label: l.state_name }))}></Select>
                <Group justify="center">
                    {loading ? <LoadingOverlay /> :
                        <LineChart
                            data={data}
                            width={800}
                            height={600}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis/>
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" name="INCOME" dataKey="total_income" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>}
                </Group>
            </Card>
        </>
    );
};
export default IncomeReport;

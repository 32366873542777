import React, {useContext, useEffect, useState} from "react";
import axios from "../../axios2";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Switch } from "@mantine/core";
import Moment from "react-moment";
import styles from "../../styles/OrderDetails.module.css"
import OrderStatus from "../../components/OrderStatus";
import { showNotification } from "@mantine/notifications";

function Renew() {

    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [canceled, setCanceled] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getOrder();
      }, []);

      const toggle = () => {
        setLoading(true);
        axios.post('/orders/'+id+'/set-auto-renew', {canceled: !canceled})
        .then(() => {
            setCanceled(!canceled);
        })
        .finally(() => setLoading(false))
      }

    const getOrder = () => {
        axios.get('orders/' + id)
          .then((res) => {
            const order = res.data.data;
            if(!order.user.is_medical_info_completed || !order.user.has_payment_profile){
                navigate('/order/plan' + (order.plan.renew_plan_id ? '?plan=' + order.plan.renew_plan_id : ''));
            }
            setOrder(order);
            setCanceled(order.auto_renew_canceled);
          });
      }

    const download = (recom) => {
        window.open(recom.file + '?download=1');
    }
    
    return (
        <>
            <div style={{paddingInline: '1rem', textAlign: 'center', maxWidth: '500px', margin: 'auto'}}>
            <Card shadow="sm" p={30} radius="lg" withBorder>
                <Card.Section p={10}>
                    <h3>Order #{order.id}</h3>
                    <div className={styles.infoWrapper}>
                        <div className={styles.infoRow}>
                            <strong>Date: </strong>
                            <Moment format="MM/DD/YYYY">{order.created_at}</Moment>
                        </div>
                        <div className={styles.infoRow}>
                            <strong>Plan name: </strong>
                            {order.plan_name}
                        </div>
                        {!!order.plan?.has_id_card && <div className={styles.infoRow}>
                            <strong>ID CARD: </strong>
                            {order.has_id_card ? 'Yes' : 'No'}
                        </div>}
                        <div className={styles.infoRow}>
                            <strong>State Applied For: </strong>
                            {order.state_name}
                        </div>
                        <div className={styles.infoRow}>
                            <strong>Status: </strong>
                            <OrderStatus status={order.status}></OrderStatus>
                        </div>
                </div>
                <div style={{width: '280px', margin: 'auto'}}>
                <Switch
                    label="Activate Auto Renew"
                    size="lg"
                    checked={!canceled}
                    onChange={() => toggle()}
                    disabled={loading}
                    color="green"
                 />
                </div>
        </Card.Section>
        </Card>
            </div>
        </>
    );
}

export default Renew;
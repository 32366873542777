import React,{Fragment,Outlet} from 'react';
import { useNavigate, Link, matchRoutes, useLocation } from "react-router-dom";

// import Header from './Topbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Topbar  from './Topbar';
const Layout = ({ children }) => {
    return (
         <main id="dashboard"> 
            <div className="page-container">
                <div className="settings-overlay"></div>
                <div id="doctor_dashboard" className="page-content">
                    <Sidebar></Sidebar>
                    <div className="page-inner no-page-title">
                        <Topbar></Topbar>	   
                        <div id="main-wrapper">
                                {children}
                         </div>
                        <Footer></Footer>
                    </div>
                
                </div>
                
            </div>
            
		</main>

    );
};
export default Layout;
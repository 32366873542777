import {Checkbox, Flex, Grid, SimpleGrid, Space} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import axios2 from "../../axios2";
import AppContext from "../../AppContext";

function MedicalConditionsCheckList({ selectedDisease, setSelectedDisease }) {
    
    const context = useContext(AppContext).context;
    const [disease, setDisease] = useState([]);
    const [other, setOther] = useState({});

    useEffect(() => {
        axios2.get('/medical-conditions?state_id=' + context?.user?.state_id)
            .then(res => {
                setDisease(res.data.data.filter(item => item.title != 'Other'));
                setOther(res.data.data.find(item => item.title == 'Other'))
            });
    }, []);

    const handleChange = (e, item) => {
        const checked = e.currentTarget.checked;
        setSelectedDisease((pre) => {
            const toDeleteIndex = pre.indexOf(item.id);
            return checked
                ? pre.concat(item.id)
                : pre.filter((_, index) => index !== toDeleteIndex)
        });
    }

    return (<>
        <Space h='lg'></Space>
        <h3>Medical Conditions</h3>
        <Flex wrap='wrap' justify='start'>
            {disease.length > 0 && disease.map((item) => <div style={{margin: '0.5em 1em', width: '250px'}}>
                    <Checkbox
                        size='sm'
                        label={item.title}
                        onChange={(e) => handleChange(e, item)}
                        defaultChecked={selectedDisease.includes(item.id)}
                    />
                </div>
            )
            }
            {other && <div style={{margin: '0.5em 1em', width: '100%' }}><Checkbox
                size='sm'
                styles={{label: {color: '#972f17 !important', fontWeight: 'bold'}}}
                label={other.title}
                onChange={(e) => handleChange(e, other)}
                checked={selectedDisease.includes(other.id)}
            /></div>}
        </Flex>
    </>
    );
}

export default MedicalConditionsCheckList;
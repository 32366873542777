import { Flex, Group, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";

function DOB({ dob, setDob, disabled }) {
  const [days, setDays] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const site_id = process.env.REACT_APP_SITE_ID;
  
  console.log(years);
  const month_days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const form = useForm({
    initialValues: {
      month: 1,
    },
    validate: {
      month: (value) => (!value ? "Please select a month" : null),
      year: (value) => (!value ? "Please select a year" : null),
      day: (value) => (!value ? "Please select a day" : null),
    },
  });

  useEffect(() => {
    console.log("dob updated:", dob);
    const y = new Date().getFullYear()
    const _years = [];
    for (let i = y; i > y - 100; i--) {
      _years.push({ label: i, value: i });
    }
    setYears(_years);
    setMonths([
      { label: "January", value: "1" },
      { label: "February", value: "2" },
      { label: "March", value: "3" },
      { label: "April", value: "4" },
      { label: "May", value: "5" },
      { label: "June", value: "6" },
      { label: "July", value: "7" },
      { label: "August", value: "8" },
      { label: "September", value: "9" },
      { label: "October", value: "10" },
      { label: "November", value: "11" },
      { label: "December", value: "12" },
    ]);
    if (dob && dob.length > 8) {
      const [year, month, day] = dob.split("-");
      console.log({ day, month });
      if (year && month && day) {
        form.setValues({
          month: parseInt(month).toString(),
          day: parseInt(day.substr(0, 2)).toString(),
          year,
        });
      }
    }
  }, [dob]);

  useEffect(() => {
    if (!form.values.month) return;
    const _days = [];
    for (let i = 1; i <= month_days[form.values.month]; i++) {
      _days.push({ label: i, value: i });
    }
    setDays(_days);

    // set dob
    if (form.values.year && form.values.month && form.values.year) {
      setDob(
        form.values.month + "/" + form.values.day + "/" + form.values.year
      );
    }
  }, [form.values]);
  // console.log(days.map(day=>({label:day.label,value:day.value.toString()})));
  const stringifiedDays = days.map((day) => ({
    label: day.label.toString(),
    value: day.value.toString(),
  }));
  const stringifiedYears = years.map((year) => ({
    label: year.label.toString(),
    value: year.value.toString(),
  }));
  return (
    <div style={{ marginTop: "1em" }}>
      <label>Date of Birth</label>
      <Flex
        justify="space-between"
        style={{ position: "relative", top: "-10px", width: "100%" }}
      >
        <Select
          label="Month"
          withAsterisk
          disabled={disabled}
          size="sm"
          data={months}
          {...form.getInputProps("month")}
        />
        <Select
          label="Day"
          withAsterisk
          disabled={disabled}
          size="sm"
          data={stringifiedDays}
          {...form.getInputProps("day")}
        />
        <Select
          label="Year"
          withAsterisk
          disabled={disabled}
          size="sm"
          data={stringifiedYears}
          {...form.getInputProps("year")}
        />
      </Flex>
    </div>
  );
}

export default DOB;

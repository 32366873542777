import { ActionIcon, Box, Grid, Group, Text } from "@mantine/core";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import "../../App.css";
import { renderAppointment, timeSlots } from "./utils";
import { IoIosMore } from "react-icons/io";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import axios from "../../axios2";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
export default function BigCalendarSlots({
  days,
  openAddLead,
  patientSchedule,
  openDrawer,
  openDetailModal,
}) {

  const [appointments, setAppointments] = useState([]);

  const onDragEnd = (result) => {
    console.log(result);
    const appointment = patientSchedule.find(a => a.id == result.draggableId);
    const appointmentTime = dayjs(appointment.due_date).tz(
      process.env.REACT_APP_TIMEZONE
    );
    let day = appointmentTime.format('YYYY-MM-DD');
    let hour = appointmentTime.format('HH');
    let minute = appointmentTime.format('mm');
    appointments[day][hour][minute] =  appointments[day][hour][minute].filter(a => a.id !== appointment.id);

    console.log('time1', day, hour, minute);
    
    const due_date = dayjs.unix(result.destination.droppableId).tz(
      process.env.REACT_APP_TIMEZONE
    );
    console.log('due', due_date);
    day = due_date.format('YYYY-MM-DD');
    hour = due_date.format('HH');
    minute = due_date.format('mm');

    if (!appointments[day]) {
      appointments[day] = {};
    }
    if (!appointments[day][hour]) {
      appointments[day][hour] = {};
    }
    if (!appointments[day][hour][minute]) {
      appointments[day][hour][minute] = [];
    }

    console.log('time2', day, hour, minute);

    appointments[day][hour][minute].push(appointment);

    axios.post('/steadycare-leads/appointments/' + appointment.id + '/move', {date: due_date});

    setAppointments(appointments);
  }

  useEffect(() => {

    if(!patientSchedule || patientSchedule.length == 0) return;

    setAppointments(patientSchedule.reduce((acc, appointment) => {
      const appointmentTime = dayjs(appointment.due_date).tz(
        process.env.REACT_APP_TIMEZONE
      );
      const day = appointmentTime.format('YYYY-MM-DD');
      const hour = appointmentTime.format('HH');
      const minute = appointmentTime.format('mm');
  
      // Initialize structure if not already present
      if (!acc[day]) {
        acc[day] = {};
      }
      if (!acc[day][hour]) {
        acc[day][hour] = {};
      }
      if (!acc[day][hour][minute]) {
        acc[day][hour][minute] = [];
      }
  
      // Group the appointment under the respective day, hour, and minute
      acc[day][hour][minute].push(appointment);
  
      return acc;
    }, {}));

  }, [patientSchedule]);


  return (<>
  <DragDropContext onDragEnd={onDragEnd}>
      {timeSlots.map((time) => (
        <React.Fragment key={time.toISOString()}>
          <Grid.Col span={1}>
            <Text ta="left" size="xs" c="dimmed" pt={4}>
              {time.format("HH:mm")}
            </Text>
          </Grid.Col>
          {days.map((day, index) => {
            return (
              <Grid.Col span={1.5} key={index}>
                <Droppable droppableId={day.set({
                  hour: time.hour(),
                  minute: time.minute(),
                }).unix().toString()}>
                {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="droppable-area"
                        style={{height: "100%",}}
                      >
                        <Box
                          className="slotcontainer"
                          style={{
                            flexShrink: 0,
                            border: "1px solid #eee",

                            minHeight: "60px",
                            height: "100%",
                            padding: "0.5rem",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                          }}
                        >
                        <Group
                          position="right"
                          spacing={4}
                          style={{ marginBottom: 4 }}
                        >
                          <ActionIcon
                            className="slotbutton"
                            size="xs"
                            variant="subtle"
                            onClick={() => openDrawer(day.format('YYYY-MM-DD'), time.format('HH:mm'))}
                          >
                            <IoIosMore />
                          </ActionIcon>
                          <ActionIcon
                            className="slotbutton"
                            size="xs"
                            variant="subtle"
                            onClick={() => openAddLead(day.format('YYYY-MM-DD'), time.format('HH:mm'))}
                          >
                            <FaPlus />
                          </ActionIcon>
                        </Group>
                          {renderAppointment(appointments[day.format('YYYY-MM-DD')]?.[time.format('HH')]?.[time.format('mm')] ?? [], openDetailModal)}
                          {provided.placeholder}
                        </Box>
                        </div>
                )}
                </Droppable>
              </Grid.Col>
            );
          })}
        </React.Fragment>
      ))}
    </DragDropContext>
  </>
  );
}

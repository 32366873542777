import React,{useState } from 'react';
import Parser from 'html-react-parser';
import label from "../../config/PatientLabel";
import Axios from "../../axios";
import { useNavigate,Link } from 'react-router-dom';
function Resetpassword(){
    const navigate = useNavigate();

    const [loadingRButton, setloadingRButton] = useState(0);
    const [responseError, setresposeError] = useState(0);
    const [emailResponse, setemailResponse] = useState(0);
    const [emailResponseStatus, setemailResponseStatus] = useState(0);
    let params = (new URL(document.location)).searchParams;
    const code = params.get("code");
    let [account,setAccount] = useState({
        password: '',
        cpassword: '',
        passwordError:'',
        cpasswordError:'',
         
    });
let handleChange = (e) => {
    setresposeError(0)
    let name = e.target.name;
    let value = e.target.value;
    account[name] = value;
    setAccount(account);
    validate();
}

const validate = (e) => {
    let passwordError = "";
    let cpasswordError = "";
        
    
    if (!account.password) {
      passwordError = "Password field is required";
    }
    if (!account.cpassword) {
        cpasswordError = "Confirm Password field is required";
    }
    if(account.password !== account.cpassword){
        passwordError = "Password not matched.";
    }
    
    
    if (passwordError || cpasswordError) {
        
        let password = account.password;
        let cpassword = account.cpassword;
        
        setAccount({  passwordError,  cpasswordError, password, cpassword });
        
      return false;
    }
   
    let password = account.password;
    let cpassword = account.cpassword;
    
    setAccount({  passwordError,  cpasswordError, password, cpassword });    
    return true;
  }
    const hideEmailverifyMessage = ()=>{
        setemailResponseStatus('null');
        setemailResponse('');
    }
  let SubmitPressed = (e) => {
    e.preventDefault();
    if (validate()) {
        setloadingRButton(1)
        if(account.password === account.cpassword){
            Axios.post('/mUpdatePassword', { password: account.password,password_confirmation: account.cpassword,code:code })
                .then(res => {
                // console.log(res);
                 console.log(res.data);
                if(res.data.status === 'Success'){
                    setemailResponseStatus(res.data.status);
                    setemailResponse(res.data.message);
                    setTimeout(() => {
                      //  navigate('/login');
                    }, 5000)
                }else{
                    setemailResponseStatus(res.data.status);
                    setemailResponse(res.data.message);
                    e.target.reset();
                }
                setloadingRButton(0);
            })
        }else{
            setresposeError('Password not matched.');
        }
      }
    
}
    return(
        <main id="dashboard"> 
        <div className="page-container">
            <div className="login d-lg-flex align-items-lg-center">
                <div className="login-content">
                    <div className="login-box">
					    
					    <div className="login-logo">
                            <div className="content"><Link to="/"><img src="../../assets/website/img/omc-logo-new 1.png" alt="logo"/></Link></div>
						</div>
                        <div className="login-header">
                            <div className="content">{Parser(label.resetpassword_heading_description)}</div>
                        </div>
                        <div className={`emailResponse ${emailResponseStatus} == 'Success'? forgotPasswordAlert : ''}`} >
                            {emailResponseStatus === 'Success' ?
                            <div className="alert success">
                                <span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
                                <i className="fa fa-check fa-1x"></i> 
                                <b>{emailResponse}</b>
                            </div>
                            :emailResponseStatus === 'Failed'?
                            <div className="alert danger">
                                <span className="closebtn danger" onClick={hideEmailverifyMessage}>×</span>
                                <i className="fa fa-check fa-1x"></i> 
                                <b>{emailResponse}</b>
                            </div>
                            :
                            ''
                            }
                        </div>
                        <div className="login-body">
                            <div className="row">
							  <div className="col-xl-12">
							    <form onSubmit={SubmitPressed}>
									<div className="form-group">
										<img src="img/password.png" alt=""/>
										<input type="password" className="form-control" name='password' onChange={handleChange} placeholder="New Password"autoComplete='' />
									</div>
                                    <span className="text-danger">{account.passwordError}</span>
                                    <div className="form-group">
										<img src="img/password.png" alt=""/>
										<input type="password" className="form-control" name='cpassword' onChange={handleChange}  placeholder="Confirm Password" autoComplete=''/>
									</div>
                                    <span className="text-danger">{account.cpasswordError}</span>
                                    {loadingRButton === 1 ? 
									<button className="btn btn-primary" disabled>
										<i className="fa fa-circle-o-notch fa-spin"></i> Loading
									</button>
									:
									<button type="submit" className="btn btn-primary">{label.resetpassword_button}</button>}
								</form>
                                <p className="m-t-sm forgot"><Link to="/login">Login</Link></p>
                                <p className="m-t-sm forgot"><Link to="/">{label.login_create}</Link></p>
							  </div>
							</div>
                        </div>
                        <div className="login-footer">
                            <p>2022 © OnlineMedicalCard</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </main>
    )
}
export default Resetpassword;
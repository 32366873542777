import { Button, Group } from "@mantine/core";
import AddressForm from "./AddressForm";
import React, { useContext, useState } from "react";
import { useForm } from "@mantine/form";
import { TextInput, NativeSelect } from "@mantine/core";
import { FaCreditCard } from "react-icons/fa";
import amex from "../assets/amexCard.png";
import visa from "../assets/visa.png";
import masterCard from "../assets/masterCard.png";
import discover from "../assets/discover.png";
import AppContext from "../AppContext";
import axios2 from "../axios2";
import { notifications } from "@mantine/notifications";
function BillingForm({ setAutoRenew, close ,setUpdatePayment}) {
  const [isLoading, setIsLoading] = useState(false);
  const { context, setContext } = useContext(AppContext);
  let notEmpty = "Must not be empty";
  const years = Array(15)
    .fill(new Date().getFullYear())
    .map((_, i) => (_ + i).toString());
  years.unshift("");
  const form = useForm({
    initialValues: {
      billing_name: context?.user?.name,
      billing_state_id: context?.user?.state_id?.toString(),
      billing_city: context?.user?.city,
      billing_address: context?.user?.address,
      billing_zipcode: context?.user?.zipcode,
      card_expire_month: "",
      card_expire_year: "",
      card_number: "",
    },
    validate: {
      billing_address: (value) =>
        !value ? "Billing Address " + notEmpty : null,
      billing_name: (value) => (!value ? "Billing Name " + notEmpty : null),
      billing_city: (value) => (!value ? "Billing City " + notEmpty : null),
      billing_state_id: (value) =>
        !value ? "Billing State " + notEmpty : null,
      billing_zipcode: (value) =>
        !value ? "Billing Zipcode " + notEmpty : null,
      card_number: (value) => (!value ? "Card Number " + notEmpty : null),
      card_holder_name: (value) => (!value ? "Card Name " + notEmpty : null),
      card_expire_month: (value) =>
        !value ? "Card Expire Month " + notEmpty : null,
      card_expire_year: (value) =>
        !value ? "Card Expire Year " + notEmpty : null,
      card_cvv: (value) => (!value ? "Card CVV " + notEmpty : null),
    },
  });
  function formatCardNumber(value) {
    const input = value.replace(/\D/g, "");
    let formattedInput = "";
    for (let i = 0; i < input.length; i += 4) {
      formattedInput += input.slice(i, i + 4) + "-";
    }
    return formattedInput.slice(0, -1);
  }
  function getCardTypeImage(values) {
    const firstDigit = values.replace(/\D/g, "").charAt(0);
    switch (Number(firstDigit)) {
      case 3: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "60px", marginRight: "1.1rem" }}
            src={amex}
          />
        );
      }
      case 4: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "50px", marginRight: "1rem" }}
            src={visa}
          />
        );
      }
      case 5: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "50px", marginRight: "1rem" }}
            src={masterCard}
          />
        );
      }
      case 6: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "40px", marginRight: "1rem" }}
            src={discover}
          />
        );
      }
      default: {
        return <FaCreditCard />;
      }
    }
  }
  function addPaymentProfile(values) {
    setIsLoading(true);
    const expireMonth = values.card_expire_month;
    const expireYear = values.card_expire_year;
    const card_expire_date =
      expireYear +
      "-" +
      (expireMonth < 10
        ? "0" + expireMonth.toString()
        : expireMonth.toString());
    const formData = {
      billing_state_id: values.billing_state_id,
      billing_address: values.billing_address,
      billing_city: values.billing_city,
      billing_zipcode: values.billing_zipcode,
      card_cvv: values.card_cvv,
      card_number: values.card_number,
      card_holder_name: values.card_holder_name,
      card_expire_date,
    }
    setIsLoading(true);
    axios2
      .post("/user/payment-profile",formData)
      .then((res) => {
        // setAutoRenew()
        setContext({...context, user: res.data.data})
        close()
      }).catch ((error)  => {
        notifications.show({ 
          message: error.response.data.message,
        });
      }).finally(() =>{
        setIsLoading(false);
        setUpdatePayment(false)
      });
    }
  return (
    <div key={context}>
      <form onSubmit={form.onSubmit((values) => addPaymentProfile(values))}>
        <AddressForm form={form} type="billing"></AddressForm>
        <div className="wrapper">
          <h3 className="header">Credit/Debit Card</h3>
          <TextInput
            label="Card Number"
            size="sm"
            withAsterisk
            maxLength={19}
            value={formatCardNumber(form.values.card_number)}
            onChange={(e) =>
              form.setFieldValue(
                "card_number",
                e.target.value.replace(/-/g, "")
              )
            }
            rightSection={getCardTypeImage(form.values.card_number)}
          />
          <TextInput
            label="Name on Card"
            size="sm"
            withAsterisk
            {...form.getInputProps("card_holder_name")}
          />
          <Group justify="space-between" grow>
            <NativeSelect
              label="Month"
              size="sm"
              withAsterisk
              data={[
                "",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ]}
              {...form.getInputProps("card_expire_month")}
            />
            <NativeSelect
              label="Year"
              size="sm"
              withAsterisk
              data={years}
              {...form.getInputProps("card_expire_year")}
            />
            <TextInput
              label="CVV"
              size="sm"
              width="100px"
              withAsterisk
              {...form.getInputProps("card_cvv")}
            />
          </Group>
        </div>
        <Button
          type="submit"
          fullWidth
          mt="2rem"
          loading={isLoading}
          disabled={isLoading}
        >
          Add payment profile
        </Button>
      </form>
    </div>
  );
}
export default BillingForm;

//https://admin.onlinemedicalcard.com/api/v2/user/payment-profile
// billing_state_id
// billing_address
// billing_city
// billing_zipcode
// card_number
// card_holder_name
// card_expire_date
// card_cvv

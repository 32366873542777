import label from "../../config/PatientLabel";
import React, { useContext, useEffect, useState } from "react";
import "../../assets/website/css/pricing.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import AppContext from "../../AppContext";
import {
  Checkbox,
  Divider,
  Grid,
  Select,
  TextInput,
  Button,
  NumberInput,
  LoadingOverlay,
  NativeSelect,
  Group,
  Table,
  Accordion,
  Modal,
  Text,
  Alert,
  List,
} from "@mantine/core";
import axios2 from "../../axios2";
import { notifications } from "@mantine/notifications";
import AddressForm from "../../components/AddressForm";
import { FaCheckCircle, FaCreditCard, FaInfoCircle } from "react-icons/fa";
import format from "date-fns/format";
import { useDisclosure } from "@mantine/hooks";
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import amex from "../../assets/amexCard.png";
import visa from "../../assets/visa.png";
import masterCard from "../../assets/masterCard.png";
import discover from "../../assets/discover.png";

const years = Array(15)
  .fill(new Date().getFullYear())
  .map((_, i) => (_ + i).toString());
years.unshift("");

function Payment() {
  const notEmpty = "must not be empty";

  const site_id = process.env.REACT_APP_SITE_ID;

  const navigate = useNavigate();

  const { context, refreshContext } = useContext(AppContext);

  const [checked, setChecked] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [amounts, setAmounts] = useState({});

  const [selectedPlan, setSelectedPlan] = useState({});

  const [openReviewModal, { open, close }] = useDisclosure();
  const [openAutoRechargeModal, autoRechargeModal] = useDisclosure();

  const [tempData, setTempData] = useState({});

  const [autoRenew, setAutoRenew] = useState(false);
  useEffect(() => {
    if (!context.selectedPlan || !context.selectedPlan.id) {
      navigate("/order/plan");
      return;
    }

    setSelectedPlan(context.selectedPlan);

    handleCoupon();

    //const saved_data = JSON.parse(localStorage.getItem('payment_form'));
    //delete saved_data['shipping_state_id'];
    //form.setValues(saved_data);
  }, []);

  const form = useForm({
    initialValues: {
      shipping_name: context?.user?.name,
      shipping_state_id: context?.user?.state_id?.toString(),
      billing_state_id: context?.user?.state_id?.toString(),
      shipping_city: context?.user?.city,
      shipping_address: context?.user?.address,
      shipping_zipcode: context?.user?.zipcode,
      card_expire_month: "",
      card_expire_year: "",
      card_number: "",
      auto_renew: true,
    },
    validate: {
      shipping_address: (value) =>
        !value ? "Shipping Address " + notEmpty : null,
      shipping_city: (value) => (!value ? "Shipping City " + notEmpty : null),
      shipping_zipcode: (value) =>
        !value ? "Shipping Zipcode " + notEmpty : null,
      billing_address: (value) =>
        !value && !checked ? "Billing Address " + notEmpty : null,
      billing_name: (value) =>
        !value && !checked ? "Billing Name " + notEmpty : null,
      billing_city: (value) =>
        !value && !checked ? "Billing City " + notEmpty : null,
      billing_state_id: (value) =>
        !value && !checked ? "Billing State " + notEmpty : null,
      billing_zipcode: (value) =>
        !value && !checked ? "Billing Zipcode " + notEmpty : null,
      card_number: (value) =>
        !value && amounts.payable_amount != 0
          ? "Card Number " + notEmpty
          : null,
      card_holder_name: (value) =>
        !value && amounts.payable_amount > 0 ? "Card Name " + notEmpty : null,
      card_expire_month: (value) =>
        !value && amounts.payable_amount > 0
          ? "Card Expire Month " + notEmpty
          : null,
      card_expire_year: (value) =>
        !value && amounts.payable_amount > 0
          ? "Card Expire Year " + notEmpty
          : null,
      card_cvv: (value) =>
        !value && amounts.payable_amount != 0 ? "Card CVV " + notEmpty : null,
    },
  });
  function handleCoupon() {
    setIsLoading(true);
    axios2
      .get(
        "/orders/amounts?plan_id=" +
          context.selectedPlan.id +
          "&state_id=" +
          context?.user?.state_id +
          "&coupon_code=" +
          couponCode +
          "&has_id_card=" +
          context.selectedPlan.has_id_card +
          "&additional_pets=" +
          context.selectedPlan.additional_pets_number +
          (context?.partner?.id ? "&partner_id=" + context?.partner?.id : "")
      )
      .then((result) => {
        const amounts = result.data.data;

        if (!context?.partner?.id && amounts.partner_id) {
          localStorage.setItem("partner_source", amounts.partner_source);
          refreshContext(null, selectedPlan, true);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'begin_checkout',
          site_id: site_id, 
          value: amounts.payable_amount,
          currency: 'USD',
          coupon: amounts.coupon_code,
          state: context?.user?.state_id,
        });

        setAmounts(amounts);
        if (!couponCode) return;
        if (amounts.coupon_is_valid) {
          console.log("invalied");
          notifications.show({
            title: "Coupon Applied.",
            message: "Coupon applied on your order.",
            color: "green",
          });
        } else {
          console.log("valid");
          notifications.show({
            title: "Coupon is invalid.",
            message: "Your coupon code is invalid.",
            color: "red",
          });
        }
      })
      .finally(() => setIsLoading(false));
  }

  const handleNextClick = (data) => {
    setTempData(data);
    checkBillingData();
    open();
  };

  const checkBillingData = () => {
    if (checked) {
      Object.keys(form.values).map((key) => {
        if (key.substring(0, 7) !== "billing") {
          form.values["billing" + key.substring(8)] = form.values[key];
        }
      });
    }
  };

  const submitForm = (data) => {
    close();
    checkBillingData();
    if (amounts.payable_amount > 0) {
      const expireMonth = data["card_expire_month"];
      const expireYear = data["card_expire_year"];
      data["card_expire_date"] =
        expireYear +
        "-" +
        (expireMonth < 10
          ? "0" + expireMonth.toString()
          : expireMonth.toString());

      delete data["card_expire_year"];
      delete data["card_expire_month"];
    }
    delete data["shipping_name"];
    delete data["billing_name"];

    data["auto_renew"] = autoRenew ? true : false;

    data["has_id_card"] = context.selectedPlan.has_id_card === 1;
    data["additional_pets"] = context.selectedPlan.additional_pets_number;
    data["coupon_code"] = couponCode;
    data["partner_id"] = context?.partner?.id ? context?.partner?.id : 0;
    data["plan_id"] = context.selectedPlan.id;
    data["state_id"] = context?.user?.state_id;
    console.log(data);
    setIsLoading(true);
    axios2
      .post("/orders", data)
      .then((result) => {
        setIsLoading(false);
        console.log(data);
        localStorage.removeItem("payment_form");
        localStorage.removeItem("partner_source");
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase', 
          value: result.data.data.paid_amount, 
          currency: 'USD',
          transaction_id: result.data.data.id,
          coupon: couponCode,
          state: result.data.data.state_name,
          site_id: result.data.data.site_id,
          items: [
            {
              item_id: result.data.data.plan_id,
              item_name: result.data.data.plan_name,
              price: result.data.data.plan_price
            }
          ]
        });
        if (result.data.data.has_questions == 1) {
          navigate("/questionnaire/" + result.data.data.id);
        } else {
          navigate("/dashboard");
        }
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(data)
        form.setValues({
          card_expire_month: data.billingire_month,
          card_expire_year: data.billingire_year,
        });
      });
  };
  function formatCardNumber(value) {
    const input = value.replace(/\D/g, "");
    let formattedInput = "";
    for (let i = 0; i < input.length; i += 4) {
      formattedInput += input.slice(i, i + 4) + "-";
    }
    return formattedInput.slice(0, -1);
  }
  function getCardTypeImage(values) {
    const firstDigit = values.replace(/\D/g, "").charAt(0);
    switch (Number(firstDigit)) {
      case 3: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "60px", marginRight: "1.1rem" }}
            src={amex}
          />
        );
      }
      case 4: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "50px", marginRight: "1rem" }}
            src={visa}
          />
        );
      }
      case 5: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "50px", marginRight: "1rem" }}
            src={masterCard}
          />
        );
      }
      case 6: {
        return (
          <img
            alt="credit card logo"
            style={{ width: "40px", marginRight: "1rem" }}
            src={discover}
          />
        );
      }
      default: {
        return <FaCreditCard />;
      }
    }
  }
  return (
    <div
      className="container content-body"
      id="steps"
      style={{ textAlign: "left", padding: "1em" }}
    >
      <div className="page-listing mt-4 mb-5">
        <ul>
          <li className="filled">
            <Link to="/order/plan">
              <span></span> <label>Plan</label>
            </Link>
          </li>
          <li className="filled">
            <Link to="/order/medical-info">
              <span></span> <label>Medical Info</label>
            </Link>
          </li>
          <li className="current">
            <Link to="/order/payment">
              <span></span> <label>Payment</label>
            </Link>
          </li>
        </ul>
      </div>

      <form onSubmit={form.onSubmit((values) => handleNextClick(values))}>
        <Grid>
          <Grid.Col span={{ lg: 7 }}>
            <div className="wrapper">
              <AddressForm form={form} type="shipping"></AddressForm>
              <Checkbox
                style={{ marginBottom: "5vmin" }}
                label="Billing Address Same As Shipping Address"
                mt="sm"
                size="sm"
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
              />
              {!checked && (
                <AddressForm form={form} type="billing"></AddressForm>
              )}
            </div>
            {amounts.payable_amount != 0 && (
              <div className="wrapper">
                <h3 className="header">Credit/Debit Card</h3>
                <TextInput
                  label="Card Number"
                  size="sm"
                  inputMode="numeric"
                  withAsterisk
                  maxLength={19}
                  value={formatCardNumber(form.values.card_number)}
                  onChange={(e) =>
                    form.setFieldValue(
                      "card_number",
                      e.target.value.replace(/-/g, "")
                    )
                  }
                  rightSection={getCardTypeImage(form.values.card_number)}
                />
                <TextInput
                  label="Name on Card"
                  size="sm"
                  withAsterisk
                  {...form.getInputProps("card_holder_name")}
                />
                <Group justify="space-between" grow>
                  <NativeSelect
                    label="Month"
                    size="sm"
                    withAsterisk
                    data={[
                      "",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                    ]}
                    {...form.getInputProps("card_expire_month")}
                  />
                  <NativeSelect
                    label="Year"
                    size="sm"
                    withAsterisk
                    data={years}
                    {...form.getInputProps("card_expire_year")}
                  />
                  <TextInput
                    label="CVV"
                    inputMode="numeric"
                    size="sm"
                    width="100px"
                    withAsterisk
                    {...form.getInputProps("card_cvv")}
                  />
                </Group>
                <Checkbox
                  mt={20}
                  checked={autoRenew}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      autoRechargeModal.open();
                    }
                    setAutoRenew(false);
                  }}
                  label="Activate Auto Renewal"
                />
                <Text size="sm">
                  Would you like to streamline your experience and ensure
                  uninterrupted service? Activate auto-renewal for the next year
                  with just a simple click!
                </Text>
                <Text size="sm" fw={700}>
                  By setting up Auto Recharge, You agree to be bound by{" "}
                  <Link onClick={() => autoRechargeModal.open()}>
                    {" "}
                    the terms of this Agreement.
                  </Link>
                </Text>
              </div>
            )}
          </Grid.Col>
          <Grid.Col span={{ lg: 5 }}>
            <div className="wrapper">
              <h3 className="header">Order Summary</h3>
              <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Th>Package</Table.Th>
                    <Table.Td>
                      {context?.selectedPlan?.name}{" "}
                      {amounts?.coupon_base_price ? "(Special Price)" : ""}
                    </Table.Td>
                    <Table.Td>${amounts?.plan_price}</Table.Td>
                  </Table.Tr>

                  {context?.selectedPlan?.has_id_card === 1 ? (
                    <Table.Tr>
                      <Table.Th>+ ID Card</Table.Th>
                      <Table.Td>Yes</Table.Td>
                      <Table.Td>
                        ${context?.selectedPlan?.id_card_price}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    ""
                  )}

                  {context?.selectedPlan?.additional_pets_number > 0 ? (
                    <Table.Tr>
                      <Table.Th>+ Additional Pets</Table.Th>
                      <Table.Td>Yes</Table.Td>
                      <Table.Td>
                        $
                        {(
                          parseFloat(
                            context?.selectedPlan?.additional_pet_price
                          ) +
                          (context?.selectedPlan?.has_id_card
                            ? parseFloat(context?.selectedPlan?.id_card_price)
                            : 0)
                        ).toFixed(2)}{" "}
                        x {context?.selectedPlan?.additional_pets_number}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    ""
                  )}

                  {amounts?.coupon_is_valid ? (
                    <Table.Tr>
                      <Table.Th>Coupon</Table.Th>
                      <Table.Td>{amounts.coupon_title}</Table.Td>
                      <Table.Td>${amounts.coupon_discount.toFixed(2)}</Table.Td>
                    </Table.Tr>
                  ) : (
                    ""
                  )}

                  <Table.Tr style={{ fontWeight: "bold" }}>
                    <Table.Th>Total</Table.Th>
                    <Table.Td></Table.Td>
                    <Table.Td>${amounts?.payable_amount?.toFixed(2)}</Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </div>
            <div className="wrapper">
              <Accordion>
                <Accordion.Item value="coupon">
                  <Accordion.Control>Apply Coupon</Accordion.Control>
                  <Accordion.Panel>
                    <TextInput
                      placeholder="Enter Coupon"
                      disabled={context?.partner?.id}
                      value={couponCode}
                      className={amounts?.coupon_is_valid ? "applied" : ""}
                      size="sm"
                      onChange={(event) =>
                        setCouponCode(event.currentTarget.value)
                      }
                    />
                    {amounts?.coupon_is_valid ? (
                      <div style={{ color: "green" }}>
                        <FaCheckCircle></FaCheckCircle> {amounts?.coupon_title}{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    <Group justify="right">
                      <Button
                        disabled={context?.partner?.id}
                        style={{ backgroundColor: "#23A5A7" }}
                        onClick={handleCoupon}
                        loading={isLoading}
                      >
                        Apply Coupon
                      </Button>
                    </Group>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>
          </Grid.Col>
        </Grid>
        <Alert icon={<FaInfoCircle size="2rem" />} m={20}>
          <Text size="sm">
            * By click "Place Order" I authorize this payment and agree with the{" "}
            <a
              rel="noreferrer"
              href={process.env.REACT_APP_TERMS}
              target="_blank"
            >
              terms of service and payment and refund policies.
            </a>
            <br />* Please note this charge will show up as from "
            {process.env.REACT_APP_PAYMENT_NAME}" on your bank / card statement
          </Text>
        </Alert>
        <Group justify="center" mt={10}>
          <Button
            size="xl"
            color="gray"
            loading={isLoading}
            onClick={() => {
              localStorage.setItem("payment_form", JSON.stringify(form.values));
              navigate("/order/medical-info");
            }}
          >
            Back
          </Button>
          <Button
            size="xl"
            type="submit"
            style={{ backgroundColor: "#23A5A7" }}
            loading={isLoading}
          >
            Place Order
          </Button>
        </Group>
      </form>

      <Modal
        opened={openAutoRechargeModal}
        size="lg"
        onClose={autoRechargeModal.close}
        title="Agreement for Auto Recharge"
      >
        <Text>
          <p>
            By setting up Auto Recharge, You agree to be bound by the terms of
            this Agreement and authorize Us to charge the Auto Recharge Amount
            at the agreed renewal price of the respective state of your
            selection at Your Nominated Card on Your Credit Expiry Date. The
            Nominated Card details will also be stored on your account.
          </p>
          <p>
            Auto Recharge will be triggered on the Recommendation Expiry Date
            and your recommendation will be renewed and forwarded to you unless
            you choose to cancel it.
          </p>

          <p>
            You warrant that You have the capacity and authority to authorize Us
            to charge the Nominated Card.
          </p>

          <p>
            We are not liable for any loss arising from and any costs incurred
            in connection with:
          </p>
          <List m={20}>
            <List.Item>
              a. Us being unable to charge the Auto Recharge Amount to Your
              Nominated Card;
            </List.Item>
            <List.Item>
              b. any unsuccessful payment by You of the Auto Recharge Amount
              using Your Nominated Card (including a chargeback resulting from a
              disputed or fraudulent transaction or a failure by You to update
              Your details);
            </List.Item>
            <List.Item>
              c. Your credit expiring due to payment of the Auto Recharge Amount
              using Your Nominated Card being unsuccessful for any reason;
            </List.Item>
            <List.Item>
              d. any delay in applying the Auto Recharge; or
            </List.Item>
            <List.Item>
              e. Our variation or termination of this Agreement Changes by Us
            </List.Item>
          </List>
          <Group justify="center">
            <Button
              color="green"
              mr={10}
              onClick={() => {
                setAutoRenew(true);
                autoRechargeModal.close();
              }}
            >
              Accept
            </Button>
            <Button color="red" onClick={() => autoRechargeModal.close()}>
              Close
            </Button>
          </Group>
        </Text>
      </Modal>

      <Modal
        opened={openReviewModal}
        size="lg"
        onClose={close}
        title="Review Order"
      >
        <h5>Please review order details and make sure everything is right:</h5>
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          mb={20}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Name
              </Table.Td>
              <Table.Td>{context.user?.name}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Email
              </Table.Td>
              <Table.Td>{context.user?.email}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Mobile
              </Table.Td>
              <Table.Td>{context.user?.mobile}</Table.Td>
            </Table.Tr>
            {site_id != 2 && (
              <Table.Tr>
                <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                  Driver's License/State ID Number
                </Table.Td>
                <Table.Td>{context.user?.driver_license_number}</Table.Td>
              </Table.Tr>
            )}
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Date of Birth
              </Table.Td>
              <Table.Td>
                <Moment format="MM/DD/YYYY">{context.user.dob}</Moment>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Billing Address
              </Table.Td>
              <Table.Td>{tempData.billing_address}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Billing City
              </Table.Td>
              <Table.Td>{tempData.billing_city}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Billing State
              </Table.Td>
              <Table.Td>
                {
                  context?.states?.find(
                    (s) => s.id == tempData.billing_state_id
                  )?.name
                }
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                Billing Zipcode
              </Table.Td>
              <Table.Td>{tempData.billing_zipcode}</Table.Td>
            </Table.Tr>

            {context?.selectedPlan &&
              site_id != 2 &&
              context?.selectedPlan?.has_caregiver == 1 && (
                <>
                  <Table.Tr>
                    <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                      Caregiver Name
                    </Table.Td>
                    <Table.Td>{context.user.caregiver_name}</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td style={{ fontWeight: "bold", width: "200px" }}>
                      Caregiver Identification
                    </Table.Td>
                    <Table.Td>{context.user.caregiver_identification}</Table.Td>
                  </Table.Tr>
                </>
              )}
          </Table.Tbody>
        </Table>
        <Alert icon={<FaInfoCircle size="2rem" />} m={20}>
          <Text size="xs">
            * By click "Order Now" I authorize this payment and agree with the{" "}
            <a
              rel="noreferrer"
              href={process.env.REACT_APP_TERMS}
              target="_blank"
            >
              terms of service and payment and refund policies.
            </a>
            <br />* Please note this charge will show up as from "
            {process.env.REACT_APP_PAYMENT_NAME}" on your bank / card statement
          </Text>
        </Alert>
        <Group position="center">
          <Button color="gray" onClick={() => close()}>
            Back
          </Button>
          <Button color="green" onClick={() => submitForm(tempData)}>
            Order Now
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

export default Payment;

import {
    Button,
    PasswordInput,
    TextInput,
    Checkbox,
    LoadingOverlay,
    Modal,
    Group,
    PinInput,
    Loader,
    Box,
    Alert
} from "@mantine/core";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "@mantine/form";
import {Link, redirect, useNavigate, useSearchParams} from "react-router-dom";
import Parser from "html-react-parser";
import label from "../../config/DoctorLabel";
import Loginfooter from "../doctor/Loginfooter";
import {notifications} from '@mantine/notifications';
import axios from "../../axios2";
import AppContext from "../../AppContext";
import moment from "moment";

function SteadyCareVerify(props) {

    const [loading, setLoading] = useState(false);
    let [params] = useSearchParams();
    const navigate = useNavigate();
    const [done, setDone] = useState(false);
    
    
    const app_id = params.get("id");
    const token = params.get("token");

    const [appointment, setAppointment] = useState();
    const [message, setMessage] = useState();

    const submit = () => {

    }

    useEffect(() => {
        setLoading(true);
        axios.get('/steadycare-leads/appointments/' + app_id + '/' + token)
        .then((result) => {
            setAppointment(result.data.data);
        })
        .catch(() => {
            setMessage('This appointment already is reviewed.');
        })
        .finally(() => setLoading(false))
    }, []);

    const confirm = (action) => {
        setLoading(true);
        axios.post('/steadycare-leads/appointments/' + app_id + '/' + action + '/' + token)
        .then((resp) => {
            setMessage(resp.data.message);
            setDone(true);
        })
        .finally(() => setLoading(false))
    }

    return (<div
        className="page-container login d-lg-flex align-items-lg-center"
        style={{ height: "100vh" }}
      >
        <div
          className="login-content login-box"
          style={{ padding: "10px", paddingBottom: "1.0em", maxWidth: "300px" }}
        >
          {/* <div className="login-logo content" style={{ marginTop: "1.0em" }}>
            <img src={process.env.REACT_APP_SITE_LOGO1} alt="logo" />
          </div> */}
  
          <div style={{ textAlign: "center", marginTop: "2em" }}>
            <h3 style={{ fontWeight: "bold", marginBottom: "0.4em" }}>Steady Care Medical</h3>
            <p>Appointment</p>
          </div>
        <div style={{maxWidth: '600px', margin: '2em auto', textAlign: 'center'}}>
        {loading && <Loader color="blue" type="bars"></Loader>}
        <Box>{message && <Alert mb={20} color='green' variant="outline">{message}</Alert>}</Box>
        <br/>
        {appointment && <>
            <h4>Your Next Appointment</h4>
            <h3>{ moment(appointment?.due_date).format('MM/DD/YYYY HH:mm') }</h3>

            <h4>Do you confirm it?</h4>

            <Group justify="center" mt={10}>
                <Button variant="outline" onClick={() => confirm('verify')} disabled={done} loading={loading} color="green">Yes</Button> &nbsp;
                <Button variant="outline" onClick={() => confirm('reject')} disabled={done} loading={loading} color="red">No</Button>
            </Group>
        </>}
    </div>
    </div>
    </div>
    );
}

export default SteadyCareVerify;

import React, {useState, useEffect, useContext} from 'react';
import label from "../../config/PatientLabel";
import {Link, useNavigate} from "react-router-dom";
import Axios from "../../axios";
import AppContext from '../../AppContext';

function Sidebar() {
    const navigate = useNavigate();
    const [context] = useContext(AppContext);
    const [activeTabId, setActiveTabId] = useState(0);
    const [userInfo, setUserData] = useState([]);

    const [emailResponse, setemailResponse] = useState(0);
    const [emailResponseStatus, setemailResponseStatus] = useState(0);

    const [requestResponse, setRequestResponse] = useState(0);
    const [requestResponseStatus, setRequestResponseStatus] = useState(0);
    //const [isLoading, setIsLoading] = useState(true);
    const Logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
        console.log(localStorage.getItem('formData'))
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            let SessionToken = localStorage.getItem('access_token');
            if (SessionToken == null) {
                Logout();
            }
            const config = {
                headers: {Authorization: `Bearer ${SessionToken}`}
            };
            const bodyParameters = {};
            Axios.post(
                '/mPatientProfile',
                bodyParameters,
                config
            ).then(response => {
                //setIsLoading(false);
                //console.log(response.data.user);
                setUserData(response.data.user)
            }).catch(response => {
                //setemailResponse(response.data.message);
            });
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const redirecttoPlanPage = () => {
        let SessionToken = localStorage.getItem('access_token');
        const config = {
            headers: {Authorization: `Bearer ${SessionToken}`}
        };
        let stateID = context.user?.state;
        const bodyParameters = {
            state_id: stateID,
        };
        Axios.post(
            '/mgetUserPlan',
            bodyParameters,
            config
        ).then(res => {
            if (res.data.status == 'Failed') {
                let ApiResponseMessage = res.data.message;
                let ApiResponseStatus = res.data.status;
                setemailResponse(ApiResponseMessage);
                setemailResponseStatus(ApiResponseStatus);
                setTimeout(() => {
                    hideEmailverifyMessage();
                }, 5000);
            } else {
                navigate('/newplan');
            }
        }).catch(response => {
            //setemailResponse(response.data.message);
        });
    }

    const hideEmailverifyMessage = () => {
        setemailResponse('');
        setemailResponseStatus('null');
    }

    return (
        <>
            <div>
                {emailResponseStatus == 'Success' ?
                    <div className="alert success">
                        <span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
                        <i className="fa fa-check fa-1x"></i>
                        <b>{emailResponse}</b>
                    </div>
                    : emailResponseStatus == 'Failed' ?
                        <div className="alert danger pendingRecAlert">
                            <span className="closebtn danger" onClick={hideEmailverifyMessage}>×</span>
                            <i className="fa fa-check fa-1x"></i>
                            <b>{emailResponse}</b>
                        </div>
                        :
                        ''
                }
            </div>
            <div className="secondary-sidebar">

                <div id="sidebar-toggle-button-close"><i className="icon-close"></i></div>
                <div className="secondary-sidebar-bar">
                    <a href="#" className="logo-box"><img src="../../assets/dashboard/img/logo-2.png" alt=""/></a>
                </div>
                <div className="mobile-sidebar">
                    <a href="#" id="close-menu"><i className="fa fa-times"></i></a>
                    <div className="secondary-sidebar-menu menu-top scrollbar verticle-scroll">
                        <ul className="accordion-menu">
                            <li className={activeTabId == 1 ? 'active-page' : ''}>
                                <Link to="/dashboard" onClick={() => {
                                    setActiveTabId(1)
                                }}>
                                    <span><img src="../../assets/dashboard/img/Dashboard.png"
                                               alt=""/></span><label>{label.SidebarTitleDashboard}</label>
                                </Link>
                            </li>
                            {/* <li>
                        <a href="#">
                            <span><img src="../assets/dashboard/img/dashboard-icon.png" alt="" /></span> <label>Patients</label>
                                            <i className="accordion-icon"></i>
                        </a>
                        <ul className="sub-menu">
                            <li><a href="#"><span><img src="../assets/dashboard/img/profile-icon.png" alt="" /></span><label>Automatically Added Patients</label></a></li>
                            <li><a href="#"><span><img src="../assets/dashboard/img/profile-icon.png" alt="" /></span><label>Manually Added Patients</label></a></li>
                        </ul>
                    </li> */}
                            <li className={activeTabId == 2 ? 'active-page' : ''}><Link to="/viewProfile"
                                                                                        onClick={() => {
                                                                                            setActiveTabId(2)
                                                                                        }}><span><img
                                src="../../assets/dashboard/img/profile-icon.png" alt=""/></span>
                                <label>{label.SidebarTitleProfile}</label></Link></li>
                            <li className={activeTabId == 3 ? 'active-page' : ''}><Link to="/profileUpdate"
                                                                                        onClick={() => {
                                                                                            setActiveTabId(3)
                                                                                        }}><span><img
                                src="../../assets/dashboard/img/Edit-Profile.png" alt=""/></span>
                                <label>{label.SidebarTitleEditProfile}</label></Link></li>
                            <li className={activeTabId == 4 ? 'active-page' : ''}><Link to="/viewRecommendation"
                                                                                        onClick={() => {
                                                                                            setActiveTabId(4)
                                                                                        }}><span><img
                                src="../../assets/dashboard/img/Recommendation.png" alt=""/></span>
                                <label>{label.SidebarTitleOrders}</label></Link></li>
                            {/* <li><Link to="/coupon"><span><img src="../assets/dashboard/img/coupon-icon.png" alt="" /></span> <label>Coupons</label></Link></li> */}
                            <li className={activeTabId == 5 ? 'active-page' : ''}><Link to="/changePassword"
                                                                                        onClick={() => {
                                                                                            setActiveTabId(5)
                                                                                        }}><span><img
                                src="../../assets/dashboard/img/setting-icon.png" alt=""/></span>
                                <label>{label.SidebarTitleSetting}</label></Link></li>
                            <li className={activeTabId == 6 ? 'active-page' : ''}><Link to="#" onClick={() => {
                                setActiveTabId(6);
                                redirecttoPlanPage()
                            }}><span><img src="../../assets/dashboard/img/Make-Payment-2.png" alt=""/></span>
                                <label>Renew</label></Link></li>
                        </ul>
                    </div>
                    <div className="sidebar_bottom">
                        <div className="secondary-sidebar-profile">
                            <Link to="/viewProfile"><img
                                src={userInfo.profile_pic == undefined ? `${label.baseURLWeb}/assets/website/img/defaultImage.jpg` : userInfo.profile_pic}
                                id="left-profile-img" alt="viewProfile"/></Link>
                            <div id="left-profile-name">
                                <h6>{userInfo.fname == undefined ? '' : userInfo.fname + ' ' + (userInfo.mname == null ? '' : userInfo.mname == 'null' ? '' : userInfo.mname) + " " + userInfo.lname}</h6>
                                <Link className={activeTabId == 7 ? 'active-page' : ''} to="/contactUs" onClick={() => {
                                    setActiveTabId(7)
                                }}><img src="../../assets/dashboard/img/Contact-Us.png"
                                        alt=""/> {label.SidebarTitleContactus}</Link>
                                <Link to="#" onClick={Logout}><img src="../../assets/dashboard/img/Logout.png"
                                                                   alt=""/>{label.SidebarTitleLogout}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
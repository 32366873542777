import React from 'react';

import {  useNavigate  } from "react-router-dom";
function Loginfooter() {
  return (
    <>
        <div className="login-footer">
        <p>{ (new Date()).getFullYear() } © { process.env.REACT_APP_TITLE }</p>
        </div>
    </>
 );
}
export default Loginfooter;
import axios2 from "../../axios2";
import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Divider, LoadingOverlay, Stepper, Text} from "@mantine/core";
import AppContext from "../../AppContext";
import OrderSteps from "../../components/OrderSteps";
import Moment from "react-moment";
import OrderStatus from "../../components/OrderStatus";
import styles from "../../styles/OrderDetails.module.css"
import moment from "moment";
import OrderNow from "../../components/OrderNow";
import { useNavigate } from "react-router-dom";
import { FaVideo } from "react-icons/fa";

function Dashboard() {
    const userData = useContext(AppContext).context?.user;
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState();

    const navigate = useNavigate();

    const site_id = process.env.REACT_APP_SITE_ID;

    useEffect(() => {
        if (userData.role == 'ROLE_DOCTOR') {
            if(userData.sites.length > 0) {
                navigate('/orders/' + userData.sites[0]['name']);
            }else{
                navigate('/leads');
            }
        }
        axios2.get('/orders/dashboard')
            .then(resp => {
                setIsLoading(false);
                if (resp.data.data.length > 0) {
                    setOrder(resp.data.data[0])
                }
            })
    }, [])

    const download = (recom) => {
        window.open(recom.file + '?download=1');
    }

    return isLoading ? <LoadingOverlay visible={isLoading}overlayProps={{blur:2}} /> : <div style={{ textAlign: 'center', maxWidth: '600px', margin: 'auto' }}>
        {order && <Card shadow="sm" p={30} radius="md" withBorder>
            <Card.Section p={10}>
                <h3>Your Order</h3>
                <div className={styles.infoWrapper}>
                    <div className={styles.infoRow}>
                        <strong>Date: </strong>
                        <Moment format="MM/DD/YYYY">{order.created_at}</Moment>
                    </div>
                    <div className={styles.infoRow}>
                        <strong>Plan name: </strong>
                        {order.plan_name}
                    </div>
                    {!!order.plan.has_id_card && <div className={styles.infoRow}>
                        <strong>ID CARD: </strong>
                        {order.has_id_card ? 'Yes' : 'No'}
                    </div>}
                    <div className={styles.infoRow}>
                        <strong>State Applied For: </strong>
                        {order.state_name}
                    </div>
                    <div className={styles.infoRow}>
                        <strong>Status: </strong>
                        <OrderStatus status={order.status}></OrderStatus>
                    </div>
                </div>
                <OrderSteps updateOrder={setOrder} order={order}></OrderSteps>
                {order.status == 'done' && order.main_recommendation && <div>
                    <h5>Your { site_id == 2 ? 'ESA Letter' : 'recommendation' } is ready!</h5>
                    {order.main_recommendation.file && <Button variant="light" color="grape" size="xs" onClick={() => download(order.main_recommendation)}>Download</Button>}
                </div>}
        </Card.Section>
        </Card>}
        {!order && <OrderNow></OrderNow>}
                </div>
}

export default Dashboard;
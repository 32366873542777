import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Label from "../../config/DoctorLabel";
import Axios from "../../axios2";
import Moment from "react-moment";
import "moment-timezone";
import Helpers from "../../components/doctor/Helper";
import Filter from "./UsersFilter";
import {
  Table,
  Pagination,
  Badge,
  Button,
  Tabs,
  ScrollArea,
} from "@mantine/core";
import AppContext from "../../AppContext";
import axios2 from "../../axios2";
import OrderStatus from "../../components/OrderStatus";
import { TiThSmall } from "react-icons/ti";
import { FaCheckDouble } from "react-icons/fa";
import { TbProgress } from "react-icons/tb";

const OrderList = ({ site, state }) => {

  const context = useContext(AppContext).context;

  const [filters, setFilters] = useState({});
  const [tab, setTab] = useState("all");
  const [useFilter, setUseFilter] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    try {

      document.querySelector("#breadcrumb").innerHTML =
        Label.MANUAL_REGISTER_PATIENT_LIST;
    } catch (e) {}
  }, []);

  useEffect(() => {
    fetchData();
  }, [context, site, filters]);

  useEffect(() => {
    if(tab == 'renew'){
      setFilters({...filters, old_app: 1});
      return;
    }
    setFilters({ ...filters, status: tab == "all" ? "" : tab });
  }, [tab]);

  const fetchData = (page, _filters) => {
    if (!context.user || !context.user.sites || !site) return;
    page = page ? page : 1;
    setData([]);
    if (_filters) setFilters(_filters);
    const site_id = context.user.sites.find((s) => s.name == site)?.id;
    if (!site_id) return;
    const filters_query = new URLSearchParams(
      _filters ? _filters : filters
    ).toString();
    axios2
      .get(
        "orders?page=" +
          page +
          "&" +
          filters_query +
          "&site_id=" +
          (site ? site_id : null) +
          (context?.doctor_id ? "&doctor_id=" + context?.doctor_id : "")
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((response) => {
        Helpers.errorAlert(response.data.message, "error");
      });
  };

  const onClickFilterCallback = (filters) => {
    setUseFilter(true);
    fetchData(1, filters);
  };

  return (
    <>
      <div className="content-header">
        <div class="patient-title">
          <h1 className="page-title">All Orders </h1>
        </div>
      </div>

      <div className="patient_table">
        <div className="row filter_block">
          <div className="col-12">
            <div className="search_box">
              <img
                src="../../assets/dashboard/img/search-icon.png"
                alt="Search Patient"
              />
              <Filter onClickFilterCallback={onClickFilterCallback} state={state}></Filter>
            </div>
          </div>
        </div>
        <div>
          <Tabs defaultValue="all" value={tab} onChange={(tab) => setTab(tab)}>
            <Tabs.List>
              <Tabs.Tab value="all" leftSection={<TiThSmall size="0.8rem" />}>
                All
              </Tabs.Tab>
              <Tabs.Tab
                value="processing"
                leftSection={<TbProgress size="0.8rem" />}
              >
                Processing
              </Tabs.Tab>
              <Tabs.Tab
                value="done"
                leftSection={<FaCheckDouble size="0.8rem" />}
              >
                Done
              </Tabs.Tab>
              {context?.user?.change_doctor == 1 && <Tabs.Tab
                value="renew"
                leftSection={<FaCheckDouble size="0.8rem" />}
              >
                Old App
              </Tabs.Tab>}
            </Tabs.List>
          </Tabs>
        </div>
        <div className="table_wrap" style={{ marginTop: "1em" }}>
          {data.data ? (
            <Table striped withTableBorder withColumnBorders>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>No.</Table.Th>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>Email</Table.Th>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>State</Table.Th>
                  <Table.Th>Status</Table.Th>
                 {context?.user?.change_doctor == 1 && <Table.Th>OLD APP?</Table.Th>}
                  <Table.Th>Comment</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data.data.length > 0 ? (
                  data.data.map((order) => (
                    <Table.Tr key={order.id}>
                      <Table.Td>{order.id}</Table.Td>
                      <Table.Td>{order.user.name}</Table.Td>
                      <Table.Td>{order.user.email}</Table.Td>
                      <Table.Td>
                        <Moment format="MM/DD/YYYY">{order.created_at}</Moment>
                      </Table.Td>
                      <Table.Td>{order.state_name}</Table.Td>
                      <Table.Td style={{ textAlign: "center" }}>
                        <OrderStatus status={order.status}></OrderStatus>
                      </Table.Td>
                      {context?.user?.change_doctor == 1 && <Table.Td>{order.old_app_id ? 'Yes' : 'No'}</Table.Td>}
                      <Table.Td>{order.recommendation?.comment}</Table.Td>
                      <Table.Td>
                        <Link to={"/orders/" + order.id} target="_blank">
                          <Button color="teal">View</Button>
                        </Link>
                      </Table.Td>
                    </Table.Tr>
                  ))
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan="12">
                      <center>No Data found</center>
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          ) : (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
        <br />
        {data.meta && (
          <Pagination
            defaultValue={data.meta.current_page}
            onChange={fetchData}
            total={data.meta.last_page}
          />
        )}
      </div>
    </>
  );
};
export default OrderList;

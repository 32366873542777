import React,{useState,useEffect} from 'react';
function Footer() {
  return (
    <>
        <div className="page-footer">
			<p>2022 © onlinemedicalcard</p>
		</div>
    </>
 );
}
export default Footer;
import ProfileForm from "./ProfileForm";

function EditProfile() {

    return (
        <>
            <div className="content-header" style={{marginTop: '2rem'}}>
                <h1 className="page-title">Edit Profile</h1>
            </div>

            <div id='profile' className="view-profile update-profile" style={{paddingInline: '1rem'}}>
                <ProfileForm isEditable={true}></ProfileForm>
            </div>
        </>
    );
}

export default EditProfile;
import { Grid, Loader, Paper, ScrollArea, Stack } from "@mantine/core";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { memo, useEffect, useState } from "react";
import "../../App.css";
import axios from "../../axios2";
import BigCalendarHeader from "./BIgCalendarHeader";
import BigCalendarSlots from "./BigCalendarSlots";
import { getWeekDays } from "./utils";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const fetchAppointments = async (start, end) => {
  const res = await axios.get(
    `/steadycare-leads/appointments?start=${start}&end=${end}&timezone=${process.env.REACT_APP_TIMEZONE}`
  );
  const transformedData = Object.values(res.data).map((value) => ({
    ...value,
  }));
  const schedules = transformedData?.flatMap((item) =>
    item.appointments?.map((person, index) => ({
      ...person,
      index
    }))
  );
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(schedules);
    }, 1000);
  });
};

const BigCalendar = memo(function BigCalendar({ selectedDate, onOpenDrawer, onOpenLeadForm, onOpenDetailModal }) {

  useEffect(() => {
    console.log('MyComponent re-rendered', selectedDate.format('YYYY-MM-DD z'));
  });

  const [patientSchedule, setPatientSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { startFormatted, endFormatted, days } = getWeekDays(selectedDate);

  useEffect(() => {
    if(!selectedDate) return;
    const loadAppointments = async () => {
      setIsLoading(true);
      try {
        const appointments = await fetchAppointments(
          startFormatted,
          endFormatted
        );
        setPatientSchedule(appointments);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAppointments();
  }, [selectedDate]);

  return (
    <Stack spacing="md">
      <Grid>
        <Grid.Col span={12} style={{ position: "relative" }}>
          <Paper shadow="xs" p="md">
            <ScrollArea>
                <Grid gutter={0} miw={800} style={{ position: "relative" }}>
                  <Grid.Col span={1}></Grid.Col>
                  <BigCalendarHeader days={days} />
                   <BigCalendarSlots
                    openAddLead={ onOpenLeadForm }
                    days={days}
                    openDrawer = { onOpenDrawer }
                    openDetailModal = { onOpenDetailModal }
                    patientSchedule={patientSchedule}
                  /> 
                </Grid>
            </ScrollArea>
          </Paper>
        </Grid.Col>
      </Grid>
    </Stack>
  );
});

export default BigCalendar;

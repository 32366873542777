import { Button, Paper } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import { getWeekDays } from "./utils";
import moment from "moment";

function CalendarDatepicker({
  selectedDate,
  setSelectedDate,
}) {

  const [startFormatted, setStartFormatted] = useState();
  const [endFormatted, setEndFormatted] = useState();
  const [days, setDays] = useState();

  const [calendarOpen, setCalendarOpen] = useState(false);

  const [hovered, setHovered] = useState();
  const [value, setValue] = useState(selectedDate?.toDate());

  useEffect(()=>{
    const week = getWeekDays(selectedDate);
    setStartFormatted(week.startFormatted);
    setEndFormatted(week.endFormatted);
    setDays(week.days);
  }, [selectedDate]);

  function getDay(date) {
    const day = date.getDay();
    return day === 0 ? 6 : day - 1;
  }
  
  function startOfWeek(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - getDay(date) - 1);
  }
  
  function endOfWeek(date) {
    return dayjs(new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - getDay(date))))
      .endOf('date')
      .toDate();
  }
  
  function isInWeekRange(date, value) {
    return value
      ? dayjs(date).isBefore(endOfWeek(value)) && dayjs(date).isAfter(startOfWeek(value))
      : false;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <p>
          {dayjs(startFormatted).format("MMM D")} to{" "}
          {dayjs(endFormatted).format("MMM D, YYYY")}
        </p>
        <Button
          onClick={() => setCalendarOpen(true)}
          variant="light"
          rightSection={<FaChevronDown />}
        >
          change
        </Button>
      </div>
      {calendarOpen && (
        <Paper
          shadow="md"
          p="md"
          style={{
            position: "absolute",
            top: "0",
            left: 0,
            zIndex: 1000,
            backgroundColor: "white",
          }}
        >
          <FaTimes
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => setCalendarOpen(false)}
          />
          <Calendar
            withCellSpacing={false}
            getDayProps={(date) => {
              const isHovered = isInWeekRange(date, hovered);
              const isSelected = isInWeekRange(date, value);
              const isInRange = isHovered || isSelected;
              return {
                onMouseEnter: () => setHovered(date),
                onMouseLeave: () => setHovered(null),
                inRange: isInRange,
                firstInRange: isInRange && date.getDay() === 1,
                lastInRange: isInRange && date.getDay() === 0,
                selected: isSelected,
                onClick: () => {
                  setValue(date);
                  setSelectedDate(moment(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()));
                  setCalendarOpen(false);
                },
              };
            }}
          />
        </Paper>
      )}
    </>
  );
}

export default CalendarDatepicker;

import $ from "jquery";
import React,{useState,useEffect} from "react";
import {  useNavigate ,useLocation } from "react-router-dom";
import Alert from 'react-popup-alert'
import Label from '../../config/DoctorLabel';
import Moment from 'react-moment';
import Axios from '../../axios';
import Helpers from "../../components/doctor/Helper";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'moment-timezone';
import CancelCallimg from '../../assets/website/img/phone.png';
import Callimg from '../../assets/website/img/phone-2.png';

function Footer() {
  
  const navigate = useNavigate();
  const location = useLocation();

  const [callUrl, setCallUrl] = useState([]);
  const [roomID, setroomID] = useState(0);
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [profileimg, setProfileimg] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [url, setUrl] = useState('');
  const [callId, setCallId] = useState('');

  
  const SessionToken=window.sessionStorage.getItem('token');
  const config = {
    headers: { Authorization: `bearer ${SessionToken}` }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      //window.$('#exampleModalCenter').modal('show');
      Axios.post('mcallCurrentPatient','',config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
            if(res.data.customerInfo.roomId!==''){
              setroomID(res.data.customerInfo.roomId);
              setName(res.data.customerInfo.name);
              setGender(res.data.customerInfo.gender);
              setCustomerId(res.data.customerInfo.customer_id);
              setProfileimg(res.data.customerInfo.profile_pic);
              setUrl(res.data.customerInfo.roomurl);
              setCallId(res.data.customerInfo.callId);
              window.$('#exampleModalCenter').modal('show');
          
            }else{
           
              setroomID('');
              setName('');
              setGender('');
              setCustomerId('');
              setProfileimg('');
              setUrl('');
              setCallId('');
              window.$('#exampleModalCenter').modal('hide');
              window.$('.modal-backdrop').remove();
          
            }
           
          
          
          }
      }).catch(response =>{
        if(response.data){
          Helpers.errorAlert(response.data.error,'error');
        }
        
      });

      

      
    },50000)
 
    return () => {
        clearInterval(intervalId); //This is important
      
    }
   
 }, [callUrl])

 const disConnectCall=()=>{
  let path =location.pathname.replace('/','');
  
  window.$('#exampleModalCenter').modal('hide');
  window.$('.modal-backdrop').remove();
  setroomID('');
  setName('');
  setGender('');
  setCustomerId('');
  setProfileimg('');
  setUrl('');

   if(roomID){
     let data={
       roomID:roomID,
       callId:callId
     }
    Axios.post('mcancelIncommingcall',data,config).then(res =>{
      if(res.data.status=='unauthorised'){
        Helpers.Logout();
      }else if(res.data.status=='Failed'){
        Helpers.errorAlert(res.data.message,'error');
      }else{
        Helpers.errorAlert(res.data.message,'success');
        if(path=='video-call'){
          navigate('/dashboard',{});
        }
       
      }
  }).catch(response =>{
    if(response.data){
      Helpers.errorAlert(response.data.error,'error');
    }
    
  });


   }

 }
 const CancelCall=(e)=>{
    e.preventDefault();
 
    confirmAlert({
      title: Label.DISCONNECT_CALL,
      message:Label.DISCONNECT_CALL_MSG,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>disConnectCall()
        },
        {
          label: 'No',
          
        }
      ]
    });
  

 }
 const connectCall=()=>{
  if(url){
    window.$('#exampleModalCenter').modal('hide');
    window.$('.modal-backdrop').remove();
    navigate('/video-call/'+customerId, {'state':{'url':url,callId:callId,'customerId':customerId}});
  }
  
 }
  return (
    <>
    <div className="modal fade" id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
           <div className="modal-body callscreen">

            <div className="media">

              {setProfileimg!=''? <img   className="align-self-start mr-3 profile-img" src={profileimg} />: <img  className="align-self-start mr-3 profile-img" src="../../assets/website/img/user.jpg" /> }
              <div className="media-body">
                <h5 className="mt-0">{name}</h5>
               
                <p>Incomming call <span className="hellip">&hellip;</span></p>
              </div>
              <div className="media-body">
                  <div className="callout" onClick={CancelCall}>
                    <a><img src={Callimg}/></a>
                  </div>
                  <div className="callup" onClick={connectCall}>
                    <a><img src={CancelCallimg}/></a>
                  </div>
              </div>
            </div>


           {/* <div className="card">
            <div className="card-item card-item-1">
                <h5 className="title">{roomID.name}</h5>
                <h6 className="subtitle">incomming call <span className="hellip">&hellip;</span></h6>
            </div>
            <div className="card-item card-item-2">
                <img className="img" src="https://placekitten.com/640/360" alt=""/>
            </div>
            <div className="card-item card-item-3">
                <div className="call-btn">
                <div className="callout">
                    <img src="https://img.icons8.com/ios-filled/50/ffffff/phone.png"/>
                </div>
                <span className="">Decline</span>
                </div>
                <div className="call-btn">
                <div className="callup">
                   <img src="https://img.icons8.com/ios-filled/50/ffffff/phone1.png"/>
                </div>
                <span>Accept</span>
                </div>
             </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>

     <div className="page-footer">
						<p><Moment format="YYYY"></Moment> © {Label.WEBSITE}</p>
      </div>

    </>
 );
}
export default Footer;
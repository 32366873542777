// import Header from './Topbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Topbar  from './Topbar';
const Layouts = ({ children }) => {
    
    return (
        <main id="dashboard">
            <div className="page-container">
                <div className="settings-overlay"></div>
                <div className="page-content">
                    <Sidebar/>
                    <div className="page-inner no-page-title">
                        <Topbar/>
                        <div id="main-wrapper">
                            {children}
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>
        </main>
        
        );
};
export default Layouts;
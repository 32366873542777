import { TextInput, Button, Grid, LoadingOverlay } from "@mantine/core";
import React, { useContext, useState } from "react";
import Parser from "html-react-parser";
import label from "../../config/PatientLabel";
import { useForm } from "@mantine/form";
import axios2 from "../../axios2";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";

function ChangePassword() {
  const userData = useContext(AppContext).context?.user;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    validate: {
      current_password: (value) =>
        !value ? "Password could not be empty" : null,
      new_password: (value) =>
        verifyPassword(value)
          ? "Password must be at least 6 characters long"
          : null,
      confirmation_password: (value, values) =>
        value !== values.new_password
          ? "Password does not match confirmation"
          : null,
    },

    validateInputOnChange: true,
  });

  function verifyPassword(value) {
    if (!value) return "Password must not be empty";
    else if (value.length < 6)
      return "Password must be at at least 6 characters long";
  }

  function handleSubmit() {
    delete form.values["confirmation_password"];

    setIsLoading(true);
    axios2.post("/user/changepassword", form.values).then((res) => {
      console.log(res);
      notifications.show({
        title: "Success",
        message: "Password successfully changed",
        color: "green",
      });

      setIsLoading(false);
      navigate(userData?.role === "ROLE_DOCTOR" ? "/orders" : "/dashboard");
      window.scrollTo(0, 0);
    });
  }

  return (
    <>
      <div className="content-header">
        <h1 className="page-title" style={{ marginTop: "1em" }}>
          {Parser(label.changePasswordTitle)}
        </h1>
      </div>

      <div className="content-body" style={{ padding: "2rem" }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col span={{ md: 6, lg: 6 }}>
              <TextInput
                label="Old Password"
                size="lg"
                mt="xl"
                radius="1rem"
                leftSection={
                  <img src="/assets/website/img/password.png" alt="" />
                }
                {...form.getInputProps("current_password")}
              />

              <TextInput
                label="New Password"
                size="lg"
                mt="xl"
                radius="1rem"
                leftSection={
                  <img src="/assets/website/img/password.png" alt="" />
                }
                {...form.getInputProps("new_password")}
              />

              <TextInput
                label="Confirm New Password"
                size="lg"
                mt="xl"
                radius="1rem"
                leftSection={
                  <img src="/assets/website/img/password.png" alt="" />
                }
                {...form.getInputProps("confirmation_password")}
              />

              <div className="form-group button-group d-flex">
                <Button type="submit" className="btn submit-btn">
                  Submit
                  <LoadingOverlay visible={isLoading} overlayProps={{blur:2}} />
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </form>
      </div>
    </>
  );
}

export default ChangePassword;

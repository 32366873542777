import React, { useContext, useEffect, useState } from "react";
import axios from "../../axios2";
import { Link, useSearchParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Group,
  Image,
  Modal,
  PasswordInput,
  PinInput,
  Select,
  Stepper,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import RegisterLayout from "./RegisterLayout";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AppContext from "../../AppContext";
import { notifications } from "@mantine/notifications";
import { AiOutlineMail } from "react-icons/ai";
import { MdMobileFriendly } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import { useDisclosure } from "@mantine/hooks";
import { load } from "@progress/kendo-react-intl";

function Register(props) {

  const { context, refreshContext } = useContext(AppContext);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [states, setStates] = useState([]);

  const site_id = process.env.REACT_APP_SITE_ID;

  const [oldPatientName, setOldPatientName] = useState(false);
  const [confirmModalOpenned, { open, close }] = useDisclosure();

  const [verifyStep, setVerifyStep] = useState("email");
  const [mobileIsVerified, setMobileIsVerified] = useState(false);

  const mobile = localStorage.getItem("otp_mobile");
  const email = localStorage.getItem("otp_email");

  const [sendAgainButton, setSendAgainButton] = useState(false);
  const [resetTimer, setResetTimer] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const isTesting = localStorage.getItem("is_testing");
  const formattedState = states.map((state) => ({
    label: state.label,
    value: state.value.toString(),
  }));
  useEffect(() => {
    if (!context?.active_states) return;
    const _states = context?.active_states.map((s) => ({
      label: s.name,
      value: s.id,
    }));
    setStates(_states);
  }, [context]);
  useEffect(() => {
    const name = searchParams.get("name") ?? "";
    const middle_name = searchParams.get("middle_name") ?? "";
    const [first_name, last_name] = name.split(" ");
    const state = context?.active_states?.find(
      (s) => s.name == searchParams.get("state")
    );
    form.setValues({
      first_name: searchParams.get("first_name") ?? first_name,
      last_name: searchParams.get("last_name") ?? last_name,
      middle_name: middle_name,
      email: searchParams.get("email"),
      mobile: searchParams.get("mobile"),
      state:state?.id.toString(),
    });
    if (state && state.redirect_to_old_app) {
      window.location = state.redirect_url
        ? state.redirect_url
        : "https://app.onlinemedicalcard.com/register?token=PNnFNUeblRrDeJYnXKjvizeuhjte1Bnei53&state=" +
          state.name;
    }
  }, []);

  const sendAgain = (type, _email, _mobile) => {
    _email = _email ?? email;
    _mobile = _mobile ?? mobile;
    setOtp("");
    setResetTimer(resetTimer + 1);
    setSendAgainButton(false);
    setLoading(true);
    const request_id = localStorage.getItem("otp_request_id");
    axios
      .post("/user/sendotp", {
        request_id,
        type: type ?? verifyStep,
        mobile: _mobile,
        email: _email,
      })
      .then((res) => {
        localStorage.setItem("otp_request_id", res.data.data.request_id);
        localStorage.setItem("otp_mobile", res.data.data.mobile);
        localStorage.setItem("otp_email", res.data.data.email);
        if (res.data.data.email_is_verified) {
          setVerifyStep("mobile");
        }
        setStep(2);
      })
      .finally(() => setLoading(false));
  };

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      mobile: "",
      password: "",
      auto_pass: false,
    },
    validate: {
      first_name: (value) =>
        value.length < 2 ? "First Name must have at least 2 letters" : null,
      last_name: (value) =>
        value && value.length < 2
          ? "Last Name must have at least 2 letters"
          : null,
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email address",
      mobile: (value) =>
        /^[0-9]{10,20}$/.test(value) ? null : "Mobile must have 10 digits",
      //password: (value) => form.values.auto_pass ? null : (value.length < 6 ? 'Password must have at least 6 letters/digits' : null),
      // age: false,
      state: (value) => (value > 0 ? null : "Please select your state."),
      terms: false,
    },
    validateInputOnChange: true,
  });

  const submit = (values) => {
    const selectedState = states.find((s) => s.value == values.state);
    const state = context.active_states.find((s) => s.id == values.state);
    if (isTesting || !state.redirect_to_old_app || site_id != 1) {
      open();
    } else {
      notifications.show({
        title: "Redirecting...",
        message:
          "We are redirecting you to our website to place an order for " +
          selectedState.label,
        color: "red",
      });
      setTimeout(() => {
        window.location = state.redirect_url
          ? state.redirect_url
          : "https://app.onlinemedicalcard.com/register?token=PNnFNUeblRrDeJYnXKjvizeuhjte1Bnei53&state=" +
            selectedState.label;
      }, 3000);
    }
  };

  const confirm = () => {
    const values = form.values;
    setLoading(true);
    axios
      .post("/user/preregister", values)
      .then((res) => {
        close();
        const token = res.data.data.access_token;
        if (token) {
          axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
          localStorage.setItem("access_token", token);
          refreshContext("dashboard");

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({event: 'sign_up', site_id: site_id, email: res.data.data.email});

        } else {
          setOtp("");
          setVerifyStep("email");
          setOldPatientName(res.data.data.old_user);
          localStorage.setItem("otp_request_id", res.data.data.request_id);
          localStorage.setItem("otp_mobile", res.data.data.mobile);
          localStorage.setItem("otp_email", res.data.data.email);
          setStep(2);
        }
      })
      .finally(() => setLoading(false));
  };

  const verify = (otp) => {
    if (otp.length < 6) {
      notifications.show({
        title: "Error",
        message: "Verification code is wrong",
        color: "red",
      });
      return;
    }

    setLoading(true);
    const request_id = localStorage.getItem("otp_request_id");
    const mobile = localStorage.getItem("otp_mobile");
    const email = localStorage.getItem("otp_email");
    const data = {
      mobile_secret: otp,
      email_secret: otp,
      request_id: request_id,
      mobile: mobile,
      email: email,
      type: verifyStep,
    };

    axios
      .post("/user/verifyotp", data)
      .then((res) => {
        setMobileIsVerified(res.data.data.mobile_is_verified);
        setOtp("");
        setResetTimer(resetTimer + 1);
        setSendAgainButton(false);
        if (verifyStep == "mobile" && res.data.data.email_is_verified) {
          register();
        }
        setVerifyStep(res.data.data.email_is_verified ? "mobile" : "email");
        if (res.data.data.email_is_verified) {
          sendAgain("mobile");
        }
      })
      .finally(() => setLoading(false));
  };

  const register = () => {
    setLoading(true);
    const request_id = localStorage.getItem("otp_request_id");
    axios
      .post("/user/register", { request_id, email, mobile })
      .then((res) => {
        localStorage.removeItem("otp_request_id");
        localStorage.removeItem("otp_mobile");
        localStorage.removeItem("otp_email");

        const token = res.data.data.access_token;
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
        localStorage.setItem("access_token", token);
        refreshContext("dashboard");
      })
      .finally(() => setLoading(false));
  };

  return (
    <RegisterLayout>
      {step == 1 && (
        <div>
          <div className="top_sec">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: process.env.REACT_APP_SIGNUP_TITLE,
              }}
            ></div>
            <div className="register_img">
              <Image
                src={process.env.REACT_APP_SIGNUP_IMAGE}
                alt="register-image"
              />
            </div>
          </div>
          <div className="form_box mt-3 mt-md-5">
            <div className="content">
              <h2 class="form-title">
                Register Now
              </h2>
            </div>
            <Box maw={400} m="auto">
              <form onSubmit={form.onSubmit(submit)}>
                <TextInput
                  withAsterisk
                  label="First Name"
                  placeholder=""
                  {...form.getInputProps("first_name")}
                />

                <TextInput
                  label="Middle Name"
                  placeholder=""
                  {...form.getInputProps("middle_name")}
                />

                <TextInput
                  withAsterisk
                  label="Last Name"
                  placeholder=""
                  {...form.getInputProps("last_name")}
                />

                <TextInput
                  withAsterisk
                  label="Email"
                  type="email"
                  inputMode="email"
                  placeholder=""
                  {...form.getInputProps("email")}
                />

                <span
                  style={{
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "1em",
                  }}
                >
                  The primary form of communication will be email. Please make
                  sure your email address is correct.
                </span>

                <TextInput
                  withAsterisk
                  label="Mobile"
                  inputMode="numeric"
                  maxLength={20}
                  placeholder=""
                  {...form.getInputProps("mobile")}
                />

                <Select
                  withAsterisk
                  label="State you are applying For"
                  placeholder="Choose the state"
                  {...form.getInputProps("state", { type: "select" })}
                  data={formattedState}
                />

                {/* <PasswordInput
                                withAsterisk
                                label="Password"
                                placeholder=""
                                disabled={form.values.auto_pass}
                                {...form.getInputProps('password')}
                            />

                            <Checkbox
                                label="Generate password and email me"
                                size='xs'
                                {...form.getInputProps('auto_pass', {type: 'checkbox'})}
                                onChange={(v) => {
                                    form.setFieldValue('auto_pass', !form.values.auto_pass);
                                }}
                            /> */}

                <Divider my="sm" variant="dotted" />

                {/* <Checkbox
                  mt="lg"
                  label="I'm over 18 years old"
                  {...form.getInputProps("age", { type: "checkbox" })}
                /> */}

                <Checkbox
                  mt="md"
                  label={
                    <span>
                      I accept{" "}
                      <a href={process.env.REACT_APP_TERMS} target="_blank">
                        terms and conditions
                      </a>
                    </span>
                  }
                  {...form.getInputProps("terms", { type: "checkbox" })}
                />

                <Group justify="right" mt="md">
                  <Button
                    type="submit"
                    loading={loading}
                    disabled={
                     !form.values.terms || !form.isValid()
                    }
                  >
                    Submit
                  </Button>
                </Group>
              </form>
            </Box>
          </div>
        </div>
      )}

      {step == 2 && (
        <div>
          <div
            style={{ textAlign: "center", maxWidth: "500px", margin: "auto" }}
          >
            <Stepper
              active={verifyStep == "email" ? 0 : 1}
              style={{ marginBottom: "1em" }}
            >
              <Stepper.Step
                icon={<AiOutlineMail />}
                label="Email Verification"
              />
              <Stepper.Step
                icon={<MdMobileFriendly />}
                label="Mobile verification"
              />
            </Stepper>
            {verifyStep == "mobile" && (
              <div>
                <MdMobileFriendly
                  style={{ fontSize: "3em", color: "green", margin: "5px" }}
                />
                <p style={{ marginTop: "1em" }}>
                  We have sent a code to <strong>{mobile}</strong>. Please check
                  6-digit code and enter it below:
                </p>
              </div>
            )}
            {verifyStep == "email" && (
              <div>
                <AiOutlineMail
                  style={{ fontSize: "3em", color: "green", margin: "5px" }}
                />
                {oldPatientName && <h4>Welcome Back {oldPatientName}</h4>}
                <p>
                  You need to verify your email address. We have sent a code to{" "}
                  <h5>{email}</h5> Please check 6-digit code and enter it below:
                </p>
                <Alert mb={20} color="red">
                  If you do not see the email in a few minutes, check your “junk
                  mail” folder or “spam” folder. Please also check your email
                  address is correct.{" "}
                </Alert>
              </div>
            )}
            <Divider></Divider>
            <PinInput
              length={6}
              type="number"
              oneTimeCode
              value={otp}
              onChange={setOtp}
              placeholder="-"
              spacing={5}
              size="xl"
              style={{
                display: "inline-flex",
                margin: "1em 0",
                maxWidth: "100%",
              }}
            />
            <div style={{ display: "inline-block" }}>
              <Button
                onClick={() => verify(otp)}
                loading={loading}
                color="indigo"
                style={{ width: "200px", margin: "auto", display: "block" }}
                className="mt-3"
              >
                Verify
              </Button>
              {verifyStep == "mobile" && (
                <a
                  href="#"
                  style={{
                    textAlign: "center",
                    display: "block",
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                  onClick={() => register()}
                >
                  If you don't have a mobile number, you can click here and skip
                  this step.
                </a>
              )}
              <Link
                style={{ display: "block", marginTop: "1em" }}
                onClick={() => setStep(1)}
              >
                Change your {verifyStep}?
              </Link>
            </div>

            <div className="form_box mt-3 mt-md-2">
              {!sendAgainButton && (
                <div>
                  <p style={{ marginTop: "3em" }}>
                    Please wait 60 seconds to send again
                  </p>
                  <div className="timer-wrapper">
                    <CountdownCircleTimer
                      key={resetTimer}
                      isPlaying
                      onComplete={() => setSendAgainButton(true)}
                      duration="60"
                      colors={["#23A5A7", "#fbbc05", "#e29138", "#A30000"]}
                    >
                      {({ remainingTime }) => (
                        <span style={{ fontSize: "40px" }}>
                          {remainingTime}
                        </span>
                      )}
                    </CountdownCircleTimer>
                  </div>
                </div>
              )}
              {sendAgainButton && (
                <Button
                  variant={"light"}
                  style={{ display: "block", margin: "1em auto" }}
                  onClick={() => sendAgain()}
                >
                  Send Again
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      <Modal opened={confirmModalOpenned} size="sm" onClose={close} title="">
        <div style={{ textAlign: "center" }}>
          <p>
            The primary form of communication will be email. Please make sure{" "}
            <h3>{form.values.email}</h3> is your correct email address.
          </p>
          <p style={{ margin: "1em 0em 1em 0em" }}>
            Also, please make sure <h3>{form.values.mobile}</h3> is a valid
            mobile number.
          </p>
          <h5>Do you confirm them?</h5>
          <Group justify="center" mt="1rem">
            <Button color="green" loading={loading} onClick={() => confirm()}>
              Yes
            </Button>
            <Button color="gray" onClick={() => close()}>
              No
            </Button>
          </Group>
        </div>
      </Modal>
    </RegisterLayout>
  );
}

export default Register;

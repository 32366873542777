import React from "react";
import Parser from 'html-react-parser';
import label from "../../config/PatientLabel";
import { Link } from 'react-router-dom';
function Registersuccessful(){

    return(
        <main id="dashboard"> 
        <div className="page-container">
            <div className="login d-lg-flex align-items-lg-center">
                <div className="login-content">
                    <div className="login-box">
					    <div className="login-logo">
                            <div className="content"><Link to="/"><img src="../../assets/website/img/omc-logo-new 1.png" alt="logo"/></Link></div>
						</div>
                        
                        <div className="login-header">
                       
                            <div className="content">{Parser(label.registersuccessfully_heading_description)}</div>
                        </div>
                        {/* <div className="login-body">
                            <div className="row">
							  <div className="col-xl-12">
							    <form>
                                {loadingRButton === 1 ? 
								<button className="btn btn-primary" disabled>
									<i className="fa fa-circle-o-notch fa-spin"></i> Loading
								</button>
								:
								<Link to="#" className="btn btn-primary" onClick={verifyEmail}>{label.email_verify_button}</Link>}
                                    
								</form>
							  </div>
							</div>
                        </div> */}
                        <div className="login-footer">
                            <div className="content">{Parser(label.footer_copyright)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </main>
    )
}
export default Registersuccessful;
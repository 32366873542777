import label from "../../config/PatientLabel";
import React, { useContext, useEffect, useState } from "react";
import axios2 from "../../axios2";
import AppContext from "../../AppContext";
import {
  Button,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
} from "@mantine/core";
import Plan from "../../components/patient/Plan";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import {
  FaExclamationCircle,
  FaMinusCircle,
  FaPlusCircle,
} from "react-icons/fa";

function Plans() {
  const navigate = useNavigate();
  const { context, setContext } = useContext(AppContext);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [isIDCardSelected, setIsIDCardSelected] = useState(false);
  const [additionalPets, setAdditionalPets] = useState(false);
  const [additionalPetsNumber, setAdditionalPetsNumber] = useState(1);
  const [hasRecommendedPlan, setHasRecommendedPlan] = useState(false);
  const [recommendedPlan, setRecommendedPlan] = useState(
    window.localStorage.getItem("plan")
  );

  const site_id = process.env.REACT_APP_SITE_ID;

  useEffect(() => {
    if (!context.user) return;
    axios2
      .get(
        "/plans?state_id=" +
          context?.user?.state_id +
          (context?.partner?.id ? "&partner_id=" + context?.partner?.id : "")
      )
      .then((response) => {
        setPlans(response.data.data);
        const sp = response.data.data.find((p) =>
          recommendedPlan ? p.id == recommendedPlan : p.is_recommended
        );
        window.localStorage.removeItem("plan");
        setHasRecommendedPlan(sp ? true : false);
        setSelectedPlan(sp ? sp : response.data.data[0]);
        setLoading(false);
      })
      .catch(() => {
        showNotification({
          title: "Error",
          message:
            "It seems you are logged in as doctor account, so you cannot place an order.",
          color: "red",
          icon: <FaExclamationCircle />,
        });
        navigate("/dashboard");
      });
  }, [context]);

  function handleSubmit() {
    selectedPlan["has_id_card"] = isIDCardSelected ? 1 : 0;
    selectedPlan["additional_pets"] = additionalPets ? 1 : 0;
    selectedPlan["additional_pets_number"] = additionalPets
      ? additionalPetsNumber
      : 0;
    setContext({ ...context, selectedPlan });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({event: 'plan_selected', site_id: site_id});

    navigate("/order/medical-info");
    window.scrollTo(0, 0);
  }
console.log(plans)
  return loading ? (
    <LoadingOverlay visible={true}></LoadingOverlay>
  ) : (
    <>
      <div
        className="container page-listing"
        style={{ padding: "1em", marginTop: "2em" }}
      >
        <ul>
          <li className="current">
            <Link to="/order/plan">
              <span />
              <label>Plan</label>
            </Link>
          </li>
          <li>
            <Link to="/order/medical-info">
              <span />
              <label>Medical Info</label>
            </Link>
          </li>
          <li>
            <Link to="/order/payment">
              <span />
              <label>Payment</label>
            </Link>
          </li>
        </ul>

        <small className="plan-title">{context?.user?.state_name}</small>

        <Grid mb="3em">
          <Grid.Col span={{ lg: hasRecommendedPlan ? 4 : 0 }}>
            {plans.length &&
              plans
                .filter((p) =>
                  recommendedPlan ? p.id == recommendedPlan : p.is_recommended
                )
                .map((plan, index) => (
                  <Plan
                    style={{ height: "100%" }}
                    plan={plan}
                    key={index}
                    changeCallBack={(plan) => setSelectedPlan(plan)}
                    selected={selectedPlan.id == plan.id}
                  />
                ))}
          </Grid.Col>
          <Grid.Col span={{ lg: hasRecommendedPlan ? 8 : 12 }}>
            <Flex wrap="wrap" justify="space-around">
              {plans.length &&
                plans
                  .filter((p) =>
                    recommendedPlan
                      ? p.id != recommendedPlan
                      : !p.is_recommended
                  )
                  .map((plan, index) => (
                    <Plan
                      plan={plan}
                      key={index}
                      changeCallBack={(plan) => setSelectedPlan(plan)}
                      selected={selectedPlan.id == plan.id}
                    />
                  ))}
            </Flex>
          </Grid.Col>
        </Grid>

        {selectedPlan.id && selectedPlan.details !== null && (
          <>
            <small className="plan-title">
              This Recommendation Bundle Includes
            </small>
            <div
              style={{
                border: "1.9px solid rgba(60,183,180,0.3)",
                borderRadius: "2vmin",
              }}
            >
              {selectedPlan.details.map((item, index) => {
                return (
                  <div style={{ margin: "5vmin" }} key={index}>
                    <img
                      style={{ marginRight: "2vmin" }}
                      src="../../assets/website/img/list.png"
                      alt=""
                    />
                    <h6
                      style={{ display: "inline", color: "rgba(0,0,0,0.8)" }}
                      className="content"
                    >
                      {item.title}
                    </h6>
                  </div>
                );
              })}
            </div>
          </>
        )}

{selectedPlan.id && selectedPlan.has_id_card === 1 &&
                <>
                    <small className='plan-title'>
                        Add a { site_id == 2 ? 'Pet' : 'Patient' } ID Card
                        <span style={{ color: 'red' }}> (Recommended)</span>
                    </small>

                    <div className="radio_boxes" style={{marginBottom: '2em'}}>
                        <label className="radio_box">
                            <input
                                type="radio"
                                checked={isIDCardSelected}
                                onChange={(value) => setIsIDCardSelected(value.currentTarget.checked)}
                            />
                            <span className="label_text">
                                Yes
                                <span>+${selectedPlan.id_card_price}</span>
                            </span>
                            <span className="checkmark" />
                        </label>

                        <label className="radio_box">
                            <input
                                type="radio"
                                checked={!isIDCardSelected}
                                onChange={(value) => setIsIDCardSelected(!value.currentTarget.checked)}
                            />
                            <span className="label_text">No</span>
                            <span className="checkmark" />
                        </label>
                    </div>
                </>
            }
            {selectedPlan.id && selectedPlan.support_additional_pets === 1 &&
                <>
                    <small className='plan-title'>
                        + Additional Pets
                    </small>

                    <div className="radio_boxes" style={{marginBottom: '2em'}}>
                        <label className="radio_box" style={{paddingRight: additionalPets ? '5em' : '0'}}>
                            <input
                                type="radio"
                                checked={additionalPets}
                                onChange={(value) => {
                                    setAdditionalPets(value.currentTarget.checked);
                                    setAdditionalPetsNumber(1);
                                }}
                            />
                            <span className="label_text">
                                Yes
                                <span>+${ additionalPets ? ((parseFloat(selectedPlan.additional_pet_price) + (isIDCardSelected ? parseFloat(selectedPlan.id_card_price) : 0))  * additionalPetsNumber).toFixed(2) : ( parseFloat(selectedPlan.additional_pet_price) + (isIDCardSelected ? parseFloat(selectedPlan.id_card_price) : 0)).toFixed(2) }</span>
                            </span>
                            <span className="checkmark" />
                            {additionalPets && <div style={{display: 'flex',  alignItems: 'center', margin: '10px', width: '100px', fontSize: '2em', position: 'absolute', top: '-23px', left: '140px'}}>
                                <FaMinusCircle onClick={() => setAdditionalPetsNumber(additionalPetsNumber - 1 > 1 ? additionalPetsNumber - 1 : 1)} style={{padding: '2px 5px', color: 'darkred', cursor: 'pointer'}}/>
                                <span><strong>{additionalPetsNumber}</strong></span>
                                <FaPlusCircle onClick={() => setAdditionalPetsNumber(additionalPetsNumber + 1 < 6 ? additionalPetsNumber + 1 : additionalPetsNumber)} style={{padding: '5px', color: 'green', cursor: 'pointer'}}/>
                            </div>}
                        </label>

                        <label className="radio_box">
                            <input
                                type="radio"
                                checked={!additionalPets}
                                onChange={(value) => {
                                    setAdditionalPets(!value.currentTarget.checked);
                                    setAdditionalPetsNumber(0);
                                }}
                            />
                            <span className="label_text">No</span>
                            <span className="checkmark" />
                        </label>

                    </div>
                </>
            }

        <Group justify="center">
          <Button
            size="xl"
            style={{ backgroundColor: "#23A5A7" }}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </Group>
      </div>
    </>
  );
}

export default Plans;


// {selectedPlan.id && selectedPlan.has_id_card === 1 && (
//   <>
//     <small className="plan-title">
//       Add a {site_id == 2 ? "Pet" : "Patient"} ID Card
//       <span style={{ color: "red" }}> (Recommended)</span>
//     </small>
//     <div className="radio_boxes" style={{ marginBottom: "2em" }}>
//       <label
//         className="radio_box"
//         style={{ paddingRight: additionalPets ? "5em" : "0" }}
//       >
//         <input
//           type="radio"
//           checked={additionalPets}
//           onChange={(value) => {
//             setAdditionalPets(value.currentTarget.checked);
//             setAdditionalPetsNumber(1);
//           }}
//         />
//         <span className="label_text">
//           Yes
//           <span>
//             +$
//             {additionalPets
//               ? (
//                   (parseFloat(selectedPlan.additional_pet_price) +
//                     (isIDCardSelected
//                       ? parseFloat(selectedPlan.id_card_price)
//                       : 0)) *
//                   additionalPetsNumber
//                 ).toFixed(2)
//               : (
//                   parseFloat(selectedPlan.additional_pet_price) +
//                   (isIDCardSelected
//                     ? parseFloat(selectedPlan.id_card_price)
//                     : 0)
//                 ).toFixed(2)}
//           </span>
//         </span>
//         <span className="checkmark" />
//         {additionalPets && (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               margin: "10px",
//               width: "100px",
//               fontSize: "2em",
//               position: "absolute",
//               top: "-23px",
//               left: "140px",
//             }}
//           >
//             <FaMinusCircle
//               onClick={() =>
//                 setAdditionalPetsNumber(
//                   additionalPetsNumber - 1 > 1
//                     ? additionalPetsNumber - 1
//                     : 1
//                 )
//               }
//               style={{
//                 padding: "2px 5px",
//                 color: "darkred",
//                 cursor: "pointer",
//               }}
//             />
//             <span>
//               <strong>{additionalPetsNumber}</strong>
//             </span>
//             <FaPlusCircle
//               onClick={() =>
//                 setAdditionalPetsNumber(
//                   additionalPetsNumber + 1 < 6
//                     ? additionalPetsNumber + 1
//                     : additionalPetsNumber
//                 )
//               }
//               style={{
//                 padding: "5px",
//                 color: "green",
//                 cursor: "pointer",
//               }}
//             />
//           </div>
//         )}
//       </label>

//       <label className="radio_box">
//         <input
//           type="radio"
//           checked={!isIDCardSelected}
//           onChange={(value) =>
//             setIsIDCardSelected(!value.currentTarget.checked)
//           }
//         />
//         <span className="label_text">No</span>
//         <span className="checkmark" />
//       </label>
//     </div>
//   </>
// )}
// {selectedPlan.id && selectedPlan.support_additional_pets === 1 && (
//   <>
//     <small className="plan-title">+ Additional Pets</small>

//     <div className="radio_boxes" style={{ marginBottom: "2em" }}>
//       <label
//         className="radio_box"
//         style={{ paddingRight: additionalPets ? "5em" : "0" }}
//       >
//         <input
//           type="radio"
//           checked={additionalPets}
//           onChange={(value) => {
//             setAdditionalPets(value.currentTarget.checked);
//             setAdditionalPetsNumber(1);
//           }}
//         />
//         <span className="label_text">
//           Yes
//           <span>
//             +$
//             {additionalPets
//               ? (
//                   (parseFloat(selectedPlan.additional_pet_price) +
//                     (isIDCardSelected
//                       ? parseFloat(selectedPlan.id_card_price)
//                       : 0)) *
//                   additionalPetsNumber
//                 ).toFixed(2)
//               : parseFloat(selectedPlan.additional_pet_price) +
//                 (isIDCardSelected
//                   ? parseFloat(selectedPlan.id_card_price)
//                   : 0)}
//           </span>
//         </span>
//         <span className="checkmark" />
//         {additionalPets && (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               margin: "10px",
//               width: "100px",
//               fontSize: "2em",
//               position: "absolute",
//               top: "-23px",
//               left: "140px",
//             }}
//           >
//             <FaMinusCircle
//               onClick={() =>
//                 setAdditionalPetsNumber(
//                   additionalPetsNumber - 1 > 1
//                     ? additionalPetsNumber - 1
//                     : 1
//                 )
//               }
//               style={{
//                 padding: "2px 5px",
//                 color: "darkred",
//                 cursor: "pointer",
//               }}
//             />
//             <span>
//               <strong>{additionalPetsNumber}</strong>
//             </span>
//             <FaPlusCircle
//               onClick={() =>
//                 setAdditionalPetsNumber(
//                   additionalPetsNumber + 1 < 6
//                     ? additionalPetsNumber + 1
//                     : additionalPetsNumber
//                 )
//               }
//               style={{
//                 padding: "5px",
//                 color: "green",
//                 cursor: "pointer",
//               }}
//             />
//           </div>
//         )}
//       </label>

//       <label className="radio_box">
//         <input
//           type="radio"
//           checked={!additionalPets}
//           onChange={(value) => {
//             setAdditionalPets(!value.currentTarget.checked);
//             setAdditionalPetsNumber(0);
//           }}
//         />
//         <span className="label_text">No</span>
//         <span className="checkmark" />
//       </label>
//     </div>
//   </>
// )}
import $ from "jquery";
import "jquery-validation";
import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams, } from "react-router-dom";
import Label from "../../config/DoctorLabel";
import Axios from "../../axios";
import Moment from "react-moment";
import "moment-timezone";
import Helpers from "../../components/doctor/Helper";
export const identifierRegex =
  /^(([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])$/;

export const containerPathRegex = /^(?:\/\.?[a-zA-Z0-9_\\-]*)+$/;

export const domainRegex =
  /^(?:\s?(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))+$/;

export const basicAuthRegex =
  /.+:.+:\$1\$[A-Za-z0-9./]{1,16}\$[A-Za-z0-9./]{21,31}$|\$2\$[A-Za-z0-9]{1,16}\$[A-Za-z0-9./]{40,55}$/;
$.validator.addMethod(
  "phoneUS",
  function (phone_number, element) {
    phone_number = phone_number.replace(/\s+/g, "");
    return (
      this.optional(element) ||
      (phone_number.length > 9 &&
        phone_number.match(
          /^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/
        ))
    );
  },
  "Please specify a valid phone number"
);
const Editprofile = (props) => {
    const { id } = useParams();
     
    const [date, setDate] = useState([]);
    const [month, setMonth] = useState([]);
    const [year, setYear] = useState([]);
    const [states, setStates] = useState([]);
    const [loadingRButton, setloadingRButton] = useState(0);
    const [formdata,setFormdata]=useState([]);
    const [userInfo,setUserInfo]=useState([]);
    const [selectDay,setSelectDay]=useState('');
    const [selectMonth,setSelectMonth]=useState('');
    const [selectYear,setSelectYear]=useState('');
  const SessionToken=window.sessionStorage.getItem('token');

  const config = {
    headers: { Authorization: `bearer ${SessionToken}`,"Access-Control-Allow-Origin": "*"}
  };
  const navigate = useNavigate();

 
  let m = [];
  for (let i = 1; i <= 12; i++) {
    m.push({
      id: i,
      value: i,
    });
  }

  let d = [];
  for (let i = 1; i <= 31; i++) {
    d.push({
      id: i,
      value: i,
    });
  }



  let y = [];
  let dt = new Date();
  dt = dt.getFullYear();

  for (let i = 1980; i <= dt; i++) {
    y.push({
      id: i,
      value: i,
    });
  }


  useEffect(() => {

    document.querySelector("#breadcrumb").innerHTML = Label.EDIT_REGISTER;


    if(!id){
      navigate('/manual-register-patient');
    }

    $("#frm-patient-add").validate({
      rules: {
        fname: {
          required: true,
          maxlength: 20,
        },
        lname: {
          required: true,
          maxlength: 20,
        },
        dmvid: {
          required: true,
          maxlength: 20,
        },
        email: {
          required: true,
          email: true,
        },
        mobile: {
          required: true,
          phoneUS: true,
          maxlength: 10,
          minlength: 10,
          number: true
        },
        date: {
          required: true,
        },
        month: {
          required: true,
        },
        year: {
          required: true,
        },
        gender: {
          required: true,
        },
        address: {
          required: true,
        },
        city: {
          required: true,
        },
        states: {
          required: true,
        },
        pincode:{
          required:true,
          number: true
        }
      },
    });
  }, []);
  useEffect(() => {
      Axios.post('mGetStatesbyDoctor','',config).then(res =>{
        if(res.data.status=='unauthorised'){
          Helpers.Logout();
        }else if(res.data.status=='Failed'){
          Helpers.errorAlert(res.data.message,'error');
        }else{
          setStates(res.data.states);
      }
    }).catch(response =>{
      Helpers.errorAlert(response.data.error,'error')
    });

  }, []);

  useEffect(() => {
    let data={
       patient_id:id
    }
    Axios.post('mPatientProfileById',data,config).then(res =>{
      if(res.data.status=='unauthorised'){
        Helpers.Logout();
      }else if(res.data.status=='Failed'){
        Helpers.errorAlert(res.data.message,'error');
      }else{
      
        setUserInfo(res.data.user);
       
        if(typeof res.data.user.dob !="undefined"){
           let dob=res.data.user.dob.split('-');
           setSelectYear(dob[0]);
           setSelectMonth(dob[1].replace(/^0+/, ''));
           setSelectDay(dob[2].replace(/^0+/, ''));
          
          
        }
     }
  }).catch(response =>{
    console.log(response);
    Helpers.errorAlert(response.data.error,'error')
  });

}, []);




  const updatePatient = (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target)
    setloadingRButton(1);
    Axios.post('mUpdatePatientProfileById',formdata,config).then(res =>{
        setloadingRButton(0);
        if(res.data.status=='unauthorised'){
          Helpers.Logout();
        }else if(res.data.status=='Failed'){

          let str='';
          res.data.message.map(function(data) {
              str +=data+"\n";
          })

          Helpers.errorAlert(str,'error');
        }else{
          Helpers.errorAlert(res.data.message,'success')
        

        
      }
  }).catch(response =>{
      console.log(response.message);
      Helpers.errorAlert(response.message,'error')
  });
  };

  const onChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value })
  }

  const onChangeDate = (e) => {
    setSelectDay(e.target.value)
  }
  const onChangeMonth = (e) => {
    setSelectMonth( e.target.value )
  }
  const onChangeYear = (e) => {
    setSelectYear(e.target.value)
  }
  const btnBack = () => { 
    window.history.back();
  }

  return (
    <>
      <form onSubmit={updatePatient} method="post" id="frm-patient-add">
        <div className="content-header">
        {  userInfo.fname &&
          <h1 className="page-title">Edit {userInfo.fname+" "+(userInfo.mname?userInfo.mname:'')+" "+userInfo.lname }</h1>
        }
        </div>
        <div className="patient_data">
         {  userInfo.id?
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fname"
                  required
                  onChange={onChange}
                  defaultValue={userInfo.fname}
                   />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>Middle Name</label>
                <input type="hidden" defaultValue={id}  name="userId"/>
                <input type="text" className="form-control" name="mname" onChange={onChange} defaultValue={userInfo.mname}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>Last Name</label>
                <input type="text" className="form-control" name="lname" onChange={onChange} defaultValue={userInfo.lname}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>DMV ID#</label>
                <input type="text" className="form-control" name="dmvid" onChange={onChange} defaultValue={userInfo.license_no}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>Email ID</label>
                <input type="email" className="form-control" name="email" onChange={onChange} defaultValue={userInfo.email}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>Phone Number</label>
                <input type="text" className="form-control" name="mobile_no" maxLength={10} onChange={onChange} defaultValue={userInfo.mobile_no}/>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="form-group mb-0">
                <label>Date of Birth</label>
              </div>
              <div className="row">
              
              <div className="col-md-4">
                  <div className="form-group mb-4">
                    <select className="form-control select" name="month" onChange={onChangeMonth} value={selectMonth}>
                      <option value="">Select Month</option>
                      {m.map(function(data, idx) {
                        return <option key={data.id} value={data.id} >{data.value}</option>;
                      })};

                    </select>
                  </div>
                </div>
                
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <select className="form-control select" name="date" onChange={onChangeDate} value={selectDay}>
                    <option value="">Select day</option>
                    {d.map(function(data, idx) {
                        return <option key={data.id} value={data.id} >{data.value}</option>;
                    })};
                   
                    </select>
                  </div>
                </div>
              
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <select className="form-control select" name="year" onChange={onChangeYear} value={selectYear}>
                      <option value="">Select Year</option>
                      {y.map(function(data, idx) {
                        return <option  key={data.id} value={data.id} >{data.value}</option>;
                      })};


                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>Gender</label>
                <select className="form-control select" name="gender" onChange={onChange} defaultValue={userInfo.gender}>
                  <option value=''>Select Gender</option>
                  <option   value='Male'>Male</option>
                  <option   value='Female'>Female</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 col-xl-12">
              <div className="form-group mb-4">
                <label>Address</label>
                <input type="text" className="form-control" name="address" onChange={onChange} defaultValue={userInfo.address}/>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4">
              <div className="form-group mb-4">
                <label>City</label>
                <input type="text" className="form-control" name="city" onChange={onChange}  defaultValue={userInfo.city}/>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4">
              <div className="form-group mb-4">
                <label>Pin Code</label>
                <input type="text" className="form-control" name="pincode" onChange={onChange} defaultValue={userInfo.zipcode}/>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4">
                <label>State</label>
                <select className="form-control select" name="states" onChange={onChange} defaultValue={userInfo.state} >
                  <option value="">Select State</option>
                  {states.map(({name,id}) => (
                      <option value={id} key={id}>{name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-8 col-xl-4">
              <div className="form-group button-group d-flex">
           

                {loadingRButton === 1 ?     
                <button className="btn submit-btn" disabled> <i className="fa fa-circle-o-notch fa-spin"></i> Loading</button>
                :
                <button className="btn submit-btn" type="submit">Save</button>}

                <button className="btn close-btn" type='reset' onClick={btnBack}>{Label.BTN_BACK}</button>

              </div>
            </div>
          </div>:  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

          }
        </div>
      </form>
    </>
  );
};
export default Editprofile;

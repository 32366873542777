import React, { useState, useEffect }from "react";
import Label from "../../config/DoctorLabel";

const Concatus = () => {
    
	 
    useEffect(() => {
		

		document.querySelector('#breadcrumb').innerHTML=Label.CONTACT_US_HEADING;
	
	  }, [])
    return (
        <div>
                        <div class="content-header">                            
                            <h1 class="page-title">Contact Us</h1>
                        </div>
						<div id="contact" class="content-body">
						  <div class="row">
						    <div class="col-md-7 col-lg-8 contact-left p-0">
							  <div class="contact_lf_block">
							    <div class="contact-box">
								  <img class="icon-box" src="img/headphone.png" alt=""/>
								  <div class="textblock">
								    <span>QUICK SUPPORT</span>
									<h3>Contact Us</h3>
									<p>Our experts are always available for help. Feel tree to contact us in case of  any queries.</p>
								  </div>
								</div>
								<div class="contact-box">
								  <img class="icon-box" src="img/location.png" alt=""/>
								  <div class="textblock">
									<h3>Walk-Ins-Accepted Online Medical Card</h3>
									<p>2001 East 1st St. Suite 102. Santa Ana CA 92705</p>
								  </div>
								</div>
							  </div>
							</div>
							<div class="col-md-5 col-lg-4 contact-right p-0">
							  <div class="contact_rt_block">
							    <div class="contact-box">
								  <img class="icon-box" src="img/headphone.png" alt=""/>
								  <div class="textblock">
								    <span>Contact Details</span>
									<h3>Call Us 24/7 <span><a href="tel:8889888420">(888) 988-8420</a></span></h3>
								  </div>
								</div> 
                                <div class="contact-box">
								  <img class="icon-box" src="img/timer.png" alt=""/>
								  <div class="textblock">
								    <span>Time Details</span>
									<h3>Mon-Sun: <span>9:00 AM - 10:00 PM</span></h3>
								  </div>
								</div> 								
							  </div>
							</div>
						  </div>
						</div>
                    </div>
    );
};
export default Concatus;
import React,{useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../assets/website/css/videocall.css';
import axios from "../../axios2";
import { Button, Loader, LoadingOverlay } from '@mantine/core';

function Videocall({ is_doctor }){
    
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [room, setRoom] = useState({});
    const [error, setError] = useState();
    const intervalRef = useRef();

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            getRoom();
        }, 5000);
        return () => clearInterval(intervalRef.current);
    }, []);

    const getRoom = () => {
        setLoading(true)
        axios.post('/video/start/' + id)
            .then((resp) => {
                setRoom(resp.data.data);
                if (resp.data.data.room_id) {
                    setLoading(false);
                    setError(null);
                    clearInterval(intervalRef.current)
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(error.response.data.message);
            });
    }

    const retry = () => {
        getRoom();
    }

    return(
        <>
            {(loading || error )? <div style={{ textAlign: 'center', marginTop: '2em' }}>
                <h5><Loader /> {is_doctor ? 'Waiting for the patient to make a video call, you can call the patient instead' : 'Waiting for the doctor to accept your request' } <Loader /></h5>
                {error ? <div><h6 style={{ color: 'darkred' }}>{error}</h6><Button loading={loading} color='indigo' onClick={() => retry()}>Retry</Button></div> : ''}
            </div> : (
                    <iframe width="100%" height={is_doctor ? 300 : 767} src={ 'https://omc.yourvideo.live/' + (is_doctor ? 'host/' : '') + room.room_id + '?name=' + (is_doctor ? room.doctor_name : room.patient_name) } allowFullScreen allow="camera; microphone;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" frameBorder="0"></iframe>
            )}
        </>
    )
}
export default Videocall;
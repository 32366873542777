import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  Image,
  Space,
  TextInput,
  Button,
  Select,
  FileButton,
  Group,
  Input,
  Alert,
  Text,
  Radio,
  Textarea,
  Modal,
} from "@mantine/core";
import SignaturePad from "signature_pad";
import SigningImage from "../../assets/website/assets/images/sign.png";
import axios2 from "../../axios2";
import AppContext from "../../AppContext";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import * as dayjs from "dayjs";

import { Link, useLocation, useNavigate } from "react-router-dom";
import MedicalConditionsCheckList from "./MedicalConditionsCheckList";
import { confirmAlert } from "react-confirm-alert";
import DOB from "../../components/DOB";
import { FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
export function isLegalAge(birthDate) {
  const [month, day, year] = birthDate.split("/").map(Number);
  const birthDateObj = new Date(year, month - 1, day);
  const today = new Date();

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  const dayDiff = today.getDate() - birthDateObj.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age >= 18;
}

function UploadDoc() {
  const notEmpty = "must not be empty";
  const site_id = process.env.REACT_APP_SITE_ID;

  const { context, setContext } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [dob, setDob] = useState(context.user?.dob ? context.user?.dob : "");

  const [showIdProof, setShowIdProod] = useState(
    context.user?.id_proof ? false : true
  );
  const [showMedicalRecord, setShowMedicalRecord] = useState(
    context.user?.medical_record || context.user?.state_id != 9 ? false : true
  );
  const [showSignature, setShowSignature] = useState(
    context.user?.signature ? false : true
  );

  const [selectedPlan, setSelectedPlan] = useState();
  const [isMedicalRecordRequired, setMedicalRecordRequired] = useState(true);

  const [disease, setDisease] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedDisease, setSelectedDisease] = useState(
    context.user?.medical_conditions.map((m) => m.id)
  );

  const [picture, setPicture] = useState(null);
  const [signaturePad, setSignaturePad] = useState();
  const [medicalRecord, setMedicalRecord] = useState(null);
  // const [is18Checked, setIs18Checked] = useState(false);
  const [isSchizoChecked, setIsSchizoChecked] = useState(false);
  const [isPregnant, setIsPregnant] = useState(false);
  // const [showNotLegalModal,setShowNotLegalModal] = useState(false)
  const [
    isLegalModalOpened,
    { open: openIsLegalModal, close: closeIsLegalModal },
  ] = useDisclosure(false);
  const [pets, setPets] = useState(context?.user?.pets);

  console.log("pets", pets);

  const [pidChecked, setPidChecked] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData,removeFormData] = useLocalStorage({
    key: "formData",
    defaultValue: {},
  });
  const [formDataLoaded, setFormDataLoaded] = useState(false);
  const [isCaregiverRequired, setIsCaregiverRequired] = useState(false);
  const [isLegal, setIsLegal] = useState(null);
  useEffect(() => {
    if (formData) {
      setFormDataLoaded(true);
    }
  }, [formData]);
  useEffect(() => {
    if (dob == "") return;
    setIsLegal(isLegalAge(dob));
  }, [dob]);
  useEffect(() => {
    checkCaregiverRequired();
  }, [isLegal]);
  function checkCaregiverRequired() {
    if (selectedPlan && selectedPlan.has_caregiver == 1 && site_id != 2) {
      setIsCaregiverRequired(true);
      return;
    } else if (isLegal != null && isLegal == false && site_id != 2) {
      setIsCaregiverRequired(true);
      return;
    } else {
      setIsCaregiverRequired(false);
    }
  }
  useEffect(() => {
    checkCaregiverRequired();
  }, []);
  console.log(isCaregiverRequired);
  const form = useForm({
    initialValues: {
      driver_license:
        context.user?.driver_license_number || formData.driver_license,
      caregiver_identification:
        context.user?.caregiver_identification ||
        formData.caregiver_identification,
      caregiver_name: context.user?.caregiver_name || formData.caregiver_name,
      reffered_by: context.user?.reffered_by || formData.reffered_by,
      gender: context.user?.gender || formData.gender || "",
      pid: context.user?.pid,
      id_proof_type: context.user?.id_proof_type,
      allergies: context.user?.allergies || formData.allergies,
    },
    validate: {
      driver_license: (value) =>
        !value && site_id != 2 ? "Driver License " + notEmpty : null,
      gender: (value) => (!value ? "Gender must " + notEmpty : null),
      pid: (value) =>
        context.user?.state_id == 45 && site_id != 2 && !value
          ? "Please enter Pennsylvania ID"
          : null,
    },
  });
  console.log(form.values)
  useEffect(() => {
    if (formDataLoaded) {
      form.setValues({
        driver_license:
          context.user?.driver_license_number || formData.driver_license,
        caregiver_identification:
          context.user?.caregiver_identification ||
          formData.caregiver_identification,
        caregiver_name: context.user?.caregiver_name || formData.caregiver_name,
        reffered_by: context.user?.reffered_by || formData.reffered_by,
        gender: context.user?.gender || formData.gender,
        pid: context.user?.pid,
        id_proof_type: context.user?.id_proof_type,
        allergies: context.user?.allergies || formData.allergies,
      });
    }
  }, [formDataLoaded, formData, context.user]);

  useEffect(() => {
    if (!context.selectedPlan || !context.selectedPlan.id) {
      navigate("/order/plan");
      return;
    }
    setSelectedPlan(context.selectedPlan);
    axios2
      .get("/questions/state/" + context?.user?.state_id + "/user")
      .then((resp) => {
        setQuestions(resp.data.data);
      });

    while (pets.length < context.selectedPlan.additional_pets_number + 1) {
      pets.push({
        id: -1,
        name: "",
        breed: "",
        type: "",
        age: 1,
        image: "",
      });
    }

    pets.splice(context.selectedPlan.additional_pets_number + 1);

    setPets(pets);
  }, []);

  useEffect(() => {
    if (showSignature) {
      setSignaturePad(new SignaturePad(document.getElementById("signature")));
    }
  }, [showSignature]);

  function handleErrors(errors, values, event) {
    if (Object.keys(errors).length > 0) {
      for (const element of Object.values(event.target)) {
        if (element.dataset && element.dataset.invalid) {
          element.focus();
          break;
        }
      }
    }
  }
  function sendPlanData(data) {
    removeFormData()
    setIsLoading(true);
    axios2
      .post("/user/profile", data)
      .then((res) => {
        setIsLoading(false);
        setContext({ ...context, user: res.data.data });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({event: 'medical_info_completed', site_id: site_id});

        navigate("/order/payment");
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function submitForm(data,onContinue = false) {
    let message = "";
    if (showSignature) {
      data["signature"] = signaturePad.toDataURL();
    }

    if (showIdProof && site_id != 2) {
      data["id_proof"] = picture;
    }

    if (showMedicalRecord && site_id != 2) {
      data["medical_record"] = medicalRecord;
    }

    if (site_id == 2) {
      for (const k in pets) {
        if (!pets[k].name) {
          document.getElementById("pet-" + k + "-name").focus();
          message = "Please enter pet's name.";
        }
        if (!pets[k].breed) {
          document.getElementById("pet-" + k + "-breed").focus();
          message = "Please enter pet's breed.";
        }
        if (!pets[k].type) {
          document.getElementById("pet-" + k + "-type").focus();
          message = "Please enter pet's type.";
        }
        if (!pets[k].age) {
          document.getElementById("pet-" + k + "-age").focus();
          message = "Please enter pet's age.";
        }
        if (!pets[k].image && selectedPlan.has_id_card) {
          document.getElementById("pet-" + k + "-image").scrollIntoView();
          message = "Please enter pet's photo.";
        }
        data["pet_files[" + k + "]"] = pets[k].image;
      }
      data["pets"] = JSON.stringify(pets);
    }

    data["dob"] = dayjs(dob).format("MM-DD-YYYY");
    data["answers"] = JSON.stringify(answers);

    if (
      showMedicalRecord &&
      isMedicalRecordRequired &&
      !medicalRecord &&
      site_id != 2
    ) {
      document.getElementById("medical-record").scrollIntoView();
      message = "Please upload your medical record.";
    }

    if (showIdProof && !picture && site_id != 2) {
      document.getElementById("upload-id").scrollIntoView();
      message = "Please upload your ID.";
    }

    if (!dob) {
      document.getElementById("dob-field").scrollIntoView();
      message = "Please select your birth of date.";
    }

    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (data[key] != null) {
        formData.append(key, data[key]);
      }
    });

    selectedDisease.map((key) =>
      formData.append("medical_conditions[]", parseInt(key))
    );

    console.log(pets.length, selectedPlan.additional_pets_number + 1);

    if (
      (site_id == 2 &&
        !message &&
        dob &&
        pets.length == selectedPlan.additional_pets_number + 1) ||
      ((!showIdProof || picture !== null) &&
        (!showMedicalRecord ||
          !isMedicalRecordRequired ||
          medicalRecord !== null) &&
        dob &&
        data["id_proof_type"])
    ) {

      if (
        site_id == 1 &&
        context?.user?.state_id == "6" &&
        !onContinue &&
        !selectedPlan.has_caregiver &&
        !isLegal
      ) {
        setFormData(data);
        openIsLegalModal();
        return;
      }
      sendPlanData(formData);
    } else {
      notifications.show({
        title: "Error",
        message: message,
        color: "red",
      });
    }
  }

  const updateQuestions = (value, question) => {
    answers[question.id] = value;
    setAnswers(answers);
  };

  const updatePet = (index, key, val) => {
    pets[index][key] = val;
    setPets(Object.assign([], pets));
  };

  const deletePet = (index) => {
    pets.splice(index, 1);
    selectedPlan.additional_pets_number -= 1;
    setSelectedPlan(Object.assign({}, selectedPlan));
    setContext({ ...context, selectedPlan });
    setPets(Object.assign([], pets));
  };

  return (
    <div className="container page-listing" style={{ paddingBlock: "5vmin" }}>
      <ul>
        <li className="filled">
          <Link to="/order/plan">
            <span></span> <label>Plan</label>
          </Link>
        </li>
        <li className="current">
          <Link to="/order/medical-info">
            <span></span> <label>Medical Info</label>
          </Link>
        </li>
        <li>
          <Link to="/order/payment">
            <span></span> <label>Payment</label>
          </Link>
        </li>
      </ul>

      <form
        onSubmit={form.onSubmit((values) => submitForm(values), handleErrors)}
      >
        <Grid gutter={{ md: "lg" }} m="auto" justify="center" p="10px">
          {site_id != 2 && (
            <Grid.Col span={{ md: 6, lg: 5 }}>
              <TextInput
                label="Your Driver License Number or State ID Number"
                withAsterisk
                mt="lg"
                size="sm"
                {...form.getInputProps("driver_license")}
              />
            </Grid.Col>
          )}

          <Grid.Col
            span={{ md: site_id == 2 ? 10 : 6, lg: site_id == 2 ? 10 : 5 }}
          >
            <Select
              label="Gender Assigned at birth"
              mt="lg"
              withAsterisk
              size="sm"
              placeholder="Pick one"
              data={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ]}
              {...form.getInputProps("gender")}
            />
          </Grid.Col>

          <Grid.Col span={{ md: 6, lg: 5 }}>
            {/* <Input.Wrapper label="Date of Birth" withAsterisk mt='lg' size="sm">
                            <Input
                                component={DatePicker}
                                selected={dob}
                                onChange={(v) => setDob(v)}
                                adjustDateOnChange={true}
                                showMonthDropdown
                                showYearDropdown
                                peekNextMonth={false}
                                disabledKeyboardNavigation
                                focusSelectedMonth={true}
                                dropdownMode="select"
                                maxDate={subYears(new Date(), 18)}
                                customInput={<MaskedTextInput type="text" mask='99/99/9999' />}
                                dateFormat="MM/dd/yyyy"
                            />
                        </Input.Wrapper> */}
            <div id="dob-field" tabIndex={2}>
              <DOB setDob={setDob} dob={dob}></DOB>
            </div>
          </Grid.Col>

          <Grid.Col style={{ marginTop: "20px" }} span={{ md: 6, lg: 5 }}>
            <TextInput
              label="Referred by"
              mt="lg"
              size="sm"
              {...form.getInputProps("reffered_by")}
            />
          </Grid.Col>

          {site_id != 2 && (
            <Grid.Col span={{ lg: 10 }}>
              <TextInput
                label=" What are your allergies? (If any) "
                mt="lg"
                size="sm"
                {...form.getInputProps("allergies")}
              />
            </Grid.Col>
          )}

          {context.user?.state_id == 45 && site_id != 2 && (
            <Grid.Col span={{ md: 10 }}>
              <TextInput
                label="If you have already registered at Pennsylvania medical marijuana program website and have a patient ID number, please provide us with your patient ID number here"
                mt="lg"
                disabled={pidChecked}
                size="sm"
                {...form.getInputProps("pid")}
              />
              <Checkbox
                size="xs"
                checked={pidChecked}
                onChange={(event) => {
                  setPidChecked(event.currentTarget.checked);
                  if (event.currentTarget.checked) {
                    form.setFieldValue("pid", "I Don't Have It");
                  } else {
                    form.setFieldValue("pid", "");
                  }
                }}
                label="Please click here if you don't have the Pennsylvania medical marijuana program Patient ID number."
              />
            </Grid.Col>
          )}

          {context.user?.state_id == 9 && site_id != 2 && (
            <Grid.Col span={{ md: 10 }}>
              <small
                className="mb-4 mt-4 d-block"
                id="medical-record"
                tabIndex={1}
              >
                Medical Record
              </small>

              <p>
                Please take a picture of your last Dr.'s visit notes or
                documents for our Doctor to review
              </p>

              {context.user?.medical_record && (
                <blockquote style={{ textAlign: "center" }}>
                  You've already uploaded below file:
                  <Group justify="center" m={20}>
                    <Image
                      src={context.user?.medical_record}
                      width={150}
                    ></Image>
                  </Group>
                  <Button size="xs" onClick={() => setShowMedicalRecord(true)}>
                    Modify
                  </Button>
                </blockquote>
              )}

              {showMedicalRecord && (
                <div
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    border: "1.9px dashed rgba(60,183,180,0.3)",
                    padding: "2em 1em",
                    width: "80%",
                  }}
                >
                  <FileButton
                    onChange={setMedicalRecord}
                    accept="image/png,image/jpeg"
                    required={isMedicalRecordRequired}
                  >
                    {(props) =>
                      medicalRecord === null ? (
                        <Button {...props} color="cyan">
                          Choose or Take a Picture{" "}
                          <span style={{ color: "#fa5252" }}>&nbsp;*</span>
                        </Button>
                      ) : (
                        <>
                          <div
                            style={{ fontSize: "12px", marginBottom: "5px" }}
                          >
                            Clikc on the image to change it
                          </div>
                          <Image
                            fit="contain"
                            {...props}
                            src={URL.createObjectURL(medicalRecord)}
                            style={{ maxWidth: "200px", margin: "auto" }}
                          />
                        </>
                      )
                    }
                  </FileButton>
                </div>
              )}
              <Checkbox
                mt={20}
                checked={isMedicalRecordRequired ? false : true}
                label="I do not have medical record now but I will provide it later."
                onChange={(event) =>
                  setMedicalRecordRequired(
                    event.currentTarget.checked ? false : true
                  )
                }
              />
              <Text size="sm">
                Please note that the pictures of your Medical records are a
                state requirement to complete your consultation and get you
                approved by the state and the doctor. If you do not have your
                documents right now, you may still proceed with your
                application. However, they will still be required for the
                application processing.
              </Text>
              <Space h="md" />
            </Grid.Col>
          )}

          {isCaregiverRequired == true && (
            <>
              <Grid.Col span={{ md: 6, lg: 5 }}>
                <TextInput
                  label="Caregiver Name"
                  mt="lg"
                  required
                  size="sm"
                  {...form.getInputProps("caregiver_name")}
                />
              </Grid.Col>
              <Grid.Col span={{ md: 6, lg: 5 }}>
                <TextInput
                  label="Caregiver Identification"
                  mt="lg"
                  required
                  size="sm"
                  {...form.getInputProps("caregiver_identification")}
                />
              </Grid.Col>
            </>
          )}

          <Grid.Col span={{ md: 10, lg: 10 }}>
            {site_id != 2 && (
              <>
                <blockquote style={{ marginBottom: "1em" }}>
                  <p>Medical Canabis Qualifying Conditions are listed below</p>
                  <p>
                    Check each Medical problem that you suffer from or select
                    other to have the doctor evaluate your unique condition. At
                    least one box must be checked to qualify.
                  </p>
                </blockquote>

                <MedicalConditionsCheckList
                  selectedDisease={selectedDisease}
                  setSelectedDisease={setSelectedDisease}
                />

                <Space h="md" />

                <small
                  className="mb-4 mt-4 d-block"
                  id="upload-id"
                  tabIndex={0}
                >
                  Upload Your ID
                </small>

                <p>
                  As required by the state, please upload a picture of
                  government issued ID, such as your driver's license, a state
                  ID or passport
                </p>

                {context.user?.id_proof && (
                  <blockquote style={{ textAlign: "center" }}>
                    You've already uploaded below file:
                    <Group justify="center" m={20}>
                      <Image src={context.user?.id_proof} width={150}></Image>
                    </Group>
                    <Button size="xs" onClick={() => setShowIdProod(true)}>
                      Modify
                    </Button>
                  </blockquote>
                )}

                {showIdProof && (
                  <div
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      border: "1.9px dashed rgba(60,183,180,0.3)",
                      padding: "2em 1em",
                      width: "80%",
                    }}
                  >
                    <FileButton
                      onChange={setPicture}
                      accept="image/png,image/jpeg"
                      required
                    >
                      {(props) =>
                        picture === null ? (
                          <Button {...props} color="cyan">
                            Choose or Take a Picture{" "}
                            <span style={{ color: "#fa5252" }}>&nbsp;*</span>
                          </Button>
                        ) : (
                          <>
                            <div
                              style={{ fontSize: "12px", marginBottom: "5px" }}
                            >
                              Clikc on the image to change it
                            </div>
                            <Image
                              fit="contain"
                              {...props}
                              src={URL.createObjectURL(picture)}
                              style={{ maxWidth: "200px", margin: "auto" }}
                            />
                          </>
                        )
                      }
                    </FileButton>

                    <Select
                      label="ID Type"
                      mt="lg"
                      withAsterisk
                      size="sm"
                      placeholder="Pick one"
                      data={[
                        { label: "Driver's License", value: "driver_license" },
                        { label: "State ID Card", value: "idcard" },
                        { label: "Tribal ID Card", value: "tribal" },
                        {
                          label: "U.S. Passport/U.S. ID Card",
                          value: "passport",
                        },
                      ]}
                      {...form.getInputProps("id_proof_type")}
                    />
                  </div>
                )}
              </>
            )}

            {site_id == 2 &&
              pets.map((pet, index) => (
                <>
                  <Space h="md" />

                  <small
                    className="mb-4 mt-4 d-block"
                    id={"pet-" + (index + 1)}
                    tabIndex={0}
                  >
                    {index > 0 ? "Additional" : ""} Pet {index + 1}{" "}
                    {index > 0 ? (
                      <FaTimesCircle
                        onClick={() => deletePet(index)}
                        style={{
                          color: "darkred",
                          position: "relative",
                          top: "2px",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        title="Remove Additional Pet"
                      />
                    ) : (
                      ""
                    )}
                  </small>

                  <Grid
                    gutter={{ md: "lg" }}
                    style={{ margin: "auto", justifyContent: "center" }}
                  >
                    <Grid.Col span={{ md: 6, lg: 5 }}>
                      <TextInput
                        label="Pet's name"
                        id={"pet-" + index + "-name"}
                        withAsterisk
                        mt="lg"
                        size="sm"
                        value={pet.name}
                        onChange={(event) =>
                          updatePet(index, "name", event.currentTarget.value)
                        }
                      />
                    </Grid.Col>

                    <Grid.Col span={{ md: 6, lg: 5 }}>
                      <TextInput
                        label="Pet's breed"
                        id={"pet-" + index + "-breed"}
                        withAsterisk
                        mt="lg"
                        size="sm"
                        value={pet.breed}
                        onChange={(event) =>
                          updatePet(index, "breed", event.currentTarget.value)
                        }
                      />
                    </Grid.Col>

                    <Grid.Col span={{ md: 6, lg: 5 }}>
                      <Select
                        label="Pet's Type"
                        mt="lg"
                        withAsterisk
                        size="sm"
                        id={"pet-" + index + "-type"}
                        placeholder="Pick one"
                        data={[
                          { label: "dog", value: "Dog" },
                          { label: "cat", value: "Cat" },
                          { label: "other", value: "other" },
                        ]}
                        value={pet.type}
                        onChange={(value) => updatePet(index, "type", value)}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ md: 6, lg: 5 }}>
                      <TextInput
                        label="Pet's Age"
                        id={"pet-" + index + "-age"}
                        withAsterisk
                        mt="lg"
                        size="sm"
                        value={pet.age}
                        onChange={(event) =>
                          updatePet(index, "age", event.currentTarget.value)
                        }
                      />
                    </Grid.Col>
                  </Grid>

                  <h3
                    style={{ textAlign: "center", margin: "1em 1em 0em 1em" }}
                    id={"pet-" + index + "-image"}
                  >
                    Upload Pet's Photo{" "}
                    {selectedPlan?.has_id_card ? (
                      <span style={{ color: "#fa5252", fontSize: "10px" }}>
                        &nbsp;(*Required)
                      </span>
                    ) : (
                      <span style={{ fontSize: "10px" }}>(Optional)</span>
                    )}
                  </h3>

                  {pet.image && (
                    <blockquote style={{ textAlign: "center" }}>
                      You've already uploaded below file:
                      <Group justify="center" m={20}>
                        <Image
                          src={
                            typeof pet.image == "object"
                              ? URL.createObjectURL(pet.image)
                              : pet.image
                          }
                          width={150}
                        ></Image>
                      </Group>
                      <Button
                        size="xs"
                        onClick={() => updatePet(index, "show_file", true)}
                      >
                        Modify
                      </Button>
                    </blockquote>
                  )}

                  {(!pet.image || pet.show_file) && (
                    <div
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        border: "1.9px dashed rgba(60,183,180,0.3)",
                        padding: "2em 1em",
                        width: "80%",
                      }}
                    >
                      <FileButton
                        onChange={(file) => updatePet(index, "image", file)}
                        accept="image/png,image/jpeg"
                        required={selectedPlan?.has_id_card}
                      >
                        {(props) =>
                          !pet.image ? (
                            <Button {...props} color="cyan">
                              Choose or Take a Picture
                              {selectedPlan?.has_id_card ? (
                                <span style={{ color: "#fa5252" }}>
                                  &nbsp;*
                                </span>
                              ) : (
                                <span style={{ color: "#fa5252" }}>
                                  &nbsp;(Optional)
                                </span>
                              )}
                            </Button>
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: "12px",
                                  marginBottom: "5px",
                                }}
                              >
                                Clikc on the image to change it
                              </div>
                              <Image
                                fit="contain"
                                {...props}
                                src={
                                  typeof pet.image == "object"
                                    ? URL.createObjectURL(pet.image)
                                    : pet.image
                                }
                                style={{ maxWidth: "200px", margin: "auto" }}
                              />
                            </>
                          )
                        }
                      </FileButton>
                    </div>
                  )}
                </>
              ))}

            <small className="mb-4 mt-4 d-block">Patient’s Signature</small>

            {/* <p>
                            By Electronically signing this document, you declare that the information on this form is
                            true and correct.
                        </p>

                        <p>
                            Additionally, you are also aware that your recommendation may be revoked at any time if you
                            misrepresented yourself on this form.
                        </p> */}

            <p
              style={{ color: "red", display: site_id != 1 ? "none" : "block" }}
            >
              Note : Filling out this questionnaire does not guarantee a medical
              marijuana recommendation.
            </p>

            {context.user?.signature && (
              <blockquote style={{ textAlign: "center" }}>
                Your current signature shows below:
                <Group justify="center" m={20}>
                  <Image src={context.user?.signature} w={150}></Image>
                </Group>
                <Button size="xs" onClick={() => setShowSignature(true)}>
                  Modify
                </Button>
              </blockquote>
            )}

            {showSignature && (
              <>
                <div style={{ textAlign: "center" }}>
                  Use your mouse, finger or stylus to add your signature.
                </div>

                <Image
                  src={SigningImage}
                  w={{ xs: 150, sm: 200, md: 250 }}
                  fit="contain"
                  style={{ margin: "auto", width: "30vmin", height: "20vmin" }}
                />

                <div
                  style={{
                    border: "1.5px dashed #23A5A7",
                    width: "305px",
                    margin: "auto",
                  }}
                >
                  <canvas id="signature" />
                </div>

                <div style={{ margin: "auto", textAlign: "center" }}>
                  <Button
                    color="red"
                    size="md"
                    mt="xl"
                    radius="md"
                    onClick={() => {
                      signaturePad.clear();
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </>
            )}

            {questions && questions.length > 0 && (
              <>
                {questions.map((question) =>
                  question.type == "choice" ? (
                    <div className="question">
                      <Radio.Group
                        label={question.question}
                        styles={{ label: { fontWeight: "bold" } }}
                        onChange={(value) => updateQuestions(value, question)}
                      >
                        <Group mt="xs">
                          {question.options.map((option) => (
                            <Radio
                              variant="outline"
                              color="cyan"
                              key={option.value}
                              value={option.value}
                              label={option.label}
                            />
                          ))}
                        </Group>
                      </Radio.Group>
                    </div>
                  ) : (
                    <div className="question">
                      <Text>{question.question}</Text>
                      {question.type == "textarea" ? (
                        <Textarea
                          required={question.required}
                          onChange={(value) => updateQuestions(value, question)}
                        ></Textarea>
                      ) : (
                        <TextInput
                          required={question.required}
                          onChange={(value) => updateQuestions(value, question)}
                        ></TextInput>
                      )}
                    </div>
                  )
                )}
              </>
            )}

            {site_id != 2 && (
              <>
                <small className="mb-4 mt-4 d-block">
                  Give My Consent To Telemedicine
                </small>

                <p>
                  (Telehealth Means the Mode of Delivering Health care Services
                  and Public Health Via information and Communication
                  Technologies to Facilitate the Diagnosis, Consultation,
                  Treatment, Education, Care Management, and Self-Management of
                  a Patient's Health Care)
                </p>

                <p>I Declare the following to be true.</p>

                {/* <Checkbox
                  size="md"
                  mt="xl"
                  required
                  label="I am over 18 years old"
                  onChange={(value) =>
                    setIs18Checked(value.currentTarget.checked)
                  }
                /> */}

                <Checkbox
                  size="md"
                  mt="xl"
                  required
                  label="I dont have schizophrenia and I dont have hallucinations"
                  onChange={(value) =>
                    setIsSchizoChecked(value.currentTarget.checked)
                  }
                />

                {form.values.gender == "female" && (
                  <Checkbox
                    size="md"
                    mt="xl"
                    required
                    label="(For females only) I am not pregnant"
                    onChange={(value) =>
                      setIsPregnant(value.currentTarget.checked)
                    }
                  />
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
        <Group justify="center">
          <Button
            type="submit"
            size="xl"
            color="gray"
            loading={isLoading}
            onClick={() => {
              if (form.isDirty()) {
                confirmAlert({
                  title: "Are you sure",
                  message: "Your entered data will be lost. ",
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        navigate("/order/plan");
                      },
                    },
                    {
                      label: "No",
                      onClick: () => "",
                    },
                  ],
                });
                return;
              }
              navigate("/order/plan");
            }}
          >
            Back
          </Button>
          <Button
            type="submit"
            size="xl"
            style={{ backgroundColor: "#23A5A7" }}
            loading={isLoading}
          >
            Next
          </Button>
        </Group>
        <Modal
          opened={isLegalModalOpened}
          onClose={closeIsLegalModal}
          title="Attention"
          centered
        >
          <Alert icon={<FaInfoCircle />}>
            Based on restricted laws for patients below the age of 18, caregiver
            info is required, we recommend switching your plan to a 'caregiver
            plan'
          </Alert>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <Button component={Link} to="/order/plan" variant="filled">
              Change your plan
            </Button>
            <Button
              variant="light"
              onClick={()=>submitForm(form.values,true)}
              loading={isLoading}
            >
              continue
            </Button>
          </div>
        </Modal>
      </form>
    </div>
  );
}

export default UploadDoc;

import { FaPhone } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { formatPhone } from "../pages/doctor/utils";
import AppContext from "../AppContext";
import { useContext } from "react";

export function Phone({ mobile, name, email }) {

    const {context, activeKeypad, setCountryValue, setInputValue, setChat} = useContext(AppContext);
    
    function setNumberOnKeypad(mobile) {
        activeKeypad();
        setCountryValue(`${mobile[0]}`);
        setInputValue(mobile.slice(1));
    }

    function openChatBox()
    {
        setChat({name, mobile, email});
    }

    return !mobile ? '---' : <div className="phone">
            <a href={"tel:+" + mobile} target="_blank">
                {formatPhone(mobile)}
            </a>
            <FaMessage
                className="sms-icon"
                onClick={() => openChatBox()}

            />
            {context?.user?.available_for_call ? (
                <FaPhone
                    className="phone-icon"
                    onClick={() => setNumberOnKeypad(mobile)}
                />
            ) : null}
        </div>
}
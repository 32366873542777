import React,{useState,useEffect} from "react";
import { useNavigate, Link, matchRoutes, useLocation } from "react-router-dom";
import Axios from '../../axios';
import Helpers from "../../components/doctor/Helper";
import callRingImg from '../../assets/website/img/call-doctor.jpg'
function Topbar() {
  const navigate = useNavigate();

   
  const [userInfo, setUserinfo] = useState([]);
  const [isonline,setIsonline]=useState('');
  const [isChecked,setIsChecked]=useState(false);
  const [heading,setHeading]=useState('');
  const [callUrl,setCallUrl]=useState('');
  const [callData,setcallData]=useState([]);
  const [callStatus,setCallStatus]=useState('');
  const [roomId,setRoomId]=useState(null);

  const SessionToken=window.sessionStorage.getItem('token');

  const config = {
    headers: { Authorization: `bearer ${SessionToken}`,"Access-Control-Allow-Origin": "*"}
  };

  useEffect(() => {



    Axios.post('dGetDoctorprofile','',config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
            setUserinfo(res.data.datainfo);
            setIsonline(res.data.datainfo.is_online);

            if(res.data.datainfo.is_online == 3){
              setIsChecked(true);

            }else{
              setIsChecked(false);

            }
           }
			}).catch(response =>{
        Helpers.errorAlert(response.data.error,'error')
			});
  },[]);
  /*useEffect(() => {
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
        Axios.post('doctorCallResp','',config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
          
            setRoomId(res.data.roomId);
          }
      }).catch(response =>{
        if(response.data){
          Helpers.errorAlert(response.data.error,'error');
        }
        
      });

      
    },10000)
 
    return () => {
        clearInterval(intervalId); //This is important
      
    }
 
 }, [callUrl])*/

  const isDisabled=(evt)=>{
      let isOnlinevalue='';
      if(evt.target.checked){
        setIsChecked(true);
        isOnlinevalue=3;
      }else{
        setIsChecked(false);
        isOnlinevalue=4;
      }
      let data={
        isOnlinevalue:isOnlinevalue
      };
      Axios.post('offlineonline',data,config).then(res =>{
        if(res.data.status=='unauthorised'){
          Helpers.Logout();
        }else if(res.data.status=='Failed'){
          Helpers.errorAlert(res.data.message,'error');
        } else {
          Helpers.errorAlert(res.data.message,'success')
       }
    }).catch(response =>{
        console.log(response.message);
        Helpers.errorAlert(response.message,'error')
    });
    
  }
  return (
    <>
      <div className="page-header">
        <div className="page-header-fix">
          <div className="mob-header">
            <div className="row align-items-center">
              <div className="col-8 col-md-6 pr-0 pr-md-3">
                <nav className="navbar navbar-default navbar-expand-md">
                  <div className="container-fluid">
                    <div className="toggle_bar_mob">
                      <a href="#" id="sidebar-toggle-button">
                        <img src="../../assets/dashboard/img/toggle-icon.png" alt="" />
                      </a>
                    </div>
                    <div className="navbar_left">
                      <ul className="nav navbar-nav">
                        <li className="collapsed-sidebar-toggle-inv d-none d-md-block">
                          <a
                            href="#"
                            id="collapsed-sidebar-toggle-button"
                          >
                            <img src="../../assets/dashboard/img/toggle-icon.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" id="toggle-fullscreen">
                            <img src="../../assets/dashboard/img/expand-icon.png" alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-4 col-md-6">
               {userInfo.length!=0 &&
                <div className="d-flex align-items-center justify-content-end">
                  
                  <div className="mob-logo">
                 
                    <Link to='/dashboard' className="logo-box">
                      <img src="../../assets/dashboard/img/logo-2.png" alt="" />
                  </Link>
                  </div>
                  <div className="secondary-sidebar-profile d-flex align-items-center">
                     {roomId!=null && roomId!=''?
                        <img src={callRingImg} alt="" height={50}/>:''
                      }
                    
                    <div className="profile_box">
                   
                    {userInfo.profile_pic? <img src={userInfo.profile_pic}  alt={userInfo.name}/>: <img alt={userInfo.name} src="../../assets/website/img/user.jpg" /> }
                      
                      {userInfo.is_online==3? <span className="status online"></span>:<span className="status offline"></span> }
                    </div>
                    <div className="profile_txt">
                      <h3>{userInfo.name} </h3>
                      <h4>
                        
                        Status
                        <div className="toggle"  >
                            
                           <input type="checkbox" className="check"  defaultChecked={isChecked} onChange={(e) => {isDisabled(e)}} value={isonline} />
                            <b className="b switch"></b>
                            <b className="b track"></b>
                        </div>
                      </h4>
                    </div>
                  </div>
                  
                </div>
                }
              </div>
            </div>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-style-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">
                <span id="breadcrumb">{heading}</span>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
}
export default Topbar;

import React, { useState, useEffect }from "react";
import { useNavigate, Link, matchRoutes, useLocation } from "react-router-dom";
import { Facebook } from 'react-content-loader'
import Label from '../../config/DoctorLabel';
import Axios from '../../axios';
import Moment from 'react-moment';
import 'moment-timezone';
import Helpers from "../../components/doctor/Helper";

const Myprofile = (props) => {
  const [userInfo, setUserinfo] = useState([]);
  const [licenseinfo, setLicenseinfo] = useState([]);
  const [loader, setLoader] = useState(false);
  
  const SessionToken=window.sessionStorage.getItem('token');
  const config = {
    headers: { Authorization: `bearer ${SessionToken}` }
  };



  useEffect(() => {

    document.querySelector('#breadcrumb').innerHTML=Label.MY_PROFILE;

  }, [])
  
  let setLicenseData=(dt)=>{
    let data=[];
  
    dt.forEach(row => {
       let state='';
       if(row.get_doctor_states){
        state=row.get_doctor_states[0].name;
       }
      data.push({
        license_number:row.license_number,
        license:row.license,
        statename:state,
        status:row.status,
        expired_date:row.expired_date,
        license:row.license
      });
    });
    setLicenseinfo(data);
    setLoader(true);
   

  }
  useEffect(() => {
    setLoader(false);
    Axios.post('getDoctorprofile','',config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
            setUserinfo(res.data.doctorinfo);
            setLicenseData(res.data.license);
           
           }
			}).catch(response =>{
        if(response.data){
          Helpers.errorAlert(response.data.error,'error');
        }
        
			});
  },[]);

  return (
    <>
 
      <div className="content-header">
        <h1 className="page-title">{ Label.MY_PROFILE}</h1>
      </div>
      <div id="profile" className="view-profile update-profile">
        <div className="profile-edit-btn">
          {/*<Link to="/edit-profile">
            <img src="img/edit.png" alt="" /> Edit
          </Link>*/}
        </div>
        { loader>0 ?
         <form className="row m-0 pr_ofile view_profile">
          <div className="col-12 col-lg-3 bg_pro">
            <div className="up_pro">
              <div className="profile_picture">
              { userInfo.profile_pic ?<img src={userInfo.profile_pic} alt={userInfo.fname+' '+(userInfo.mname??'')+" "+userInfo.lname} />:
                    <img src="../../assets/dashboard/img/user.jpg" alt={userInfo.fname + ' ' + (userInfo.mname ?? '') +" "+userInfo.lname} />
              }
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9 col-xl-8 mt-3 mt-lg-0">
            <div className="fields t_op" >
              <small class="mb-4 mt-2 h4 d-block">Registered Doctor</small>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-5">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder={"Dr"+userInfo.fname+' '+(userInfo.mname??'')+" "+userInfo.lname}
                      value={"Dr "+userInfo.fname+' '+userInfo.mname+" "+userInfo.lname}
                      className="form-control"
                    
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-5">
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder={userInfo.email}
                      value={userInfo.email}
                      className="form-control"
                    
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-5">
                    <label>Mobile No</label>
                    <input
                      type="text"
                      placeholder={userInfo.mobile_no}
                      value={userInfo.mobile_no}
                      className="form-control"
                    
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-5">
                    <label>Gender</label>
                    <input
                      type="text"
                      placeholder={userInfo.gender}
                      value={userInfo.gender}
                      className="form-control"
                      
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date of birth</label>
                     <Moment format="DD/MM/YYYY">
                        {userInfo.dob}
                    </Moment>
                  </div>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Signature</label>
                    <span className="signature_img field_text">
                        {userInfo.signature ? <img src={userInfo.signature} style={{ height: 100 }} alt={userInfo.fname + ' ' + (userInfo.mname ?? '') +" "+userInfo.lname} />:
                          <img src="../../assets/dashboard/img/signature.jpg" alt={userInfo.fname + ' ' + (userInfo.mname ?? '') +" "+userInfo.lname} />
                     }

                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                  {licenseinfo.length>0 &&
                  <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>License Image</th>
                          <th>License number</th>
                          <th>State</th>
                          <th>Expire date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                         {
                          licenseinfo.map((row, index) => (
                            <tr key={index}>
                              <td> <div className="profile_picture">{row.license ? <img src={row.license} style={{ height: 50 }} alt={userInfo.fname + ' ' + (userInfo.mname ?? '') +" "+userInfo.lname} />:
                                <img src="../../assets/dashboard/img/signature.jpg" alt={userInfo.fname + ' ' + (userInfo.mname ?? '') +" "+userInfo.lname} />
                             }
                             </div>
                            </td>
                              <td>{row.license_number}</td>
                              <td>{row.statename}</td>
                              <td>{row.expired_date == ''?'':<Moment format="DD/MM/YYYY">{row.expired_date}</Moment>}</td>

                              <td>{ row.status==1 ?<span class="btn submit-btn theme-button">Active</span>:<span class="btn btn-danger close-btn theme-button">In-Active</span>}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                     }
                    
                  </div>
                </div>


              </div>
            </div>
          </div>
        </form>
        :  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      }
      </div>
      
      



    </>
  );
};
export default Myprofile;

import { Image } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

const RegisterLayout = ({ children }) => {

    const navigate = useNavigate();
    
    return (
        <main id="steps">
            <section id="register" className="container h-100">
                <div className="row ma_in">
                    <header>
                        <div className="logo-img">
                            <div className="content">
                                <Link to="/"><img src={process.env.REACT_APP_SITE_LOGO1} alt="logo" /></Link>
                            </div>
                        </div>
                        <div className="login_btn">
                            <button onClick={() => navigate('/login')}>Login</button>
                        </div>
                    </header>
                    <div className="col-12">
                        <div className="register_block">

                            { children }

                        </div>
                    </div>
                </div>
        </section> 
     </main >
    );
};
export default RegisterLayout;
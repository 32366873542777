import { Badge, Button, Checkbox, Space, Table, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "../axios2";
import { useState } from "react";
import moment from "moment/moment";

export default function Verify() {

    const [loading, setLoading] = useState(false);
    const [recom, setRecom] = useState(null);

    const form = useForm({
        initialValues: {
            agree: true
        },
        validate: {
          recom_id: (value) => value && value.length > 9 ? null : 'Please enter 9 chars at least',
          agree: (value) => !value ? 'Please agree this condition by check here' : null,
        },
      });

      const verify = (data) => {
        setLoading(true);
        setRecom(null);
        axios.get('/verify/'+data.recom_id)
        .then((resp) => setRecom(resp.data.data))
        .finally(() => setLoading(false))
      }

    return <div style={{textAlign: 'center', maxWidth: '800px', margin: '2em auto'}}>
    <h1>Find Your Recommendation</h1>
    <p>
    Please enter your Rec ID to verify your <br/> medical marijuana recommendation
    </p>
    <form onSubmit={form.onSubmit(verify)}>
        <TextInput size="xl" withAsterisk radius="xl" styles={{input: {textAlign: 'center'}}} {...form.getInputProps('recom_id')}></TextInput>
        <Checkbox defaultChecked {...form.getInputProps('agree', {type: 'checkbox'})} label=" I hereby certify that I am a patient, collective, patient's caregiver, or operating on a patient's behalf and have received a patient express authorization to access this information."></Checkbox>
        <Button type="submit" loading={loading} disabled={!form.isValid()}>VERIFY RECOMMENDATION</Button>
    </form>

    <Space h="xl"></Space>

      {recom && <Table striped highlightOnHover withBorder withColumnBorders>
            <thead>
                <tr>
                    <th>Recommendation ID</th>
                    <th>Full Name</th>
                    <th>Physician</th>
                    <th>Expire Date</th>
                    <th>Status</th>
                    <th>Limit Exemption</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>{recom.recom_id}</td>
                    <td>{recom.name}</td>
                    <td>{recom.dr_name}</td>
                    <td>{moment(recom.expire_date).format('MM-DD-Y')}</td>
                    <td><Badge color={recom.status == 'Active' ? 'green' : 'red'}>{recom.status}</Badge></td>
                    <td>{recom.limit_exemption ? recom.limit_exemption : '---'}</td>
                </tr>
            </tbody>
            </Table>
            }

    </div>
}
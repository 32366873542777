import {
  Button,
  PasswordInput,
  TextInput,
  Checkbox,
  LoadingOverlay,
  Modal,
  Group,
  PinInput,
} from "@mantine/core";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "@mantine/form";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";
import label from "../../config/DoctorLabel";
import Loginfooter from "../doctor/Loginfooter";
import { notifications } from "@mantine/notifications";
import axios2 from "../../axios2";
import AppContext from "../../AppContext";
import { useDisclosure } from "@mantine/hooks";

function Login(props) {
  const [visible, setVisible] = useState(false);
  const registerToken = useRef(1);

  const { context, refreshContext } = useContext(AppContext);
  const [user, setUser] = useState({});
  const [verifyEmailModalOpened, verifyEmailModal] = useDisclosure(false);
  const [otp, setOtp] = useState("");
  const [rid, setRid] = useState();

  function isEmailValid(value) {
    if (value.length === 0) return "Email address should not be empty";

    if (value.includes("@")) return "";

    return "Invalid email address";
  }

  function handleOnSubmit() {
    setVisible(true);

    delete axios2.defaults.headers.common["Authorization"];
    axios2
      .post("/user/login", form.values)
      .then((res) => {
        setVisible(false);
        const token = res.data.data.access_token;
        if (!token) {
          verifyEmailModal.open();
          setOtp("");
          setUser(res.data.data);
          setVisible(true);
          axios2
            .post("/user/sendotp", {
              mobile: res.data.data.mobile,
              email: res.data.data.email,
              type: "email",
            })
            .then((res) => {
              setRid(res.data.data.request_id);
            })
            .finally(() => setVisible(false));
          return;
        }
        loginSuccess(token);
      })
      .catch((err) => {
        console.log(err);
        setVisible(false);
        notifications.show({
          title: "Login Failed",
          message: "Wrong credentials",
          color: "red",
        });
      });
  }

  const loginSuccess = (token) => {
    axios2.defaults.headers.common = { Authorization: `Bearer ${token}` };
    localStorage.setItem("access_token", token);
    refreshContext("dashboard");
    notifications.show({
      title: "Success",
      message: "You logged in successfully",
      color: "green",
    });
  };

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      username: (value) =>
        isEmailValid(value).length !== 0 ? isEmailValid(value) : null,
      password: (value) =>
        value.length === 0 ? "Password should not be empty" : null,
    },

    validateInputOnChange: true,
  });

  const verify = () => {
    setVisible(true);
    if (!otp || otp.length < 6) {
      return;
    }
    axios2
      .post("/user/otplogin", {
        request_id: rid,
        email: user.email,
        email_secret: otp,
      })
      .then((res) => {
        loginSuccess(res.data.data.access_token);
      })
      .finally(() => {
        setVisible(false);
      });
  };

  return (
    <div
      className="page-container login d-lg-flex align-items-lg-center"
      style={{ height: "100vh" }}
    >
      <div
        className="login-content login-box"
        style={{ padding: "6px", paddingBottom: "1.0em", maxWidth: "300px" }}
      >
        <div className="login-logo content" style={{ marginTop: "1.0em" }}>
          <img src={process.env.REACT_APP_SITE_LOGO1} alt="logo" />
        </div>

        <div style={{ textAlign: "center", marginTop: "2em" }}>
          <h3 style={{ fontWeight: "bold", marginBottom: "0.4em" }}>Login</h3>
          <p>Welcome back! Please login to continue.</p>
        </div>

        <form
          style={{ paddingInline: "1.5em" }}
          onSubmit={form.onSubmit(handleOnSubmit)}
        >
          <TextInput
            placeholder="your@gmail.com"
            mt="lg"
            size="md"
            radius="md"
            type="email"
            inputMode="email"
            withAsterisk
            leftSection={<img src="/assets/website/img/mail.png" alt="" />}
            {...form.getInputProps("username")}
          />

          <PasswordInput
            placeholder="password"
            mt="lg"
            size="md"
            radius="md"
            withAsterisk
            leftSection={<img src="/assets/website/img/password.png" alt="" />}
            {...form.getInputProps("password")}
          />

          <Checkbox mt="lg" size="md" label="Remember password" />

          <Button
            className="w-100"
            type="submit"
            mt="md"
            size="lg"
            radius="md"
            style={{ backgroundColor: "#3cb7b4" }}
          >
            <LoadingOverlay visible={visible} overlayProps={{ blur: 2 }} />
            Login
          </Button>
          <p
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            Don't have an account? <br/> <Link to="/register">Register now</Link>
          </p>
          <p style={{ marginBlock: "1.0em", fontSize: "12px" ,textAlign:'center'}}>
            <Link to="/login-by-token">{label.login_forgot}</Link>
          </p>

          <Loginfooter />
        </form>
      </div>

      <Modal
        opened={verifyEmailModalOpened}
        size="md"
        onClose={verifyEmailModal.close}
        title="Verify Email Address"
      >
        <div style={{ margin: "auto", textAlign: "center" }}>
          <h5>Please verify your email address:</h5>
          <p>
            We have sent a code to <br />
            <strong>{user.email}</strong>
            <br /> Please enter it below
          </p>
          <PinInput
            length={6}
            type="number"
            oneTimeCode
            value={otp}
            onChange={setOtp}
            placeholder="-"
            spacing={5}
            size="xl"
            style={{
              display: "inline-flex",
              margin: "1em 0",
              maxWidth: "100%",
            }}
          />
          <Group position="center">
            <Button
              color="gray"
              loading={visible}
              onClick={() => verifyEmailModal.close()}
            >
              Cancel
            </Button>
            <Button color="green" loading={visible} onClick={() => verify()}>
              Verify
            </Button>
          </Group>
        </div>
      </Modal>
    </div>
  );
}

export default Login;

import React,{useState} from 'react';
import Topbar from "./Topbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Parser from 'html-react-parser';
import label from '../../config/PatientLabel';
import Layouts from './Layouts';
//import { useLocation, useNavigate, useParams } from 'react-router-dom';
const Contactus = () => {
    
	// const match = { params: useParams() };
	// console.log(match)
	const [isLoading, setIsLoading] = useState(true);
	const [emailResponse, setemailResponse] = useState(0);
  	const [emailResponseStatus, setemailResponseStatus] = useState(0);

	React.useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		  }, 1000);
		
		}, []);
	const hideEmailverifyMessage = ()=>{
		setemailResponseStatus('null');
		setemailResponse('');
	}
    return (
		<>
			<div className={`emailResponse ${emailResponseStatus} == 'Success'? forgotPasswordAlert : ''}`} >
				{emailResponseStatus === 'Success' ?
				<div className="alert success">
					<span className="closebtn success" onClick={hideEmailverifyMessage}>×</span>
					<i className="fa fa-check fa-1x"></i> 
					<b>{emailResponse}</b>
				</div>
				:emailResponseStatus === 'Failed'?
				<div className="alert danger">
					<span className="closebtn danger" onClick={hideEmailverifyMessage}>×</span>
					<i className="fa fa-check fa-1x"></i> 
					<b>{emailResponse}</b>
				</div>
				:
				''
				}
		    </div>
			<div className="content-header">
				<h1 className="page-title">{Parser(label.contactUsTitle)}</h1>
			</div>
			{isLoading ? (
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
			) : ( 
			<div id="contact" className="content-body">
				<div className="row">
				<div className="col-md-7 col-lg-8 contact-left p-0">
					<div className="contact_lf_block">
					<div className="contact-box">
						<img className="icon-box" src="img/headphone.png" alt=""/>
						<div className="textblock">
						<span>QUICK SUPPORT</span>
						<h3>Contact Us</h3>
						<p>Our experts are always available for help. Feel free to contact us in case of  any queries.</p>
						</div>
					</div>
					<div className="contact-box">
						<img className="icon-box" src="img/location.png" alt=""/>
						<div className="textblock">
						<h3>Walk-Ins-Accepted Online Medical Card</h3>
						<p>2001 East 1st St. Suite 102. Santa Ana CA 92705</p>
						</div>
					</div>
					</div>
				</div>
				<div className="col-md-5 col-lg-4 contact-right p-0">
					<div className="contact_rt_block">
					<div className="contact-box">
						<img className="icon-box" src="img/headphone.png" alt=""/>
						<div className="textblock">
						<span>Contact Details</span>
						<h3>Call Us 24/7 <span><a href="tel:8889888420">(888) 988-8420</a></span></h3>
						</div>
					</div> 
					<div className="contact-box">
						<img className="icon-box" src="img/timer.png" alt=""/>
						<div className="textblock">
						<span>Time Details</span>
						<h3>Mon-Sun: <span>9:00 AM - 10:00 PM</span></h3>
						</div>
					</div> 								
					</div>
				</div>
				</div>
			</div>
			)}
		</>
    );
};
export default Contactus;
import React, { useState, useEffect} from "react";
import Label from "../../config/DoctorLabel";
import { useNavigate, Link, useLocation,useParams, generatePath} from "react-router-dom";
import Moment from 'react-moment';
import Axios from '../../axios';
import Helpers from "../../components/doctor/Helper";
//import '../../assets/dashboard/assets/css/bootstrap.min.css';
import '../../assets/dashboard/css/main.css';
import '../../assets/dashboard/assets/css/admin03.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Comments from "../../components/doctor/Comments";
const Videocall = () => {
  const videocallUrl = useLocation();
  const { id } = useParams();

  const [roomURL, setroomURL] = useState(0);
  const [customerId,setCustomerId]=useState('');
  const [orderDetails,setOrderDetails]=useState([]);
  const [customerDetails,setCustomerDetails]=useState([]);
  const [diseaseuser,setDiseaseuser]=useState([]);
  const [inputNote,setInputNote]=useState('');
  const [inputNoteError,setInputNoteError]=useState(0);
  const [updateCallStatus,setupdateCallStatus]=useState(0);
  const [recId,setRecId]=useState(0);
  const [viewRec,setViewRec]=useState('');

  const navigate = useNavigate();
  const SessionToken=window.sessionStorage.getItem('token');
  const config = {
    headers: { Authorization: `bearer ${SessionToken}` }
  };


  //console.log(videocallUrl.state.url)
  useEffect(() => {

  document.querySelector("#breadcrumb").innerHTML = Label.VIDEO_CALL_HEADING;
	if(videocallUrl || useParams){
		setroomURL(videocallUrl.state.url);
    if(updateCallStatus==0){
    updateCallstatus()
    }
   
	}else{
    navigate('/');
  }
  fetchCustomerOrder();
  }, []);

  const updateCallstatus=()=>{
    let callId=videocallUrl.state.callId;
    if(callId){
        let data ={
          callId:callId
        }
        Axios.post('mcallInitstate',data,config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
            Helpers.errorAlert(res.data.message,'success');
         
            setupdateCallStatus(1)
          }
      }).catch(response =>{
        if(response.data){
          Helpers.errorAlert(response.data.error,'error');
        }
        
      });
    }
  }
  const fetchCustomerOrder=()=>{
  
    if(id){
   
        let data ={
          customerId:id
        }
        Axios.post('mFetchorderdetails',data,config).then(res =>{
          if(res.data.status=='unauthorised'){
            Helpers.Logout();
          }else if(res.data.status=='Failed'){
            Helpers.errorAlert(res.data.message,'error');
          }else{
         
            setCustomerDetails(res.data.user);
            setOrderDetails(res.data.orderlist);
            setDiseaseuser(res.data.diseaseuser);
            //alert(res.data.recId);
            setRecId(res.data.recId);
          
          }
      }).catch(response =>{
        if(response.data){
          Helpers.errorAlert(response.data.error,'error');
        }
        
      });

    }
  }
  useEffect(() => {
    fetchCustomerOrder();

    }, [])

  const handleChange=(e)=>{
    setInputNoteError(0)
    setInputNote(e.target.value);
  }
  
  const saveCustomerNote=()=>{
    if(inputNote==''){
      setInputNoteError(1);
      return false;
    }else{
      setInputNoteError(0);
      let data ={
        customerId:id,
        order_id:orderDetails[0].id,
        Comment:inputNote
      }
      document.querySelector('.save_note').innerHTML='<div class="spinner-border" style="width:15px;height:15px;margin-bottom: 3px;" role="status"><span class="sr-only"></span></div> Save Note';
      document.querySelector('.save_note').disabled=true;
      Axios.post('mSaveDoctorComment',data,config).then(res =>{
        document.querySelector('.save_note').innerHTML='Save Note';
        document.querySelector('.save_note').disabled=false;                                                          
        document.querySelector('.comment').value='';
        setInputNote('');
        if(res.data.status=='unauthorised'){
          Helpers.Logout();
        }else if(res.data.status=='Failed'){
          Helpers.errorAlert(res.data.message,'error');
        }else{
        
          Helpers.errorAlert(res.data.message,'success');
        
        }
    }).catch(response =>{
      if(response.data){
        Helpers.errorAlert(response.data.error,'error');
      }
      
    });


    }
  }
  const generateRec=()=>{
    confirmAlert({
      title: 'Are you sure',
      message: 'You want to generate recommendation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            
              let data ={
                user_id:id,
                order_id:orderDetails[0].id,
                save_dr_notes:inputNote
              }

                          
            document.querySelector(".grec").innerHTML='<div class="spinner-border" style="width:15px;height:15px;margin-bottom: 3px;" role="status"><span class="sr-only"></span></div>  Generate Rec';
            document.querySelector(".grec").disabled=true;
            

              Axios.post('GenerateUserRecommendation',data,config).then(res =>{
                document.querySelector(".grec").innerHTML='Generate Rec';
                document.querySelector(".grec").disabled=false;
                
    
                if(res.data.status=='unauthorised'){
                  Helpers.Logout();
                }else if(res.data.status=='Failed'){
                  Helpers.errorAlert(res.data.message,'error');
                }else{
                  setRecId(res.data.rec_id);
                  Helpers.errorAlert(res.data.message,'success');
                }
            }).catch(response =>{
              if(response.data){
                Helpers.errorAlert(response.data.error,'error');
              }
              
            });



          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }
  const sendRec=()=>{
    confirmAlert({
      title: 'Are you sure',
      message: 'You want to send recommendation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
          


          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  const viewReco=(e)=>{
    e.preventDefault();
    let recom_id=recId;
    let user_id=id
    if(recom_id && user_id){
      let formdata={
        recom_id:recom_id,
        user_id:id
      }
       

      const config = {
        headers: {
          Authorization: `bearer ${SessionToken}`,
       
        },
     
      };
      document.querySelector(".vrec").innerHTML='<div class="spinner-border" style="width:15px;height:15px;margin-bottom: 3px;" role="status"><span class="sr-only"></span></div> View Rec';
      document.querySelector(".vrec").disabled=true;
      Axios.post('mGetManualRecommendation',formdata,config).then((res) => {
        document.querySelector(".vrec").innerHTML='View Rec';
        document.querySelector(".vrec").disabled=false;
     
      setViewRec(res.data);
      window.$('#view-rec-modal').modal('show');
    })
    .catch((error) => {
    
      Helpers.errorAlert(error,'error');
    })

  }else{
    Helpers.errorAlert('Please generate recommendation first','error');
  }
    
  }
  const SendReco=(e)=>{
    e.preventDefault();
    let recom_id=recId;
    let user_id=customerDetails.id
    console.log(customerDetails);
    if(recom_id && user_id){

      confirmAlert({
        title: 'Are you sure',
        message: 'You want to send recommendation?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>{
            
              let formdata={
                recom_id:recom_id,
                user_id:id
              }
              
            document.querySelector(".srec").innerHTML='<div class="spinner-border" style="width:15px;height:15px;margin-bottom: 3px;" role="status"><span class="sr-only"></span></div> Send Rec';
            document.querySelector(".srec").disabled=true;
            
              Axios.post('mSendRecommendationMail',formdata,config).then(res =>{
                           
            document.querySelector(".srec").innerHTML='Send Rec';
            document.querySelector(".srec").disabled=false;
            
                if(res.data.status=='unauthorised'){
                  Helpers.Logout();
                }else if(res.data.status=='Failed'){
            
                  let str='';
                  if(res.data.message.isArray){
                    res.data.message.map(function(data) {
                        str +=data+"\n";
                    })
                  }
                  if(str){
                    str=str;
                  }else{
                    str=res.data.message;
                  }
            
                  Helpers.errorAlert(str,'error');
                }else{
                  Helpers.errorAlert(res.data.message,'success')
             
                
              }
            
            });
  
  
            }
          },
          {
            label: 'No',
            onClick: () => ''
          }
        ]
      });
     
      
    }
  

      
  }
 let RedirectToOrders =()=>{
    navigate('/');
 }
  return (
    <>
    <div>

    <div className="modal " role="dialog" id="view-rec-modal">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
   
      <div className="modal-body" id="view-rec-body" dangerouslySetInnerHTML={{__html:viewRec}}>
      
      </div>
      <div className="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
      <div className="content-header">
        <h1 className="page-title">Video call</h1>
      </div>
      <div id="contact" className="content-body">
        <div className="row">
          <div className="col-xl-6 pr-xl-4">
            <div className="video_box">
              <div className="table_wrap m-0">
                {orderDetails.length>0?
                <table className="video_lf_table">
                  <tr>
                    <td>Package</td>
                    <td>{orderDetails[0].plan_value?orderDetails[0].plan_value:''} ({orderDetails[0].plan_name?orderDetails[0].plan_name:''})</td>
                  </tr>
                  <tr>
                    <td>Drivers Lic.ID</td>
                    <td>{customerDetails.license_no?customerDetails.license_no:''}</td>
                  </tr>
                  <tr>
                    <td>
                    Allergies
                    </td>
                    <td>{customerDetails.allergies}</td>
                  </tr>
                  <tr>
                    <td>Symptoms</td>
                    <td>
                      { 
                      diseaseuser.length>0?
                      diseaseuser.map((res) => (
                        <>
                        <span> {res.qualify_condition}</span>,
                        </>
                                                                        
                        )):''
                      }

                    </td>
                  </tr>
                 
                  <tr>
                    <td>Address</td>
                    <td>{customerDetails.address!='null'?customerDetails.address:''} </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{customerDetails.city!='null'?customerDetails.city:''}</td>
                  </tr>
             
                </table>: <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              }
              </div>
              <div className="comment_area my-4">
                <div className="form-group">
                  <textarea className="form-control comment" placeholder="Note for recommendation" onChange={handleChange}></textarea>
                  {inputNoteError==1 &&
                  <label className="error">This field required</label>
                  }
                </div>
              </div>
              <div className="btn_block">
                {/* {customerDetails.recom_type == 'auto'?:''} */}
                <button className="btn send_rec grec" onClick={generateRec}> Approve</button>
                {recId!=''?
                <>
                <button className="btn send_rec vrec" onClick={viewReco}>View Rec</button>
                <button className="btn send_rec srec" onClick={SendReco}  >Send Rec</button>
                </>
                :''
                }
              </div>
              </div>
          </div>
          <div className="col-xl-6 pl-xl-4 mt-5 mt-xl-0">
            <div className="video_box">
              <div className="row">
                <div className="col-sm-6">
                  <small>Video Room</small>
                </div>
                <div className="col-sm-6 mt-3 mt-sm-0 text-sm-right">
                  <a className="link" href="#">
                    Click here to clear cache.
                  </a>
                </div>
              </div>
              {roomURL == 0 ? (
                ""
              ) : (
                <div className="video_room">
                  <iframe
                    width="100%"
                    height="100%"
                    src={roomURL}
                    allowFullScreen
                    allow="camera; microphone;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="YouTube video player"
                    frameBorder="0"
                  ></iframe>
                </div>
              )}
              <div className="video_info">
                <div className="table_wrap m-0">
                  <table>
                    <tr>
                      <td>Full Name</td>
                      <td>{customerDetails.fname} {customerDetails.mname?customerDetails.mname:''} {customerDetails.lname}</td>
                    </tr>
                    <tr>
                      <td>DOB</td>
                      <td>{customerDetails.dob?<Moment format="MM/DD/YYYY">{customerDetails.dob}</Moment>:''}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{customerDetails.mobile_no}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{customerDetails.email}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{customerDetails.gender}</td>
                    </tr>
                    </table>
                    
                    <div>
                      <Comments user_id={id}></Comments>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          
     
    </>
  );
};
export default Videocall;

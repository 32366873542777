import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  Image,
  Space,
  TextInput,
  Button,
  Select,
  FileButton,
  Group,
  Input,
  Alert,
  Text,
  Radio,
  Textarea,
  Badge,
  Loader,
} from "@mantine/core";
import axios2 from "../../axios2";
import AppContext from "../../AppContext";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MedicalConditionsCheckList from "./MedicalConditionsCheckList";
import { confirmAlert } from "react-confirm-alert";
import DOB from "../../components/DOB";

function Questionnaire() {
  const { context } = useContext(AppContext);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {},
    validate: {},
  });

  useEffect(() => {
    axios2.get("/questions/order/" + id).then((resp) => {
      setIsLoading(false);
      setQuestions(resp.data.data);
    });
  }, [context]);

  function submitForm(data) {
    console.log(answers);

    setIsLoading(true);
    axios2
      .post("/questions/order/" + id, { answers })
      .then((res) => {
        setIsLoading(false);
        notifications.show({
          title: "Done",
          color: "green",
          message: "",
        });
        navigate("/dashboard");
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setIsLoading(false);
        notifications.show({
          title: "error",
          color: "red",
          message: err.message,
        });
        console.log(err);
      });
  }

  const updateQuestions = (value, question) => {
    answers[question.id] = value;
    setAnswers(answers);
  };

  return isLoading ? (
    <div style={{ margin: "2em auto", textAlign: "center" }}>
      <Loader />
    </div>
  ) : (
    <div className="container page-listing" style={{ paddingBlock: "5vmin" }}>
      <h2 style={{ textAlign: "center" }}>Order Questions</h2>
      <h4 style={{ textAlign: "center" }}>
        Please answer below questions to complete your order
      </h4>

      <form onSubmit={form.onSubmit((values) => submitForm(values))}>
        <Grid
          //   gutter={{ md: "lg" }}
          gutter={{ md: "lg" }}
          //   style={{ margin: "auto", justifyContent: "center" }}
          justify="center"
        >
          <Grid.Col span={10}>
            {questions && questions.length > 0 && (
              <>
                {questions.map((question) =>
                  question.type == "choice" ? (
                    <div className="question">
                      <Radio.Group
                        label={question.question}
                        styles={{ label: { fontWeight: "bold" } }}
                        onChange={(value) => updateQuestions(value, question)}
                      >
                        <Group mt="xs">
                          {question.options.map((option) => (
                            <Radio
                              variant="outline"
                              color="cyan"
                              key={option.value}
                              value={option.value}
                              label={option.label}
                            />
                          ))}
                        </Group>
                      </Radio.Group>
                    </div>
                  ) : (
                    <div className="question">
                      <Text>
                        {question.question}{" "}
                        {question.required == 1 && (
                          <Badge color="red" size="xs" ml={10}>
                            Required
                          </Badge>
                        )}
                      </Text>
                      {question.type == "textarea" ? (
                        <Textarea
                          required={question.required}
                          onChange={(value) =>
                            updateQuestions(value.currentTarget.value, question)
                          }
                        ></Textarea>
                      ) : (
                        <TextInput
                          required={question.required}
                          onChange={(value) =>
                            updateQuestions(value.currentTarget.value, question)
                          }
                        ></TextInput>
                      )}
                    </div>
                  )
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
        <Group justify="center">
          <Button
            type="submit"
            size="xl"
            style={{ backgroundColor: "#23A5A7" }}
            loading={isLoading}
          >
            Submit
          </Button>
        </Group>
      </form>
    </div>
  );
}

export default Questionnaire;

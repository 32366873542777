 let Year = new Date().getFullYear();
 
 const config={
    'baseURLWeb':"https://webapp.onlinemedicalcard.com",
    // -----------------------------Pages Title ------------------------------
    'emailVerifyTitle':'Email Verify',
    'errorTitle':'Error',
    'forgotPasswordTitle':'Forgot Password',
    'homeTitle':'Dashboard',
    'loginTitle':'Login',
    'otpTitle':'OTP',
    'registerTitle':'Register',
    'registerSuccessfulTitle':'Register Successfully',
    'resetPasswordTitle':'Reset Password',
    'paymentTitle':'Payments',
    'personalinfoTitle':'Personal Info',
    'uploadDocTitle':'Uploads Documents',
    'plansTitle':'Select Plans',
    'changePasswordTitle':'Change Password',
    'contactUsTitle':'Contact Us',
    'allRecommendationTitle':'All Orders',
    'viewProfileTitle':'View Profile',
    'UpdateProfileTitle':'Update Profile',
    'Qualifyconditions':'Qualifying Condition',
    'SelectPlans':'Select Plans',
    'newpayment':'Payments',
    
    
    // -----------------------------Sidebar page Navbar Title ------------------------------
    'SidebarTitleDashboard':'Dashboard',
    'SidebarTitleProfile':'View Profile',
    'SidebarTitleEditProfile':'Update Profile',
    'SidebarTitleOrders':'Orders',
    'SidebarTitleSetting':'Change Password',
    'SidebarTitleMakePayment':'Make Payment',
    'SidebarTitleContactus':'Contact Us',
    'SidebarTitleLogout':'Logout',
    
    // ------------------------------------------------ Global  ------------------------------------------------------------
    'logo':'',
    'footer_copyright':`<p>${Year} © OnlineMedicalCard</p>`,
    // ------------------------------------------------Register Page----------------------------------------------------------
    'register_terms_conditions':'Accept Term & Conditions',
    'register_button':'Register',
    'registerfNameErrorNotification':'First Name field is required.',
    'registerlNameErrorNotification':'Last Name field is required.',
    'registerEmailErrorNotification':'Email Field is Invalid.',
    'registerMobileErrorNotification':'Phone field is required.',
    'registerMobileLengthErrorNotification':'Phone must be 10 digit.',
    'registerPasswordErrorNotification':'Password field is required.',
    'registerCPasswordErrorNotification':'Confirm Password field is required.',
    'registerPasswordMatchErrorNotification':'Password not matched.',
    'registerTermAndConditionErrorNotification':'required',

    // ------------------------------------------------Login Page----------------------------------------------------------
    'login_header':'<h3>Login</h3><p>Welcome back! Please login to continue.</p>',
    'login_mail_image':'<img src="/assets/website/img/mail.png" alt=""/>',
    'login_password_image':'<img src="/assets/website/img/password.png" alt=""/>',
    'login_remember_password':'Remember password',
    'login_button':'Login',
    'login_forgot':'Forgot password?',
    'login_create':'Don’t have an account?',
    'login_footer':'<p>2022 © OnlineMedicalCard</p>',
    // ------------------------------------------------OTP Page----------------------------------------------------------
    'otp_image':'<img src="/assets/website/img/authentication.png" alt="" />',
    'otp_heading_description':"<h1>Authentication</h1><h4>Check 6-digit code.</h4>",
    'otp_button_verify':'Verify',
    'otp_subdescription':"If you don't receive a text message ",
    'otp_modal_label':'<label className="w-100">Change Phone No</label><small className="w-100">OTP is valid for 2 minutes please make sure that you have entered a right phone no</small>',
    // ------------------------------------------------Email verify Page----------------------------------------------------------
    'emailVerify_heading_description':'<h3>Verification</h3><p>Your account is now verified. </p>',
    // ------------------------------------------------Forgot password Page----------------------------------------------------------
    'forgotpassword_heading_description':'<h3>Password assistance</h3><p>Enter the email address associated with your Online Medical Card account.</p>',
    'forgotpassword_button':'Continue',
    // ------------------------------------------------Forgot password Page----------------------------------------------------------
    'resetpassword_heading_description':'<h3>Reset</h3><p>Reset your password</p>',
    'resetpassword_button':'Reset',
    // ------------------------------------------------Register Successfully Page----------------------------------------------------------
    'registersuccessfully_heading_description':'<h3>Account Created.</h3><p>Please check your email, to verify account.</p>',
    'a':'nama',
    // ------------------------------------------------Personal profile Page----------------------------------------------------------
    'personalProfileLicensenoError':'License No. field is required.',
    'personalProfileGenderError':'Gender field is required.',
    'personalProfileDobError':'DOB field is required.',
    'personalProfileInvalidDobError':'DOB should be correct.',
    
    'personalProfileAddressError':'Address field is required.',
    'personalProfileCityError':'City field is required.',
    'personalProfileStateError':'State field is required.',
    'personalProfileZipcodeError':'Zipcode field is required.',
    'PersonalProfile_button':'Next',
    'PersonalProfileheading':'You Are Getting a Recommendation for California.',
    'PersonalProfileNameLabel':'<label>Full Name</label>',
    'PersonalProfileEmailLabel':'<label>Email</label>',
    'PersonalProfilePhoneLabel':'<label>Phone No.</label>', 
    'PersonalProfileLicenseLabel':"<label>Your Driver's License NUMBER. or State ID NUMBER</label>",
    'PersonalProfileGenderLabel':'<label>Sex Assigned at Birth</label>',
    'PersonalProfileDOBLabel':'<label>Date of Birth</label>',
    'PersonalProfilereferedByLabel':'<label>Referred By</label>',
    'PersonalProfileallergiesLabel':'<label>What are your allergies? (If any)</label>',
    'PersonalProfileAddressLabel':'<label>Address (Please include Apartment / Suite number if any)</label>',
    'PersonalProfileCityLabel':'<label>City</label>',
    'PersonalProfileStateLabel':'<label>State</label>',
    'PersonalProfileZipLabel':'<label>Zipcode</label>',
    // ------------------------------------------------Plans Page----------------------------------------------------------
    'plansIdCardRemove':'ID Card Removed.',
    'plansIdCardAdded':'ID Card Added.',
    // ------------------------------------------------Payment Page----------------------------------------------------------
    'paymentCardNumber':'Card Number Field requied.',
    'paymentNameonCard':'Name on Card Field requied.',
    'paymentCardExpYear':'Expire Year Field requied.',
    'paymentCardExpMonth':'Expire Month Field requied.',
    'paymentCardCvc':'Card Cvc Field requied.',
    'paymentCheckboxTitle':'Billing Address Same As Shipping Address.',
    // ------------------------------------------------verify Email Page----------------------------------------------------------
    'verifyEmail_heading':'<h3>Verify Email.</h3><p>Please check your email, to verify account.</p>',
    // ------------------------------------------------Thank u Page----------------------------------------------------------
    'ThankYou':'<h3>Thank You.</h3><p>We will call you Soon.</p>',
    // ------------------------------------------------Change Password Page----------------------------------------------------------
    'changePasswordOld':'Old Password field Required.',
    'changePasswordnew':'New Password field Required.',
    'changePasswordconfirm':'Confirm Password field Required.',
    'changePasswordOldlength':'Old Password Length should be grater than 6 digit.',
    'changePasswordnewlength':'New Password Length should be grater than 6 digit.',
    'changePasswordconfirmlength':'Confirm Password Length should be grater than 6 digit.',
    // ------------------------------------------------verify recommendation Page----------------------------------------------------------
    'verifyrecHeading':'find Your recommendation',
    'verifyrecparagraph':'<p>Please enter your patient ID to verify your <br/> medical marijuana recommendation </p>',
    'verifyrecPlaceholder':'Recommendation ID',
    'verifyrecCheckboxContent':'I hereby certify that I am a patient,collective, patient`s caregiver, or operating on a patient`s behalf and have received a patient express authorization to access this information.',
    'verifyrecBtnName':'Verify Recommendation'

} 
export default config;
import {Link} from "react-router-dom";
import label from "../../config/PatientLabel";
import React from "react";
import {useNavigate, useLocation} from "react-router-dom";

function NavItem(props) {
    const navigate = useNavigate();
    const location = useLocation();

    function handleClick() {
        if(props.handleClick){
            props.handleClick();
            return;
        }
        if (location.pathname !== props.dest)
            navigate(props.dest)
    }

    return (
        <li onClick={handleClick}>
            <a>
                <span>
                    <img src={props.src} alt=""/>
                </span>

                <label>{props.label}</label>
            </a>
        </li>
    )
}

export default NavItem;